import { parseCookies } from 'nookies';
import { SYSTEM_GEOIP_COOKIE_NAME } from '@constants';
import type { Geo } from './index';

type GetGeoipCookie = () => Geo | null;

const getGeoipCookie: GetGeoipCookie = () => {
  const cookies = parseCookies();

  return JSON.parse(cookies[SYSTEM_GEOIP_COOKIE_NAME] || null);
};

export default getGeoipCookie;
