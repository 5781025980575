import { setCookie } from 'nookies';
import { SYSTEM_GEOIP_COOKIE_NAME, SYSTEM_COOKIE_MAX_AGE } from '@constants';
import type { Geo } from './index';

const setGeoipCookie = (geo: Geo): void => {
  setCookie(null, SYSTEM_GEOIP_COOKIE_NAME, JSON.stringify(geo), {
    maxAge: SYSTEM_COOKIE_MAX_AGE,
    expires: new Date(Date.now() + SYSTEM_COOKIE_MAX_AGE * 1000),
    secure: process.env.NODE_ENV === 'production',
    path: '/',
    sameSite: 'lax',
  });
};

export default setGeoipCookie;
