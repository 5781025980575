/* eslint-disable no-restricted-globals */
import { FC, useState, useCallback, useEffect, memo } from 'react';
import cn from 'clsx';
import clampRange from '@utils/clamp-range';
import slugify from '@utils/slugify';
import * as styles from './styles';
import s from './CartItem.module.css';

type Props = {
  id: string;
  defaultCount: number;
  onUpdate: (qty: number) => void;
  max?: number;
  disabled?: boolean;
};

const CartItemCounter: FC<Props> = ({
  id,
  defaultCount = 1,
  onUpdate,
  max,
  disabled = false,
}) => {
  const [lineQuantity, setLineQuantity] = useState(defaultCount);

  const animateQuantity = useCallback(
    amount => {
      const count = max ? clampRange(amount, 1, max) : amount;

      if (count < 1 || count > max) {
        return;
      }

      setLineQuantity(count);

      if (onUpdate) {
        onUpdate(count);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const updateQuantity = useCallback(amount => {
    const count = max ? clampRange(amount, 1, max) : amount;

    if (count < 1) {
      return;
    }

    setLineQuantity(count);

    if (onUpdate) {
      onUpdate(count);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setLineQuantity(defaultCount);
  }, [defaultCount, lineQuantity]);

  return (
    <div className={cn(styles.counter, s.counter)}>
      {!disabled && (
        <button
          type="button"
          aria-label="Decrease quantity by one"
          data-test-id={slugify(`decrease-${id}-cart`)}
          onClick={() => animateQuantity(lineQuantity - 1)}
          className={styles.padButton}
        >
          -
        </button>
      )}
      <div className={styles.counterAmount}>
        <div className={cn(styles.counterInput, s.counterInput)}>
          <input
            aria-label="Manually enter quantity"
            data-test-id={slugify(`${id}-cart-amount`)}
            onChange={e => {
              if (isNaN(parseInt(e.currentTarget.value, 10))) {
                setLineQuantity(0);
              } else {
                updateQuantity(parseInt(e.currentTarget.value, 10));
              }
            }}
            onBlur={() => isNaN(lineQuantity) && updateQuantity(1)}
            type="number"
            inputMode="numeric"
            min="1"
            max={max || '99'}
            value={lineQuantity || 0}
            className={s.quantity}
            disabled={disabled}
          />
        </div>
      </div>
      {!disabled && (
        <button
          type="button"
          aria-label="Increase quantity by one"
          data-test-id={slugify(`increase-${id}-cart`)}
          onClick={() => animateQuantity(lineQuantity + 1)}
          className={styles.padButton}
        >
          +
        </button>
      )}
    </div>
  );
};

export default memo(CartItemCounter);
