/* eslint-disable import/prefer-default-export */
import { classnames } from '@tailwindcss-classnames';

export const circle = classnames(
  'inline-flex',
  'items-center',
  'justify-center',
  'h-5',
  'md:h-6',
  'w-5',
  'md:w-6',
  'rounded-full'
);

export const circleText = classnames(
  'text-xxs',
  'text-white',
  'hover:text-nzxt-dark-grey-100'
);
