import { FC, memo } from 'react';
import { useTranslation as t } from '@utils/hooks';
import CartCta from './CartCta';
import * as styles from './styles';

const CartCtaSection: FC = () => {
  const LABEL_AFFIRM_HEADING = t('label_cart_affirm_heading');
  const LABEL_AFFIRM_SUBHEADING = t('label_cart_affirm_subheading');
  const LABEL_CART_CTA_HEADING = t('label_cta_cart_heading');

  const hasWindow = typeof window !== 'undefined';

  const STATIC_CTA_CONTENT = {
    sectionHeading: LABEL_CART_CTA_HEADING,
    cta: [
      {
        heading: LABEL_AFFIRM_HEADING,
        subheading: LABEL_AFFIRM_SUBHEADING,
        backgroundColor: '#E6E6FA',
        onClick: () =>
          hasWindow && window?.affirm
            ? window.affirm?.ui?.openModal('learn-more')
            : null,
        logo: '/logo/affirm-logo.svg',
        maxHeight: '24px',
        id: 'affirm-cta',
      },
    ],
  };
  const { sectionHeading, cta } = STATIC_CTA_CONTENT;
  return (
    <div className={styles.ctaSectionContainer} data-test-id="cart-cta-block">
      <p
        className={styles.sectionHeading}
        data-test-id="cart-cta-section-heading"
      >
        {sectionHeading}
      </p>
      <div className={styles.ctaWrapper}>
        {cta.map(c => (
          <CartCta {...c} key={c.id} />
        ))}
      </div>
    </div>
  );
};

export default memo(CartCtaSection);
