import { classnames } from '@tailwindcss-classnames';

export const wrapper = classnames('flex', 'items-center', 'gap-2.5');

const onTopCard = classnames('rounded-t', 'w-full');
const insideCard = classnames(
  'rounded-md',
  'max-w-max',
  'flex',
  'items-center',
  'justify-center',
  'max-h-max'
);
export const base = (isInner: boolean): string =>
  classnames(
    {
      [insideCard]: isInner,
      [onTopCard]: !isInner,
    },
    'font-semibold',
    'text-xs',
    'py-1',
    'px-2',
    'inline-block',
    'items-center'
  );

export const stock = classnames(
  'text-nzxt-mint-400',
  'tracking-tight',
  'leading-5'
);

export const containerMessage = classnames(
  'items-center',
  'rounded-sm',
  'text-sm',
  'font-medium',
  'w-full',
  'text-white'
);

export const bestSeller = classnames('text-nzxt-teal-500', 'bg-nzxt-teal-100');
export const outOfStock = classnames(
  'text-nzxt-dark-grey-700',
  'bg-nzxt-dark-grey-200'
);
export const lowStock = classnames('bg-nzxt-yellow-200', 'text-nzxt-brown-500');
export const onSale = classnames('bg-nzxt-green-400', 'text-white');
export const isPromo = classnames('bg-nzxt-green-200', 'text-nzxt-green-500');
export const invalid = classnames(
  'text-nzxt-red-400',
  'border',
  'border-nzxt-red-400'
);
export const newItem = classnames('bg-nzxt-blue-400', 'text-white');
export const comingSoon = classnames('text-white', 'bg-nzxt-blue-300');

export const isLocked = classnames('bg-nzxt-light-grey-500');
export const onClearance = classnames('bg-nzxt-red-400', 'text-white');
