// TODO - Shopify Cart API randomly stopped encoding cart IDs
// commenting out old function for now
// this also impacts areas of the code where we used atob directly

// const decodeVariantId = (cartId: string): string =>
//   atob(cartId || '').replace('gid://shopify/ProductVariant/', '');

const decodeVariantId = (cartId: string): string =>
  cartId.replace('gid://shopify/ProductVariant/', '');

export default decodeVariantId;
