import { FC } from 'react';
import { IconCheck } from '@nzxt/react-icons';
import { useTranslation as t } from '@utils/hooks';
import * as styles from './styles';

const SuccessView: FC = () => (
  <div className={styles.contentContainer}>
    <span className={styles.iconWrapper}>
      <IconCheck />
    </span>
    <h2 className={styles.secondaryText}>{t('cart_heading_success')}</h2>
  </div>
);

export default SuccessView;
