/* eslint-disable react/no-unstable-nested-components */
import { ComponentType, FC, memo } from 'react';
import dynamic from 'next/dynamic';

type Props = {
  iconName: string;
  className?: string;
};

type IconProps = {
  className?: string;
};

const IconDynamic: FC<Props> = ({ iconName, ...props }): JSX.Element => {
  const Icon: ComponentType<IconProps> = dynamic(
    () => import(`@nzxt/react-icons/lib/${iconName}`),
    {
      loading: () => <div className={props.className} />,
    }
  );

  return <Icon {...props} />;
};

export default memo(IconDynamic);
