import { classnames } from '@tailwindcss-classnames';

export const outerWrapper = classnames(
  'lg:p-3',
  'mt-auto',
  'lg:my-auto',
  'lg:-ml-1',
  'flex',
  'justify-center',
  'group',
  'lg:h-full',
  'lg:bg-nzxt-dark-grey-800'
);

export const innerWrapper = (isFullCta: boolean): string =>
  classnames(
    'lg:-ml-1',
    { 'px-2': !isFullCta },
    'lg:rounded-md',
    'h-full',
    'relative',
    'overflow-hidden',
    'flex',
    'lg:flex-col',
    'lg:shadow-lg',
    'lg:group-hover:shadow-xl',
    'lg:max-w-xs'
  );

export const imageWrapper = classnames('mt-auto');
export const image = classnames(
  'scale-100',
  'transform',
  'transition-transform',
  'duration-700',
  'group-hover:scale-105'
);
export const innerColor = {
  purple: classnames(
    'bg-gradient-to-b',
    'from-nzxt-violet-400',
    'to-nzxt-volt-400'
  ),
  darkgrey: classnames('bg-nzxt-light-grey-900'),
  white: classnames('bg-nzxt-light-grey-900', 'lg:bg-white'),
};

export const innerColorText = {
  purple: classnames('text-white'),
  darkgrey: classnames('text-white'),
  white: classnames('text-nzxt-light-grey-800'),
};

export const linkWrapper = classnames(
  'flex',
  'flex-col',
  'items-center',
  'py-8',
  'lg:p-3',
  'my-auto',
  'lg:mt-3'
);
export const header = (hasBackgroundImage?: boolean): string =>
  hasBackgroundImage
    ? classnames('text-xl', 'font-black', 'text-center')
    : classnames('text-base', 'font-semibold', 'text-center');

export const badge = classnames(
  'ml-3',
  'inline-flex',
  'items-center',
  'px-3',
  'py-0.5',
  'rounded-full',
  'text-xs',
  'font-medium',
  'leading-5',
  'bg-indigo-100',
  'text-nzxt-light-grey-800'
);
export const bodyCopy = classnames('mt-1', 'text-sm', 'pb-2', 'text-center');
const activeColors = classnames('text-nzxt-light-grey-100');

const activeAction = classnames('rotate-180');

export const chevron = (isActive: boolean): string =>
  classnames(
    'h-3',
    {
      [activeColors]: isActive,
      [activeAction]: isActive,
    },
    'stroke-current',
    'ml-1',
    'transition-transform',
    'duration-200',
    'transform',
    'group-nested-link-hover:text-nzxt-light-grey-100'
  );
