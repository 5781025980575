const formatPartLabel = (label: string): string => {
  switch (label) {
    case 'cpu': {
      return 'cart_label_cpu';
    }
    case 'gpu': {
      return 'cart_label_gpu';
    }
    case 'ram': {
      return 'cart_label_ram';
    }
    case 'ssd': {
      return 'cart_label_ssd';
    }
    case 'cpuCooler': {
      return 'cart_label_cpu_cooler';
    }
    case 'powerSupply': {
      return 'cart_label_power_supply';
    }
    case 'hdd': {
      return 'cart_label_hdd';
    }
    case 'rgbs': {
      return 'cart_label_rgb';
    }
    case 'caseFans': {
      return 'cart_label_fans';
    }
    case 'cases': {
      return 'cart_label_case';
    }
    case 'case': {
      return 'cart_label_case';
    }
    case 'motherboards': {
      return 'cart_label_motherboard';
    }
    case 'motherboard': {
      return 'cart_label_motherboard';
    }
    case 'coolers': {
      return 'cart_label_cpu_cooler';
    }
    case 'cpus': {
      return 'cart_label_cpu';
    }
    case 'psus': {
      return 'cart_label_power_supply';
    }
    case 'gpus': {
      return 'cart_label_gpu';
    }
    case 'softwares': {
      return 'cart_label_software';
    }
    case 'software': {
      return 'cart_label_software';
    }
    case 'services': {
      return 'cart_label_service';
    }
    case 'peripherals': {
      return 'cart_label_peripherals';
    }
    case 'monitors': {
      return 'cart_label_monitors';
    }
    case 'extras': {
      return 'cart_label_extras';
    }

    // Keyboard
    case 'base': {
      return 'Keyboard Color';
    }
    case 'cable': {
      return 'Cable';
    }
    case 'deskMat': {
      return 'Desk Mat';
    }
    case 'wristRest': {
      return 'Wrist Rest';
    }
    case 'keycap': {
      return 'cart_label_keycaps';
    }
    case 'keycapAccent': {
      return 'cart_label_keycap_accents';
    }
    case 'switch': {
      return 'cart_label_switches';
    }
    case 'switches': {
      return 'cart_label_switches';
    }
    case 'keyboard_base': {
      return 'cart_label_keyboard_base';
    }
    case 'KeyboardBase': {
      return 'cart_label_keyboard_base';
    }
    case 'keycaps': {
      return 'cart_label_keycaps';
    }
    case 'keycaps_accent': {
      return 'cart_label_keycap_accent';
    }
    case 'KeyboardAccents': {
      return 'cart_label_keycap_accent';
    }
    case 'accent_cable': {
      return 'cart_label_accent_cable';
    }
    case 'mouse': {
      return 'cart_label_mouse';
    }

    default: {
      return label;
    }
  }
};

export default formatPartLabel;
