/* eslint-disable react/no-unescaped-entities */
/* eslint-disable camelcase */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-param-reassign */
/* eslint-disable no-return-assign */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable func-names */
/* eslint-disable max-len */
/* eslint-disable prefer-template */
/* eslint-disable object-shorthand */
/* eslint-disable no-unused-vars */
/* eslint-disable dot-notation */
/* eslint-disable arrow-body-style */
/* eslint-disable eqeqeq */
/* eslint-disable consistent-return */
/* eslint-disable no-lonely-if */
/* eslint-disable no-nested-ternary */
/* eslint-disable @typescript-eslint/no-shadow */
import { FC, memo, useRef, useState } from 'react';
import cn from 'clsx';
import useDeepCompareEffect from 'use-deep-compare-effect';
import useAddItem from '@framework/cart/use-add-item';
import useCart from '@framework/cart/use-cart';
import type { ShopifyLineEdges } from '@framework/api/cart';
import GTM from '@utils/gtm';
import { useMedia } from '@utils/hooks';
import decodeVariantId from '@utils/decode-variant-id';
import Button from '@components/Button';
import FPCStyles from './FullPageCart.module.css';
import * as styles from './styles';
import getExtendPricePoint from './get-extend-price-point';

type Props = {
  item: ShopifyLineEdges;
  price?: string;
  build?: ShopifyLineEdges[];
  isPeripheral?: boolean;
  isFullPageCart?: boolean;
};

const ExtendCartOffer: FC<Props> = ({
  item,
  price,
  build,
  isPeripheral,
  isFullPageCart,
}) => {
  // ----- Hooks ----- //
  const { data } = useCart();
  const addItem = useAddItem('cart');
  const isSm = useMedia('sm');

  // current Item variantId
  let shopify_variants_id = decodeVariantId(item?.node.merchandise.id);
  let extendOffer;
  const qtyRef = useRef(1);
  const offerClass = '';
  let buildNumber;
  let parentVariantId;
  let isCustomBuild = false;
  let bandRefId;
  let warrantyInCart;
  let currentPlans;
  let isLead = false;
  if (build) {
    const customBuildCase = build.filter(buildItem => {
      return buildItem.node.merchandise.product.productType === 'Cases';
    });
    isCustomBuild = true;
    buildNumber = customBuildCase[0]?.node?.attributes[0]?.value;
  }

  const hybridId = item?.node.attributes.find(
    att => att.key === '_hybrid_id'
  )?.value;

  const customId = isCustomBuild
    ? `extend-cart-offer-${shopify_variants_id}-${buildNumber}`
    : `extend-cart-offer-${shopify_variants_id}`;

  const coveredProductId = `${
    item?.node.attributes.find(att => att.key === '_Extend.ProductId')?.value
  }`;

  // custom offer
  const customOfferSelector = document.querySelector(
    `#${customId}-customoffer`
  ) as HTMLDivElement;

  // simpleOffer
  const simpleOffer = document.querySelector(
    `#simpleOffer-${customId}`
  ) as HTMLDivElement;

  // ----- Util Functions ----- //

  // getPriceBand - Rounds price up to the nearest mutiple of 25 or  multiple 100 if number is above 100
  function getPriceBand(total) {
    if (!total) {
      return;
    }

    // Rounds up in increments of 100 if price is over 999, otherwise it will round in increments of 25
    const bandPrice =
      Number(total) > 999
        ? Math.ceil(Number(total) / 100) * 100
        : Math.ceil(Number(total) / 25) * 25;
    return bandPrice;
  }

  // getPriceBandRefId - Returns the priceBand variantId
  function getPriceBandRefId(planId, productPrice) {
    // Grabs planId and returns string between the dashes ( e.g. '1B0-VEAPS-3y' => 'BVEAPSy')
    const planName = isPeripheral
      ? planId.replace(/[-By]/g, '').replace(/[0-9]/g, '')
      : planId.replace(/-/g, '').replace(/[0-9]/g, '');

    // Encodes planName from letters to numbers and adds '111' to beginning of string (e.g. BVEAPSy => 22511619 => 22251161925)
    const planKey = isPeripheral
      ? '111' +
        planName
          .split('')
          .map(str => str.toLowerCase().charCodeAt(0) - 96)
          .join('')
      : planName
          .split('')
          .map(str => str.toLowerCase().charCodeAt(0) - 96)
          .join('');

    const priceBandPrice = getPriceBand(productPrice);

    // Concatenates planKey and priceBandPrice (e.g. 22511619 + 75 => 2251161975)
    if (planKey && priceBandPrice) {
      return planKey + priceBandPrice;
    }
  }

  // Determines if we should display Extend offers or not
  const checkRender = () => {
    const prods = data?.lines.edges.filter(i => i.node.quantity > 0);

    prods?.forEach(itm => {
      if (
        itm.node?.attributes &&
        itm.node?.attributes[3] &&
        itm.node?.attributes[3]?.value === extendOffer.id.split('-')[3]
      ) {
        warrantyInCart = true;
        extendOffer.style.display = 'none';

        // If custom offer, hide
        if (customOfferSelector) {
          customOfferSelector.style.display = 'none';
        }
      }
    });

    // If extendOffer and warrantyInCart OR the item qty is less than 1 hide warranties
    if ((extendOffer && warrantyInCart) || item.node.quantity < 1) {
      extendOffer.style.display = 'none';

      // If custom offer, hide
      if (document.querySelector(`#${customId}-customoffer`)) {
        (
          document.querySelector(`#${customId}-customoffer`) as HTMLDivElement
        ).style.display = 'none';

        // de-select the selected button
        document
          .querySelector(`#${customId}-customoffer`)
          .querySelector('#offer-container')
          .querySelectorAll(`.${FPCStyles.btnoffer}`)
          .forEach(btn => {
            if (btn.classList.contains(`${FPCStyles.active}`)) {
              btn.classList.remove(`${FPCStyles.active}`);
            }

            // Get Iframe
            const iFrameDocument = (
              document.querySelector(
                `#${customId} div iframe`
              ) as HTMLIFrameElement
            ).contentDocument;
            if (iFrameDocument) {
              const allSDKOfferTerms = iFrameDocument
                .querySelector('body')
                ?.querySelector('.component-container')
                ?.querySelector('.button-group')
                ?.querySelectorAll('.btn-offer');

              if (allSDKOfferTerms) {
                // IF we click on the current offer, select it in the SDK
                allSDKOfferTerms.forEach(term => {
                  if (term?.classList?.contains('active')) {
                    (term as HTMLDivElement).click();
                  }
                });
              }
            }
          });
      }
    } else {
      // If custom offer exists, set it to block display
      if (document?.querySelector(`#${customId}-customoffer div`)) {
        if (
          document
            ?.querySelector(`#${customId}-customoffer div`)
            .querySelector('#offer-container')?.children?.length !== 0
        ) {
          (
            document.querySelector(`#${customId}-customoffer`) as HTMLDivElement
          ).style.display = 'block';
        }
      }

      // Track extended warranty button display in cart
      if (extendOffer && typeof window !== 'undefined' && window.Extend) {
        GTM.dataLayer({
          dataLayer: {
            event: 'warrantiesDisplayed',
            display: Boolean(extendOffer),
            warranties: null,
            location: 'Cart',
          },
        });
      }
    }
  };

  // Handles adding items to the cart, used in cart offers
  const addToCart = async (
    extendId,
    extendQty,
    details,
    bandRefId
  ): Promise<void> => {
    try {
      await addItem({
        shopify_variants_id: extendId,
        quantity: extendQty,
        attributes: [
          build
            ? {
                key: `Ref`,
                value: `price-band;xtd;${buildNumber}`,
              }
            : null,
          build
            ? {
                key: '_build_number',
                value: `${buildNumber}`,
              }
            : null,
          build
            ? {
                key: 'XtdSku',
                value: `${details.planId}-${bandRefId}`,
              }
            : null,
          {
            key: `Extend.IsExtendWarranty`,
            value: build ? `false` : `true`,
          },
          {
            key: `_Extend.PlanId`,
            value: `${details.planId}`,
          },
          {
            key: `_Extend.IsPricePoint`,
            value: `true`,
          },
          {
            key: `_Extend.ProductId`,
            value: build ? `${buildNumber}` : `${shopify_variants_id}`,
          },
          isPeripheral
            ? {
                key: 'PeripheralId',
                value: `${shopify_variants_id}`,
              }
            : null,
          hybridId ? { key: '_hybrid_id', value: hybridId } : null,
        ].filter(Boolean),
      });
      // track when extended warranty is added to cart
      GTM.dataLayer({
        dataLayer: {
          event: 'warrantyATC',
          warrantyLength: details.term,
          warrantyPrice: (details?.price / 100).toFixed(2),
          location: 'Cart',
          referralSource: 'Cart CTA',
        },
      });
      checkRender();
    } catch (err) {
      return err;
    }
    return null;
  };

  // Handles adding Extend to cart for custom offers and determines if we display offers or not using checkRender()
  const addExtendToCart = async () => {
    // Get the current hidden Extend offer
    const component = window.Extend.buttons.instance(extendOffer);

    // Get the current selected plan
    const Selectedplan = component?.getPlanSelection();

    if (Selectedplan) {
      const planVariant = await getExtendPricePoint(Selectedplan);

      addToCart(planVariant, qtyRef.current, Selectedplan, bandRefId);
      (
        document
          .querySelector(`#${customId}-customoffer`)
          .querySelector('.warranty-header')
          .querySelector('.atc-btn-container')
          .querySelector('#addExtendButton') as HTMLButtonElement
      ).disabled = true;

      // Determines if we should display Extend offers or not
      checkRender();
    }
  };

  // Clicks the Extend SDK CTA 'Whats covered?' button to pop our SDK learn more modal
  const clickCTA = () => {
    const iFrameDocument = (
      document.querySelector(`#${customId} div iframe`) as HTMLIFrameElement
    ).contentDocument;
    if (iFrameDocument) {
      // Grab the body of the iframe
      const SDKOfferInfo = iFrameDocument?.querySelector('body');

      // Click SDK CTA button
      (
        SDKOfferInfo?.querySelector('.component-container')
          ?.querySelector('.caption')
          ?.querySelector('.info-button') as HTMLDivElement
      ).click();
    }
  };

  // Takes in referenceId and adds the custom offer term buttons to the custom offer div
  const displayCustomOffer = (referenceId, isPriceBand) => {
    // FULL PAGE CUSTOM CART OFFER --------------------
    if (referenceId) {
      window.Extend.getOffer(referenceId?.toString()).then(offer => {
        if (offer.plans.adh.length !== 0 || offer.plans.base.length !== 0) {
          // Set currentPlans to be either ADH or BASE plans depending on what Extend plans are mapped
          if (offer.plans.adh.length !== 0) {
            currentPlans = offer.plans.adh;
          } else if (offer.plans.base.length !== 0) {
            currentPlans = offer.plans.base;
          }

          // Handles creating the offer button terms, 1y, 2y, 3y, etc
          currentPlans.forEach((plan, customOfferindex) => {
            // Create offer button terms
            const customExtendOfferTermButton =
              document.createElement('button');
            customExtendOfferTermButton.type = 'submit';
            customExtendOfferTermButton.className = `${FPCStyles.btnoffer} w-52 flex-initial`;
            customExtendOfferTermButton.style.textAlign = 'left';
            customExtendOfferTermButton.style.padding = '15px';
            // Create custom id based on current term index
            customExtendOfferTermButton.id = `${customId}-term-${customOfferindex}`;

            // Handles clicking the correct SDK offer when a custom offer is clicked
            customExtendOfferTermButton.addEventListener('click', () => {
              // If button not currently active
              if (
                !customExtendOfferTermButton.classList.contains(
                  `${FPCStyles.active}`
                )
              ) {
                // Check all buttons and remove their active class
                document
                  .querySelector(`#${customId}-customoffer`)
                  .querySelector('#offer-container')
                  .querySelectorAll(`.${FPCStyles.btnoffer}`)
                  .forEach(btn => {
                    if (btn.classList.contains(`${FPCStyles.active}`)) {
                      btn.classList.remove(`${FPCStyles.active}`);
                    }
                  });

                // set active to clicked button and style it
                customExtendOfferTermButton.classList.add(
                  `${FPCStyles.active}`
                );
              } else if (
                customExtendOfferTermButton.classList.contains(
                  `${FPCStyles.active}`
                )
              ) {
                customExtendOfferTermButton.classList.remove(
                  `${FPCStyles.active}`
                );
              }

              // Get Iframe
              const iFrameDocument = (
                document.querySelector(
                  `#${customId} div iframe`
                ) as HTMLIFrameElement
              ).contentDocument;
              if (iFrameDocument) {
                const allSDKOfferTerms = iFrameDocument
                  .querySelector('body')
                  .querySelector('.component-container')
                  .querySelector('.button-group')
                  .querySelectorAll('.btn-offer');

                // IF we click on the current offer, select it in the SDK
                allSDKOfferTerms.forEach((term, sdkIndex) => {
                  if (customOfferindex === sdkIndex) {
                    (term as HTMLDivElement).click();
                    if (term.classList.contains('active')) {
                      (
                        document
                          .querySelector(`#${customId}-customoffer`)
                          .querySelector('.warranty-header')
                          .querySelector('.atc-btn-container')
                          .querySelector(
                            '#addExtendButton'
                          ) as HTMLButtonElement
                      ).disabled = false;
                    } else {
                      (
                        document
                          .querySelector(`#${customId}-customoffer`)
                          .querySelector('.warranty-header')
                          .querySelector('.atc-btn-container')
                          .querySelector(
                            '#addExtendButton'
                          ) as HTMLButtonElement
                      ).disabled = true;
                    }
                  }
                });
              }
            });

            // Create custom offer Buttons
            window.setTimeout(() => {
              const iFrameDocument = (
                document.querySelector(
                  `#${customId} div iframe`
                ) as HTMLIFrameElement
              ).contentDocument;
              if (iFrameDocument) {
                const SDKOfferInfo = iFrameDocument?.querySelector('body');

                const SDKOfferButtons = SDKOfferInfo?.querySelector(
                  '.component-container'
                )
                  ?.querySelector('.button-group')
                  ?.querySelectorAll('.btn-offer');

                // All SDK offers
                SDKOfferButtons?.forEach((sdkOffer, sdkIndex) => {
                  if (customOfferindex === sdkIndex) {
                    const offerTerm = sdkOffer
                      .querySelector('.btn-content')
                      .querySelector('.term-length').textContent;
                    const offerPrice = sdkOffer
                      .querySelector('.btn-content')
                      .querySelector('.plan-price').textContent;

                    // Create info for offer button
                    const customExtendOfferTermButtonInfo =
                      document.createElement('div');
                    customExtendOfferTermButtonInfo.id =
                      customId + '-customOfferButtons';
                    customExtendOfferTermButtonInfo.className = 'btn-info';

                    // Append first offer button to offer button container
                    customExtendOfferTermButton.append(
                      customExtendOfferTermButtonInfo
                    );

                    const customExtendOfferTermButtonTermLength =
                      document.createElement('p');
                    customExtendOfferTermButtonTermLength.innerText = offerTerm;
                    customExtendOfferTermButtonTermLength.style.fontWeight =
                      'bold';

                    const customExtendOfferTermButtonMetaData =
                      document.createElement('p');
                    customExtendOfferTermButtonMetaData.innerText =
                      'Protection Plan';
                    customExtendOfferTermButtonMetaData.style.fontSize =
                      '0.8rem';

                    const customExtendOfferTermButtonPlanPrice =
                      document.createElement('p');
                    customExtendOfferTermButtonPlanPrice.innerText = `${offerPrice}`;
                    customExtendOfferTermButtonPlanPrice.style.fontWeight =
                      'bold';

                    customExtendOfferTermButton.append(
                      customExtendOfferTermButtonTermLength,
                      customExtendOfferTermButtonMetaData,
                      customExtendOfferTermButtonPlanPrice
                    );

                    if (document.querySelector(`#${customId}-customoffer`)) {
                      document
                        .querySelector(`#${customId}-customoffer`)
                        .querySelector('#offer-container')
                        .append(customExtendOfferTermButton);
                    }
                  }
                });
              }
            }, 50);
          });

          // When the ATC button on the custom offer is clicked invoke addExtendToCart() passing in the referenceId we took in at the top of displayCustomOffer()
          if (document.querySelector(`#${customId}-customoffer`)) {
            document
              .querySelector(`#${customId}-customoffer`)
              .querySelector('.warranty-header')
              .querySelector('.atc-btn-container')
              .querySelector('#addExtendButton')
              .addEventListener('click', () => {
                addExtendToCart();
              });
          }

          // If we have an iframe from our normal SDK offers set isWarrantble to true as we know its a warrantable product
          if (!document.querySelector(`#${customId} div iframe`)) {
            (
              document.querySelector(
                `#${customId}-customoffer`
              ) as HTMLDivElement
            ).style.display = 'none';
          }
        }
      });
    }
  };

  window.setTimeout(() => {
    if (document.querySelector(`#${customId}-customoffer div`)) {
      if (
        document
          ?.querySelector(`#${customId}-customoffer div`)
          .querySelector('#offer-container')?.children?.length <= 0
      ) {
        (
          document.querySelector(`#${customId}-customoffer`) as HTMLDivElement
        ).style.display = 'none';
      }
    }
  }, 500);

  // Runs any time the cart (data) changes
  useDeepCompareEffect(() => {
    warrantyInCart = false;

    qtyRef.current = item?.node?.quantity ?? 0;

    const prods = data?.lines.edges.filter(i => i.node.quantity > 0);

    // If current item is not a warranty
    if (
      item?.node?.merchandise?.product?.title?.indexOf(
        'Extend Protection Plan'
      ) === -1
    ) {
      // Loop through cart
      prods?.forEach(each => {
        if (
          each.node?.attributes[3]?.value &&
          shopify_variants_id === each.node?.attributes[3]?.value
        ) {
          warrantyInCart = true;
        } else {
          if (
            (each.node.attributes &&
              each.node.attributes[0] &&
              each.node.attributes[0].key === '_build_number') ||
            isPeripheral
          ) {
            // confirms if we're on custom build or not
            if (
              each.node.attributes &&
              each.node.attributes[0] &&
              each.node.attributes[0].key === '_build_number'
            ) {
              isCustomBuild = true;
            }
            if (
              typeof window !== 'undefined' &&
              window.Extend &&
              !document.querySelector(`#${customId} div`)
            ) {
              // Set extendOffer variable to current offerId
              extendOffer = document.querySelector(`#${customId}`);

              // Fail safe
              if (!price) return;

              bandRefId = getPriceBandRefId(
                'B0-NZXT-1y',
                parseInt(price.replace(/[^0-9.]/g, '').split('.')[0], 10)
              );

              // If no extendOffer container return so we dont try and render simpleOffers on cart items
              if (!extendOffer) return;

              const originalOpen = window.Extend.modal.open;

              window.Extend.modal.open = function (e) {
                const warrantiesArray = [];

                window.Extend.getOffer(shopify_variants_id.toString()).then(
                  res => {
                    res.plans['base'].forEach(function (offer) {
                      const warrantyObject = {
                        length: offer?.contract?.termLength,
                        price: (offer?.price / 100).toFixed(2),
                      };
                      warrantiesArray.push(warrantyObject);
                    });
                    // track extended warranty modal display
                    GTM.dataLayer({
                      dataLayer: {
                        event: 'warrantyModal',
                        warranties: warrantiesArray,
                      },
                    });
                  }
                );
                return originalOpen.apply(this, [e]);
              };

              if (
                (!warrantyInCart &&
                  item.node.merchandise.product.productType !== 'prebuilds' &&
                  isPeripheral) ||
                build
              ) {
                if (isFullPageCart && !isSm) {
                  // Hide standard SDK offers if isFullPageCart
                  (
                    document.querySelector(`#${customId}`) as HTMLDivElement
                  ).style.display = 'none';

                  // Display custom offer instead of SDK offer
                  displayCustomOffer(bandRefId, true);

                  // Render regular SDK offers
                  window.Extend.buttons.render(extendOffer, {
                    referenceId: bandRefId,
                  });
                }
              }
            }
          }
        }
      });

      // If build prop exists
      if (build) {
        // Loop through all items in the build and find the case variantId
        build.forEach(buildItem => {
          if (buildItem.node.merchandise.product.productType === 'Cases') {
            shopify_variants_id = decodeVariantId(
              buildItem.node.merchandise.id
            );
            if (
              buildItem.node.attributes &&
              buildItem.node.attributes[0].key === '_build_number'
            ) {
              buildNumber = buildItem.node.attributes[0].value;
            }
          }
        });

        // Loop through all products in the cart
        prods?.forEach(cartItem => {
          // If we found the case in the cart
          if (
            decodeVariantId(cartItem.node.merchandise.id) ===
            shopify_variants_id
          ) {
            // Check for Extend
            prods?.forEach(eachItem => {
              if (
                eachItem.node.attributes &&
                eachItem.node.attributes[0] &&
                eachItem.node.attributes[0].key &&
                eachItem.node.attributes[0].key === 'Ref' &&
                eachItem.node.attributes[0].value.split(';')[2] ===
                  buildNumber &&
                eachItem.node.attributes[1] &&
                eachItem.node.attributes[1].key === '_build_number' &&
                eachItem.node.attributes[1].value === buildNumber
              ) {
                // If the Extend warranty contains the case variantId in the Ref set warrantyInCart
                if (
                  eachItem.node.attributes[0].value.split(';')[2] ===
                    buildNumber &&
                  eachItem.node.attributes[1].key === '_build_number' &&
                  eachItem.node.attributes[1].value === buildNumber
                ) {
                  warrantyInCart = true;
                } else {
                  warrantyInCart = false;
                }
              }
            });
          }
        });
      }

      // extendOffer on page
      extendOffer = document.querySelector(`#${customId}`);

      // If peripheral in cart and warranty in cart
      if (
        prods?.filter(
          itm =>
            itm.node.attributes &&
            itm.node.attributes[1] &&
            itm.node.attributes[1].value ===
              decodeVariantId(item.node.merchandise.id)
        ).length !== 0
      ) {
        warrantyInCart = true;
      }

      // Determines if we should display Extend offers or not
      checkRender();
    }

    // Loop through the cart
    prods?.forEach(cartItem => {
      // If component item matches a cart item and is an Extend warranty
      if (
        cartItem.node.merchandise.product?.title ===
          item?.node?.merchandise?.product?.title &&
        item.node.merchandise.product.title.indexOf('Extend Protection Plan') >
          -1
      ) {
        // Assigns the parent product ID of the warranty to a variable
        parentVariantId = item.node.attributes.find(
          att => att.key === '_Extend.ProductId'
        )
          ? item.node.attributes.find(att => att.key === '_Extend.ProductId')
              .value
          : null;

        // Else if component item matches cart item and is NOT an Extended warranty
      } else if (
        cartItem.node.merchandise.title === item?.node?.merchandise?.title
      ) {
        // Loop through the cart
        prods?.forEach(extendItem => {
          if (
            extendItem.node.merchandise.product.title.indexOf(
              'Extend Protection Plan'
            ) > -1
          ) {
            extendOffer = document.querySelector(`#${customId}`);
          } else {
            window.setTimeout(function () {
              if (
                typeof window !== 'undefined' &&
                window.Extend &&
                !document.querySelector(`#${customId} div`)
              ) {
                extendOffer = document.querySelector(`#${customId}`);

                // If no extendOffer container return so we dont try and render simpleOffers on cart items
                if (!extendOffer) return;

                const originalOpen = window.Extend.modal.open;
                window.Extend.modal.open = function (e) {
                  const warrantiesArray = [];

                  window.Extend.getOffer(shopify_variants_id.toString()).then(
                    res => {
                      res.plans['base'].forEach(function (each) {
                        const warrantyObject = {
                          length: each?.contract?.termLength,
                          price: (each?.price / 100).toFixed(2),
                        };
                        warrantiesArray.push(warrantyObject);
                      });
                      // Track extended warranty modal display
                      GTM.dataLayer({
                        dataLayer: {
                          event: 'warrantyModal',
                          warranties: warrantiesArray,
                        },
                      });
                    }
                  );
                  return originalOpen.apply(this, [e]);
                };

                // Find warranty that has same id as peripheral product
                if (
                  prods?.filter(
                    itm =>
                      itm.node.attributes &&
                      itm.node.attributes[1] &&
                      itm.node.attributes[1].value ===
                        decodeVariantId(item.node.merchandise.id)
                  ).length === 0
                ) {
                  // Display custom offer instead of SDK offer
                  if (isFullPageCart && !isSm) {
                    // Hide standard SDK offers if isFullPageCart
                    (
                      document.querySelector(`#${customId}`) as HTMLDivElement
                    ).style.display = 'none';
                    displayCustomOffer(shopify_variants_id, false);

                    // Render regular SDK offers
                    window.Extend.buttons.render(extendOffer, {
                      referenceId: shopify_variants_id,
                    });
                  }
                }
              }
            }, 100);
          }
        });
      }
    });

    // If we're on a Extend warranty
    if (
      item?.node?.merchandise?.product?.title?.indexOf(
        'Extend Protection Plan'
      ) > -1
    ) {
      // Set buildNumberCheck to build number the warranty is covering
      if (item.node.attributes.find(att => att.key === '_build_number')) {
        const buildNumberCheck = item.node.attributes.find(
          att => att.key === '_build_number'
        ).value;

        // Loop through all Items in cart
        data.lines.edges.forEach(itemToCheck => {
          // Check if current item has a _build_number attribute equal to the buildNumberCheck
          if (
            itemToCheck.node.attributes.find(
              att => att.key === '_build_number'
            ) &&
            itemToCheck.node.attributes.find(att => att.key === '_build_number')
              .value === buildNumberCheck
          ) {
            isCustomBuild = true;
          }
        });
      }

      const warrantyVariantId = item.node.attributes.find(
        att => att.key === '_Extend.ProductId'
      )
        ? item.node.attributes.find(att => att.key === '_Extend.ProductId')
            .value
        : null;

      // find protected item in cart
      let warrantyProd;
      let warrantyVariant;

      const translateTerm = extendPlan => {
        const term = extendPlan.node.attributes.find(
          att => att.key === '_Extend.PlanId'
        )
          ? extendPlan.node.attributes
              .find(att => att.key === '_Extend.PlanId')
              .value.split('-')[2]
          : null;
        switch (term) {
          case '1y':
            return '1 Year';
            break;
          case '2y':
            return '2 Years';
            break;
          case '3y':
            return '3 Years';
            break;
          case '4y':
            return '4 Years';

            break;
          default:
            return false;
        }
      };

      data.lines.edges.forEach(prod => {
        if (
          prod.node.merchandise.id.split('/ProductVariant/')[1] ===
          warrantyVariantId
        ) {
          warrantyProd = prod.node.merchandise.product.title;
          warrantyVariant = prod.node.merchandise.title;
        }
      });

      if (item.node.attributes.find(att => att.key === '_coveredProduct')) {
        isLead = true;
        warrantyProd = item.node.attributes.find(
          att => att.key === '_coveredProduct'
        ).value;
      }

      // If line item is a customBuild
      if (item.node.attributes.find(att => att.key === 'Ref')) {
        data.lines.edges.forEach(prod => {
          if (
            prod.node.merchandise.product.productType === 'Cases' &&
            prod.node.attributes &&
            prod.node.attributes[0] &&
            prod.node.attributes[0].key === '_build_number'
          ) {
            warrantyProd = prod.node.merchandise.product.title;
          }
        });
      }

      const warrantyTerm = translateTerm(item);
      const warrantyProdEl = document.createElement('p');

      if (!warrantyProd) return;

      warrantyProdEl.innerHTML = isCustomBuild
        ? `<strong>Product:</strong> ${warrantyProd} Build`
        : isLead
        ? `<strong>Product:</strong> ${warrantyProd}`
        : warrantyVariant
        ? `<strong>Product:</strong> ${warrantyProd} (${warrantyVariant})`
        : `<strong>Product:</strong> ${warrantyProd}`;
      warrantyProdEl.className = 'extend-warranty-info';
      warrantyProdEl.style.marginTop = '-10px';
      warrantyProdEl.style.fontSize = '.8rem';

      const warrantyTermEl = document.createElement('p');
      warrantyTermEl.innerHTML = `<strong>Term:</strong> ${warrantyTerm}`;
      warrantyTermEl.className = 'extend-warranty-info';
      warrantyTermEl.style.marginBottom = '10px';
      warrantyTermEl.style.fontSize = '.8rem';

      let warrantyTitle;

      document
        .querySelectorAll(`[data-coveredProductId="${coveredProductId}"]`)
        .forEach(title => {
          title.parentElement.querySelector('.text-base span').innerHTML =
            'Extend Protection Plan';
          warrantyTitle = title.parentElement.querySelector('.text-base span');

          if (
            title.parentElement.querySelector('.text-base span').innerHTML ===
            'Extend Protection Plan'
          ) {
            if (title.parentElement.querySelector('.extend-warranty-info')) {
              title.parentElement
                .querySelector('.extend-warranty-info')
                .remove();
            }

            title.parentElement
              .querySelector('div .text-base')
              .insertBefore(
                warrantyProdEl,
                title.parentElement.querySelector('div .text-base div')
              );
            title.parentElement
              .querySelector('div .text-base')
              .insertBefore(
                warrantyTermEl,
                title.parentElement.querySelector('div .text-base div')
              );
          }
        });
    }
    window.setTimeout(function () {
      window.dispatchEvent(new Event('resize'));
    }, 200);

    // If simple offer is rendered and 'isSm' hook true, we display regular SDK offers and hide custom offers.
    if (document.querySelector(`#simpleOffer-${customId}`)) {
      if (isSm) {
        if (customOfferSelector) {
          // fix for broken warranties via simpleOffer
          customOfferSelector.style.display = 'block';
        }
        // fix for broken warranties via simpleOffer
        simpleOffer.style.display = 'none';

        // Handles instance already exists errors
        const instanceAlreadyExists =
          window.Extend.buttons.instance(simpleOffer) !== null;

        if (!instanceAlreadyExists && !warrantyInCart) {
          // Handles returning referenceIDToUse based on either pricebands OR Shopify variantIds
          const referenceIdToUse =
            isPeripheral || build
              ? getPriceBandRefId(
                  'B0-NZXT-1y',
                  parseInt(price.replace(/[^0-9.]/g, '').split('.')[0], 10)
                )
              : shopify_variants_id;

          // Render SDK offers on cart Items
          window.Extend.buttons.renderSimpleOffer(simpleOffer, {
            referenceId: referenceIdToUse,
            onAddToCart: function (options) {
              if (options.plan && options.product) {
                const planVariant = getExtendPricePoint(options.plan);

                addToCart(planVariant, qtyRef.current, options.plan, bandRefId);
              }
            },
          });
        } else if (warrantyInCart) {
          // If there is already a warranty in the cart, hide the simple offer
          if (simpleOffer) {
            simpleOffer.style.display = 'none';
          }

          // fix for broken warranties via simpleOffer
          if (customOfferSelector) {
            customOfferSelector.style.display = 'none';
          }
        }
      }
      // If 'isSm' hook is false we show custom offers and hide normal SDK offers
      else {
        if (document.querySelector(`#${customId}-customoffer`) && !isSm) {
          if (document.querySelector(`#${customId}-customoffer div`)) {
            window.setTimeout(() => {
              if (
                document.querySelector(`#${customId} div iframe`) &&
                !warrantyInCart
              ) {
                (
                  document.querySelector(
                    `#${customId}-customoffer`
                  ) as HTMLDivElement
                ).style.display = 'block';
              }
            }, 250);
          }

          if (warrantyInCart) {
            if (customOfferSelector) {
              (
                document.querySelector(
                  `#${customId}-customoffer`
                ) as HTMLDivElement
              ).style.display = 'none';
            }
          }
        }
        (
          document.querySelector(`#simpleOffer-${customId}`) as HTMLDivElement
        ).style.display = 'none';
      }
    }
  }, [data, item, isSm]);

  return (
    <>
      {item.node.merchandise.product.title.indexOf('Extend Protection Plan') ===
      -1 ? (
        <div
          id={`${customId}-customoffer`}
          data-test-id={customId}
          style={{ display: 'none' }}
        >
          <div className={styles.wrapper}>
            <div className={styles.border} />
            <div className={styles.innerWrapper}>
              <p className={styles.heading}>Add-ons</p>
              <div
                style={{
                  borderWidth: '1px',
                  borderRadius: '5px',
                  padding: '25px',
                }}
              >
                <div className="warranty-header" style={{ display: 'flex' }}>
                  <div className="left-column">
                    <p style={{ fontWeight: 'bold' }}>Warranty</p>
                    <div className="caption" style={{ display: 'flex' }}>
                      <p
                        className={cn(
                          'caption-pick-a-plan',
                          styles.warrantySubheading
                        )}
                        style={{ marginRight: '2px' }}
                      >
                        Pick a protection plan.
                      </p>
                      <Button
                        type="submit"
                        onClick={clickCTA}
                        className={cn('learnmore', styles.learnMoreButton)}
                        customMargin
                        buttonStyle="text"
                      >
                        What's covered?
                      </Button>
                    </div>
                  </div>

                  <div
                    className={cn(
                      'atc-btn-container',
                      styles.addButtonContainer
                    )}
                  >
                    <button
                      type="submit"
                      disabled
                      id="addExtendButton"
                      className={cn(
                        'buttonStyles_btn__EKYZF',
                        styles.addButton
                      )}
                    >
                      Add
                    </button>
                  </div>
                </div>

                <div
                  className="mt-5"
                  id="offer-container"
                  style={{ display: 'flex', justifyContent: 'space-evenly' }}
                />
              </div>
            </div>
          </div>
        </div>
      ) : null}
      <div
        id={customId}
        data-coveredproductid={coveredProductId}
        className={offerClass}
        style={{ margin: '20px 0 0 95px' }}
        data-test-id={customId}
      />
      <div
        id={`simpleOffer-${customId}`}
        data-coveredproductid={coveredProductId}
        className={offerClass}
        style={{ margin: '20px 0 0 95px' }}
        data-test-id={`simpleOffer-${customId}`}
      />
    </>
  );
};

export default memo(ExtendCartOffer);
