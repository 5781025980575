import { classnames } from '@tailwindcss-classnames';

export const bodyBg = classnames('flex', 'flex-col', 'relative', 'h-full');

export const pdpConfig = classnames('lg:pb-[110px]');

export const stickFooterContent = classnames('flex-1');

export const overlay = classnames(
  'fixed',
  'top-0',
  'bottom-0',
  'left-0',
  'right-0',
  'bg-nzxt-dark-grey-700',
  'opacity-50',
  'h-screen',
  'w-screen',
  'z-20'
);

export const pageWrapper = classnames(
  'flex',
  'min-h-screen',
  'flex-col',
  'dark:bg-nzxt-dark-grey-900',
  'h-full'
);

export const mainWrapper = classnames(
  'dark:bg-nzxt-dark-grey-800',
  'bg-white',
  'dark:bg-nzxt-dark-grey-900',
  'dark:bg-rad-grad-page',
  'dark:text-white'
);
export const navWrapper = classnames('h-full', 'sticky', 'top-0', 'z-40');
