import { classnames } from '@tailwindcss-classnames';

export const base = classnames('h-full', 'flex', 'flex-col', 'relative');
export const empty = classnames('bg-white');
export const getBaseStyle = (isEmpty?: boolean): string =>
  classnames(base, {
    [empty]: isEmpty,
  });

// Top of Cart Section
export const header = classnames(
  'px-4',
  'pt-4',
  'lg:pt-6',
  'sm:px-6',
  'bg-white',
  'z-10',
  'lg:sticky',
  'lg:top-0'
);

export const cartTop = classnames(
  'border-b',
  'pb-4',
  'flex',
  'justify-between'
);
export const cartHeader = classnames(
  'flex',
  'space-x-3',
  'text-nzxt-light-grey-800'
);

export const heading = classnames(
  'text-xl',
  'leading-7',
  'font-bold',
  'tracking-wide',
  'text-nzxt-light-grey-800',
  'flex',
  'items-center'
);

export const checkmark = classnames('text-nzxt-green-300', 'mr-1');

export const closeButtonIcon = classnames(
  'h-6',
  'w-6',
  'text-nzxt-light-grey-800'
);

// cart notice
export const noticeStyles = classnames('mt-6', 'mb-0');

// cart products section
export const scrollWrapper = classnames(base, 'relative');
export const scrollPositionedInner = classnames('w-full', 'absolute');

const noScroll = classnames('overflow-y-hidden');
export const itemsContainer = (hasMany: boolean): string =>
  classnames(
    'px-4',
    'sm:px-6',
    'flex-1',
    'overflow-y-auto',
    'h-full',
    'lg:pb-4',
    { [noScroll]: hasMany }
  );

// bottom total section
const defaultPaddingBottom = classnames('pb-16');
const paddingBottom = classnames('pb-0');
export const bottomContainer = (isLandScape: boolean): string =>
  classnames('flex-shrink-0', 'px-4', 'lg:pb-4', 'sm:px-6', 'sticky', {
    [paddingBottom]: isLandScape,
    [defaultPaddingBottom]: !isLandScape,
  });

export const cartButton = classnames('w-full', 'text-lg');
export const affirmBadge = classnames('my-4', 'text-center', 'rounded-lg');
