import { setCookie } from 'nookies';
import {
  SYSTEM_AMPLITUDE_COOKIE_NAME,
  SYSTEM_COOKIE_MAX_AGE,
} from '@constants';

const setAmplitudeCookie = (id?: string): void => {
  setCookie(null, SYSTEM_AMPLITUDE_COOKIE_NAME, id, {
    maxAge: SYSTEM_COOKIE_MAX_AGE,
    expires: new Date(Date.now() + SYSTEM_COOKIE_MAX_AGE * 1000),
    secure: process.env.NODE_ENV === 'production',
    path: '/',
    sameSite: 'lax',
    domain: window?.location?.hostname ? `.${window.location.hostname}` : undefined,
  });
};

export default setAmplitudeCookie;
