import { FC, useMemo, ReactNode } from 'react';
import { useRouter } from 'next/router';
import AppSearchAPIConnector from '@elastic/search-ui-app-search-connector';
import { SearchProvider } from '@elastic/react-search-ui';
import { DEFAULT_LOCALE } from '@constants';

type Props = {
  children: ReactNode;
  resultsPerPage?: number;
};

const CreateSearchProvider: FC<Props> = ({ children, resultsPerPage = 15 }) => {
  const { locale = DEFAULT_LOCALE, locales } = useRouter();

  const connectorBaseOptions = {
    searchKey: process.env.NEXT_PUBLIC_ELASTIC_SEARCHKEY,
    endpointBase: process.env.NEXT_PUBLIC_ELASTIC_ENDPOINT,
    cacheResponses: true,
  };

  const connectors = useMemo(() => {
    const connectorObj = {};
    locales.forEach(l => {
      connectorObj[l] = new AppSearchAPIConnector({
        ...connectorBaseOptions,
        engineName: l.toLowerCase(),
      });
    });
    return connectorObj;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locales]);

  const apiConnector = useMemo(() => connectors[locale], [locale, connectors]);

  const config = useMemo(
    () => ({
      apiConnector,
      trackUrlState: false,
      initialState: {
        resultsPerPage,
      },
      alwaysSearchOnInitialLoad: true,
    }),
    [apiConnector, resultsPerPage]
  );

  return (
    <SearchProvider key={locale} config={config}>
      {children}
    </SearchProvider>
  );
};

export default CreateSearchProvider;
