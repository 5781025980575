import { FC, memo } from 'react';
import { useTranslation as t } from '@utils/hooks';
import Button from '@components/Button';
import ButtonLink from '@components/ButtonLink';
import { ROUTE_BUILD } from '@constants';
import * as styles from './styles';

export type Props = {
  region: string;
  handleClose: () => void;
  isSmall?: boolean;
};

const Empty: FC<Props> = ({ handleClose, isSmall = false }) => {
  const LABEL_CREATE_BUILD = t('create_build');
  const LABEL_BROWSING = t('keep_browsing');
  const LABEL_EMPTY_HEADING = t('cart_heading_empty');
  const LABEL_EMPTY_SUBHEADING = t('cart_subheading_empty');
  const top = isSmall ? { top: '85px' } : {};

  return (
    <div className={styles.contentContainer} style={{ ...top }}>
      <p data-test-id="cart-empty-view-heading" className={styles.emptyHeading}>
        {LABEL_EMPTY_HEADING}
      </p>
      <p data-test-id="cart-empty-view-subheading">{LABEL_EMPTY_SUBHEADING}</p>
      <div className={styles.emptyButtonContainer}>
        <Button
          buttonStyle="secondary"
          href="/"
          onClick={handleClose}
          type="button"
          customMargin
          dataTestId="cart-empty-keep-browsing-button"
        >
          {LABEL_BROWSING}
        </Button>
        <ButtonLink
          asButton
          buttonStyle="secondary"
          href={`/${ROUTE_BUILD}`}
          internalLink
          customMargin
          dark
          dataTestId="cart-empty-create-build-button"
        >
          {LABEL_CREATE_BUILD}
        </ButtonLink>
      </div>
    </div>
  );
};

export default memo(Empty);
