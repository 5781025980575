import getGeoipCookie from './get-geoip-cookie';
import setGeoipCookie from './set-geoip-cookie';

export type Geo = {
  countryCode?: string;
  continentCode?: string;
  isInEU?: boolean;
};

export { getGeoipCookie, setGeoipCookie };
