/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-underscore-dangle */
import { FC, memo } from 'react';
import Link from 'next/link';
import getCmsLinkUrl from '@utils/get-cms-link-url';
import slugify from '@utils/slugify';
import type { NavLinkedItem } from '@framework/api/types';
import HeaderLink from '../HeaderLink';

import * as styles from './styles';

type Props = {
  links: NavLinkedItem[];
  linkLength?: number;
  cols: number;
  sectionTitle: string;
  iconComponent: string;
  handleLinkClick: (name: string) => void;
  isMobileNav?: boolean;
  href: string;
  goByThree?: boolean;
  hideOnSmall?: boolean;
};

const HeaderColumnLink: FC<Props> = ({
  links,
  linkLength = 0,
  cols,
  sectionTitle,
  iconComponent,
  handleLinkClick,
  isMobileNav = false,
  hideOnSmall = false,
  href,
  goByThree,
}) => {
  const isValidIcon = iconComponent?.toLowerCase()?.includes('icon');

  return (
    <div>
      <HeaderLink
        title={sectionTitle}
        href={href}
        navIcon={isValidIcon ? iconComponent : null}
        goByThree={goByThree}
        handleLinkClick={handleLinkClick}
        showLink={Boolean(href)}
        hideOnSmall={hideOnSmall}
      />
      <div
        className={
          linkLength > 1 && cols === 1
            ? 'grid gap-3 grid-cols-2 lg:space-y-3'
            : 'lg:space-y-3'
        }
        key={`header-link-wrapper-${slugify(sectionTitle)}`}
      >
        {links.map((l, i) => (
          // eslint-disable-next-line react/no-array-index-key
          <div key={`${l.title}-${i}`}>
            {l._modelApiKey === 'external_link' ? (
              <a
                href={l.url}
                className={styles.linkItem(cols === 1)}
                target="_blank"
                rel="noopener noreferrer"
                id={`header-link-${slugify(l.title)}-${
                  isMobileNav ? 'lg' : 'sm'
                }`}
                data-test-id={`header-link-${slugify(l.title)}`}
                onClick={() => handleLinkClick(l.title)}
                onKeyDown={() => handleLinkClick(l.title)}
                tabIndex={0}
              >
                {l.title}
                <span className="block text-xs lg:text-xxs  leading-snug font-thin lg:w-40 lg:text-nzxt-dark-grey-100 text-nzxt-light-grey-600 lg:mt-0.5 mt-0">
                  {l.shortDescription}
                </span>
              </a>
            ) : (
              <div key={`header-link-${l.slug}`}>
                <Link
                  href={getCmsLinkUrl({
                    contentType: l._modelApiKey,
                    pageType: l.pageType,
                    slug: l.slug,
                    parentSlug: l?.parentCategory?.slug,
                  })}
                  className={styles.linkItem(cols === 1)}
                  id={`header-link-${l.slug}-${isMobileNav ? 'lg' : 'sm'}`}
                  data-test-id={`header-link-${l.slug}`}
                  onClick={() => handleLinkClick(l.title)}
                  onKeyDown={() => handleLinkClick(l.title)}
                  role="link"
                  tabIndex={0}
                >
                  {l.title || l.name}
                  <span className="block text-xs lg:text-xxs leading-snug font-thin lg:w-40 lg:text-nzxt-dark-grey-100 text-nzxt-light-grey-600 lg:mt-0.5 mt-0">
                    {l.shortDescription}
                  </span>
                </Link>
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default memo(HeaderColumnLink);
