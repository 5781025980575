import { parseCookies } from 'nookies';
import { SYSTEM_IMPACT_COOKIE_NAME } from '@constants';

type GetImpactCookie = () => string | null;

const getImpactCookie: GetImpactCookie = () => {
  const cookies = parseCookies();

  return JSON.parse(cookies[SYSTEM_IMPACT_COOKIE_NAME] || null);
};

export default getImpactCookie;
