import { classnames } from '@tailwindcss-classnames';
import { AFFIRM_LARGE_SIZE, AFFIRM_SMALL_SIZE } from '@constants';

const largeText = classnames('text-base');
const smText = classnames('text-xs', 'text-nzxt-light-grey-600');
export const getSize = (size?: string): string =>
  classnames({
    [largeText]: size === AFFIRM_LARGE_SIZE,
    [smText]: size === AFFIRM_SMALL_SIZE || !size,
  });

const defaultLinkColor = classnames('text-blue-600');
const lightLinkColor = classnames('text-white');
export const learnMoreLink = (isWhiteLink: boolean): string =>
  classnames('ml-1', 'hover:underline', 'underline-offset', {
    [lightLinkColor]: isWhiteLink,
    [defaultLinkColor]: !isWhiteLink,
  });
