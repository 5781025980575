import { memo, FC } from 'react';
import { CustomerResponseBody } from '@framework/api/customers';
import stringToHslColor from '@utils/stringUtils/stringToHslColor';
import * as styles from './styles';

type Props = {
  customer: CustomerResponseBody;
};

const IconAvatar: FC<Props> = ({ customer }) => {
  const firstName = customer.FirstName.trim();
  const lastName = customer.LastName.trim();
  const fullName = `${firstName} ${lastName}`;
  const userName = customer.username.trim();
  const name = fullName.trim() || userName;
  const initials =
    `${firstName.toUpperCase().charAt(0)}${lastName.toUpperCase().charAt(0)}` ||
    userName.toUpperCase().charAt(0);
  return (
    <span
      className={styles.circle}
      style={{ backgroundColor: stringToHslColor(name, 30, 80) }}
    >
      <span className={styles.circleText}>{initials}</span>
    </span>
  );
};

export default memo(IconAvatar);
