import { track } from '@amplitude/analytics-browser';
import type { ErrorType, ErrorProperties } from '../types';

type TrackErrorArgs = {
  referrer?: string;
  errorType: ErrorType;
  errorName: string;
  errorCode?: number | string;
};

const trackError = ({
  errorType,
  errorName,
  errorCode,
  referrer,
}: TrackErrorArgs): void => {
  const eventProperties: ErrorProperties = {
    'page url': window.location.href,
    'referrer source': referrer,
    'error type': errorType,
    'error name': errorName,
    'error code': errorCode,
  };

  track('error encountered', eventProperties);
};

export default trackError;
