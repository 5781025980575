import { classnames } from '@tailwindcss-classnames';

export const root = classnames(
  'fixed',
  'block',
  'md:flex',
  'md:items-center',
  'z-20',
  'md:justify-center',
  'inset-0',
  'overflow-y-scroll'
);

export const modal = classnames('bg-white', 'relative', 'h-full', 'md:h-auto');
export const button = classnames(
  'hover:text-gray-500',
  'transition',
  'ease-in-out',
  'duration-150',
  'focus:outline-none',
  'absolute',
  'top-10',
  'right-10'
);

const inverted = classnames('text-white');
export const buttonWrapper = classnames(
  'h-6',
  'flex',
  'items-center',
  'justify-end',
  'w-full'
);

export const iconClose = (isInverted: boolean): string =>
  classnames('h-6', 'w-6', 'stroke-current', {
    [inverted]: isInverted,
  });
export const overlay = classnames(
  'fixed',
  'top-0',
  'bottom-0',
  'left-0',
  'right-0',
  'bg-nzxt-dark-grey-700',
  'opacity-50',
  'h-screen',
  'w-screen',
  'z-10'
);

export const z = classnames('z-50');
