import { classnames } from '@tailwindcss-classnames';

export const containerCol = classnames('flex', 'flex-col', 'flex-wrap');

export const baseContainer = classnames(
  'flex',
  'flex-row',
  'space-x-1.5',
  'lg:space-x-8',
  'basis-full'
);

const isRemoving = classnames('opacity-75', 'pointer-events-none');
const isNotRemoving = classnames('opacity-100', 'pointer-events-auto');
export const getBaseStyle = (removing?: boolean): string =>
  classnames('py-6', {
    [isRemoving]: removing,
    [isNotRemoving]: removing,
  });

export const imageWrapper = classnames(
  'w-14',
  'h-14',
  'relative',
  'overflow-hidden'
);

export const container = classnames('flex-1', 'flex', 'flex-col', 'text-base');

export const productImage = classnames(
  'absolute',
  'w-1',
  'h-1',
  'left-24',
  'top-24'
);

export const itemName = classnames(
  'font-bold',
  'mb-1',
  'text-sm',
  'md:text-base',
  'lg:text-lg',
  'text-nzxt-light-grey-800'
);

const pointer = classnames('cursor-pointer');

export const getItemNameStyle = (isLink?: boolean): string =>
  classnames(itemName, 'text-left', 'flex', 'space-x-2', 'group', {
    [pointer]: isLink,
  });

export const itemQtyControls = classnames(
  'mt-4',
  'flex',
  'items-center',
  'border',
  'rounded-lg',
  'max-w-min',
  'text-nzxt-light-grey-600'
);

export const itemPriceRemove = classnames(
  'flex',
  'flex-col',
  'justify-between',
  'space-y-2',
  'text-base'
);

export const priceContainer = classnames(
  'flex',
  'flex-col',
  'items-end',
  'gap-y-2'
);
export const hidden = classnames('hidden');
const regularPrice = classnames('line-through', 'opacity-50');

export const itemPrice = classnames('text-sm', 'text-nzxt-light-grey-600');
export const getItemPriceStyle = (showDiscountPrice: boolean): string =>
  classnames(itemPrice, {
    [regularPrice]: showDiscountPrice,
  });

export const removeButton = classnames('flex', 'mt-0.5');
export const removeButtonIcon = classnames(
  'h-6',
  'w-6',
  'text-nzxt-light-grey-800'
);

export const padButton = classnames('p-1');

export const counter = classnames('p-2');
export const counterAmount = classnames('w-8', 'relative', 'overflow-hidden');
export const counterInput = classnames('flex', 'h-full', 'relative', 'w-full');

export const partsList = classnames('text-xxs');

export const partsListItem = classnames('mb-1');
export const partsListLabel = classnames(
  'font-bold',
  'text-gray-700',
  'capitalize'
);
export const partsListValue = classnames('font-thin', 'text-gray-900', 'ml-2');

export const stockNoticeWrapper = classnames(
  'flex',
  'justify-center',
  'pb-2',
  'px-2'
);

export const descrptionWrapper = classnames(
  'mt-4',
  'text-xxs',
  'text-nzxt-light-grey-700',
  'leading-snug'
);

export const stockNotice = classnames(
  'font-semibold',
  'text-center',
  'text-xs',
  'py-1',
  'px-2',
  'rounded',
  'inline-block',
  'bg-nzxt-light-grey-600',
  'text-white'
);

// edit button
export const uiButtonsWrapper = classnames(
  'flex',
  'justify-between',
  'items-center'
);

export const styledDivider = classnames('mt-1', 'mx-2', 'font-bold');
export const editLabel = classnames('text-sm', 'mt-1');

// cart hybrid children
export const getBaseStyleHybrid = (removing?: boolean): string =>
  classnames('p-2', 'my-2', 'md:ml-20', 'lg:ml-24', 'bg-nzxt-light-grey-50', {
    [isRemoving]: removing,
    [isNotRemoving]: removing,
  });

export const hybridTitle = classnames(
  'mt-4',
  'md:ml-20',
  'lg:ml-24',
  'font-semibold',
  'text-sm'
);

export const hybridSubtitle = classnames('text-xs', 'font-normal');
export const hybridItemName = classnames(
  'font-bold',
  'mb-1',
  'text-sm',
  'text-nzxt-light-grey-800'
);
export const getHybridItemNameStyle = (isLink?: boolean): string =>
  classnames(hybridItemName, 'text-left', 'flex', 'space-x-2', 'group', {
    [pointer]: isLink,
  });
export const hybridImageWrapper = classnames(
  'w-12',
  'h-12',
  'relative',
  'overflow-hidden'
);

export const esdWrapper = classnames(
  'p-2.5',
  'bg-nzxt-light-grey-50',
  'flex',
  'justify-around',
  'items-center',
  'md:h-8'
);
export const esdQtyWrapper = classnames(
  'flex',
  'items-baseline',
  'justify-items-start',
  'my-4'
);
export const esdIcon = classnames('w-4', 'h-4', 'mr-2');
export const esdText = classnames('text-xs', 'font-semibold');
export const esdDate = classnames('text-nzxt-green-300');
export const esdDot = classnames(
  'rounded-full',
  'bg-nzxt-green-300',
  'w-2',
  'h-2',
  'mr-1'
);
