/* eslint-disable react/no-array-index-key */
/* eslint-disable no-underscore-dangle */
/* eslint-disable jsx-a11y/anchor-is-valid */
import {
  memo,
  FC,
  useState,
  useEffect,
  useMemo,
  Fragment,
  useRef,
  MutableRefObject,
} from 'react';
import { useRouter } from 'next/router';
import { track } from '@amplitude/analytics-browser';
import { Experiment } from '@amplitude/experiment-js-client';
import {
  CONTENT_TYPE_COLLECTION,
  CONTENT_TYPE_CATEGORY,
  CONTENT_TYPE_PRODUCT,
} from '@constants';
import type {
  NavLinkedItem,
  NavColumn,
  NavCta,
  NavLinkGroup,
} from '@framework/api/types';
import GTM from '@utils/gtm';
import { useHistory } from '@utils/history';
import getCmsLinkUrl from '@utils/get-cms-link-url';
import Sidebar from '@components/Sidebar';
import { useTranslation as t } from '@utils/hooks';
import Link from 'next/link';
import { IconNzxtLogo } from '@nzxt/react-icons';
import slugify from '@utils/slugify';

import MobileMenuButton from '@components/Layout/Header/MobileMenuButton';
import MobileHeaderLink from '../MobileHeaderLink';
import HeaderColumnLink from '../HeaderColumnLink';
import HeaderSubmenuCta from '../HeaderSubmenuCta';
import MobileUtilityLinks from '../MobileUtilityLinks';
import HeaderLink from '../HeaderLink';
import * as styles from './styles';

type Props = {
  title: string;
  index: number;
  handleOpen: () => void;
  handleClose: () => void;
  closeNav: () => void;
  focused?: number;
  isOpen?: boolean;
  topLevelLink?: NavLinkedItem;
  exploreLink?: NavLinkedItem;
  userInfo?: { title: string; href: string };
  columns?: NavColumn[];
  cta?: NavCta;
  promotedLinks?: NavLinkGroup[];
};

const amplitudeId = process.env.NEXT_PUBLIC_AMPLITUDE_ID;

const MobileHeaderSubmenu: FC<Props> = ({
  title,
  topLevelLink,
  exploreLink,
  index,
  focused,
  handleClose,
  handleOpen,
  isOpen,
  closeNav,
  columns,
  cta,
  promotedLinks,
}) => {
  const router = useRouter();
  const { asPath } = router;

  const [innerFocus, setinnerFocus] = useState(null);
  const navRefLevelOne = useRef(null) as MutableRefObject<HTMLDivElement>;
  const navRefLevelTwo = useRef(null) as MutableRefObject<HTMLDivElement>;

  // track logo click via GA
  const handleLogoClick = (): void => {
    GTM.dataLayer({
      dataLayer: {
        event: 'headerLogo',
      },
    });
  };

  const logoLabel = t('home');

  const exploreLabel = t('label_explore');

  const handleClick = (name: string): void => {
    setinnerFocus(name);
  };

  const handleBackClick = (): void => {
    setinnerFocus(null);
  };

  useEffect(() => {
    if (!isOpen) {
      handleBackClick();
    }
  }, [isOpen]);

  const link = useMemo(() => {
    if (topLevelLink) {
      return getCmsLinkUrl({
        contentType: topLevelLink._modelApiKey,
        pageType: topLevelLink.pageType,
        slug: topLevelLink.slug,
      });
    }

    return null;
  }, [topLevelLink]);

  const exploreHref = useMemo(() => {
    if (exploreLink) {
      return getCmsLinkUrl({
        contentType: exploreLink._modelApiKey,
        slug: exploreLink.slug,
        parentSlug: exploreLink?.parentCategory?.slug,
      });
    }

    return null;
  }, [exploreLink]);

  const handlePromoLinkClick = (name: string): void => {
    const payload = {
      navherolinkName: name,
      navherolinkRefer: window.location.href,
      mainnavSection: title,
    };

    GTM.dataLayer({
      dataLayer: {
        event: 'navheromenuClickPromo',
        ...payload,
      },
    });
  };

  const { last: lastPage } = useHistory();

  const handleCtaClick = cta
    ? (name: string): void => {
        const eventProperties = {
          'page source url': window.location.href,
          page_path: window.location.pathname,
          'referrer source url': lastPage,
          'nav item selected': name,
          location: 'header',
        };

        track('nav item clicked', eventProperties);
      }
    : null;

  const [experimentVariant, setExperimentVariant] = useState(null);

  useEffect(() => {
    const initExperiment = async (): Promise<void> => {
      const experiment =
        Experiment.initializeWithAmplitudeAnalytics(amplitudeId);

      await experiment.start();

      const variant = experiment.variant('player-pc-links');

      if (variant && variant.value) {
        setExperimentVariant(variant.value);
      }
    };

    if (amplitudeId) {
      initExperiment();
    }
  }, [asPath]);

  const abProdLink = experimentVariant === 'treatment';
  const PROD_LINK_SLUGS = ['player-one', 'player-two', 'player-three'];

  return (
    <li className={styles.flexCol}>
      <MobileHeaderLink href={link} title={title} handleClick={handleOpen} />
      {columns && columns.length > 0 && (
        <Sidebar
          open={focused === index}
          onClose={() => closeNav()}
          isMobileOnly
          isInnerItem
          isScrollable
          customRef={navRefLevelOne}
        >
          <div className={styles.shrink}>
            <MobileMenuButton />
            <Link
              href="/"
              passHref
              onClick={() => handleLogoClick()}
              onKeyDown={() => handleLogoClick()}
              role="link"
              tabIndex={0}
              aria-label={`NZXT ${logoLabel}`}
            >
              <IconNzxtLogo className={styles.mobileLogo} />
            </Link>
          </div>
          <ul className={styles.getOpenClass(focused === index)}>
            <li className={styles.verticalSpacing}>
              <MobileHeaderLink
                title={title}
                handleClick={handleClose}
                isBack
              />
            </li>
            {promotedLinks?.length > 0 && (
              <li>
                {promotedLinks.map(promo => (
                  <HeaderLink
                    title={promo.title}
                    href={getCmsLinkUrl({
                      contentType: promo?.link?._modelApiKey,
                      pageType: promo?.link?.pageType,
                      slug: promo?.link?.slug,
                      parentSlug: promo?.link?.parentCategory?.slug,
                    })}
                    navIcon={promo?.headingIcon}
                    handleLinkClick={handlePromoLinkClick}
                    showLink
                    key={promo.title}
                  />
                ))}
              </li>
            )}
            {columns?.map((column, n) => (
              <Fragment key={`column-${n}`}>
                {column?.categories.map(category => {
                  // track link clicks via GA and amplitude
                  const handleLinkClick = (name: string): void => {
                    const payload = {
                      navherolinkName: name,
                      navherolinkRefer: window.location.href,
                      mainnavSection: category.name,
                    };

                    GTM.dataLayer({
                      dataLayer: {
                        event: 'navheromenuClick',
                        ...payload,
                      },
                    });

                    const eventProperties = {
                      'page source url': window.location.href,
                      page_path: window.location.pathname,
                      'referrer source url': lastPage,
                      'nav item selected': name,
                      location: 'header',
                    };

                    track('nav item clicked', eventProperties);
                  };

                  return (
                    <li className={styles.fullWidth} key={category.slug}>
                      {category.collections &&
                      category.collections.length > 0 ? (
                        <MobileHeaderLink
                          title={category.name}
                          handleClick={() => handleClick(category.name)}
                        />
                      ) : (
                        <MobileHeaderLink
                          title={category.navTitle || category.name}
                          href={getCmsLinkUrl({
                            contentType: category._modelApiKey,
                            slug: category.slug,
                          })}
                        />
                      )}
                      {category.collections &&
                        category.collections.length > 0 && (
                          <Sidebar
                            open={
                              innerFocus === category.name && focused !== null
                            }
                            onClose={() => closeNav()}
                            isMobileOnly
                            isScrollable
                            isInnerItem
                            customRef={navRefLevelTwo}
                          >
                            <div className={styles.shrink}>
                              <MobileMenuButton />
                              <Link
                                href="/"
                                passHref
                                onClick={() => handleLogoClick()}
                                onKeyDown={() => handleLogoClick()}
                                role="link"
                                tabIndex={0}
                                aria-label={`NZXT ${logoLabel}`}
                              >
                                <IconNzxtLogo className={styles.mobileLogo} />
                              </Link>
                            </div>
                            <ul
                              className={styles.getOpenClass(
                                innerFocus === category.name && focused !== null
                              )}
                            >
                              <li className={styles.verticalSpacing}>
                                <MobileHeaderLink
                                  title={category.name}
                                  handleClick={handleBackClick}
                                  isBack
                                  handleLinkClick={handleLinkClick}
                                />
                              </li>
                              {exploreHref && (
                                <li>
                                  <MobileHeaderLink
                                    href={exploreHref}
                                    title="Explore"
                                  />
                                </li>
                              )}

                              {category.collections.map(collection => (
                                <li
                                  className={styles.fullWidth}
                                  key={collection.slug}
                                >
                                  <MobileHeaderLink
                                    title={collection.name}
                                    href={
                                      abProdLink &&
                                      PROD_LINK_SLUGS.includes(collection.slug)
                                        ? getCmsLinkUrl({
                                            contentType: CONTENT_TYPE_PRODUCT,
                                            slug: collection.slug,
                                          })
                                        : getCmsLinkUrl({
                                            contentType:
                                              CONTENT_TYPE_COLLECTION,
                                            slug: collection.slug,
                                          })
                                    }
                                    shortDescription={
                                      collection.shortDescription
                                    }
                                    handleLinkClick={handleLinkClick}
                                  />
                                </li>
                              ))}

                              {category.activeInMainNavigation && (
                                <li className={styles.fullWidth}>
                                  <MobileHeaderLink
                                    title={`${exploreLabel} ${category.name}`}
                                    href={getCmsLinkUrl({
                                      contentType: CONTENT_TYPE_CATEGORY,
                                      slug: category.slug,
                                    })}
                                  />
                                </li>
                              )}

                              <MobileUtilityLinks
                                idLabel={`${category?.name}-${category?.parentCategory?.slug}`}
                              />
                            </ul>
                          </Sidebar>
                        )}
                    </li>
                  );
                })}

                {Array.isArray(column.linkGroup) &&
                  column.linkGroup.map(l => {
                    // track link clicks via GA and amplitude
                    const handleLinkClick = (name: string): void => {
                      const payload = {
                        navherolinkName: name,
                        navherolinkRefer: window.location.href,
                        mainnavSection: l.title,
                      };

                      GTM.dataLayer({
                        dataLayer: {
                          event: 'navheromenuClick',
                          ...payload,
                        },
                      });

                      const eventProperties = {
                        'page source url': window.location.href,
                        page_path: window.location.pathname,
                        'referrer source url': lastPage,
                        'nav item selected': name,
                        location: 'header',
                      };

                      track('nav item clicked', eventProperties);
                    };

                    return (
                      <li className={styles.fullWidth} key={slugify(l.title)}>
                        <HeaderColumnLink
                          key={slugify(l.title)}
                          cols={1}
                          links={l.links}
                          sectionTitle={l.title}
                          iconComponent={l.headingIcon}
                          handleLinkClick={handleLinkClick}
                          isMobileNav
                          hideOnSmall
                          href={
                            l?.link?.slug && l?.link?._modelApiKey
                              ? getCmsLinkUrl({
                                  contentType: l?.link?._modelApiKey,
                                  pageType: l?.link?.pageType,
                                  slug: l?.link?.slug,
                                  parentSlug: l?.link?.parentCategory?.slug,
                                })
                              : null
                          }
                        />
                      </li>
                    );
                  })}
              </Fragment>
            ))}
            <MobileUtilityLinks idLabel={title} />
            {cta && (
              <li className={styles.cta}>
                <div className={styles.ctaWrapper}>
                  <HeaderSubmenuCta {...cta} handleLinkClick={handleCtaClick} />
                </div>
              </li>
            )}
          </ul>
        </Sidebar>
      )}
    </li>
  );
};

export default memo(MobileHeaderSubmenu);
