import { classnames } from '@tailwindcss-classnames';

// CTA Section
export const ctaSectionContainer = classnames(
  'hidden',
  'md:block',
  'space-y-4',
  'py-4'
);
export const ctaWrapper = classnames('space-y-4', 'px-4');
export const sectionHeading = classnames('text-center', 'font-bold', 'text-lg');

// CTA
export const contentContainer = classnames(
  'text-center',
  'space-y-2',
  'p-4',
  'rounded-md'
);
export const heading = classnames('font-bold', 'text-lg');
export const subheading = classnames('text-sm');
export const image = classnames('w-auto', 'mx-auto');
