import { classnames } from '@tailwindcss-classnames';

// list items

export const description = classnames(
  'block',
  'lg:text-xxs',
  'text-xs',
  'leading-snug',
  'font-thin',
  'lg:w-40',
  'lg:text-nzxt-dark-grey-100',
  'mt-0.5'
);

const baseLinkClass = classnames(
  // 'py-2',
  'hover:text-nzxt-volt-200',
  'block',
  'rounded',
  'w-full'
);

export const collectionImage = classnames(
  'mr-2',
  'relative',
  'max-w-[53px]',
  'max-h-[53px]'
);

export const copyContainer = classnames('flex', 'flex-col');

const isThree = classnames('col-span-1');
export const linkItem = (isByThree: boolean): string =>
  classnames(
    baseLinkClass,
    { [isThree]: isByThree },
    'text-sm',
    'font-semibold',
    'text-white',
    'group',
    'flex'
  );
