import { classnames } from '@tailwindcss-classnames';

export const container = classnames(
  'print:relative',
  'z-40',
  'transform',
  'duration-150',
  'w-full'
);

export const wrapper = classnames('h-full', 'w-full', 'relative');

export const overlay = classnames(
  'fixed',
  'top-0',
  'bottom-0',
  'left-0',
  'right-0',
  'bg-nzxt-dark-grey-700',
  'opacity-25',
  'h-screen',
  'w-full',
  'z-10'
);
