/* eslint-disable max-len */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, memo } from 'react';
import { useMedia } from '@utils/hooks';

import useToastStore, { getDisplayToast } from '@stores/use-toast-store';
import NotificationBanner from '@components/Layout/NotificationBanner';
import PreviewAlert from '../PreviewAlert';

type Props = {
  preview?: boolean;
};

const BannerContainer: FC<Props> = ({ preview }) => {
  const displayToast = useToastStore(getDisplayToast);

  const isMd = useMedia('md');
  const isSm = useMedia('sm');
  const isLg = !isMd && !isSm;
  const displayNotification = displayToast && !isLg;

  return displayNotification || preview ? (
    <div id="geo-banner-preview-banner">
      <PreviewAlert preview={preview} />
      {displayNotification && <NotificationBanner />}
    </div>
  ) : null;
};

export default memo(BannerContainer);
