import { classnames } from '@tailwindcss-classnames';

export const container = classnames('relative');

export const button = classnames(
  'flex',
  'items-center',
  'focus:outline-white',
  'relative',
  'p-1'
);

export const searchClass = classnames(
  'h-5',
  'md:h-6',
  'w-5',
  'md:w-6',
  'text-nzxt-volt-400',
  'lg:text-white',
  'hover:text-nzxt-dark-grey-100'
);
