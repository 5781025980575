import { classnames } from '@tailwindcss-classnames';

const defaultMargin = classnames('mr-2');
export const button = (customMargin: boolean): string =>
  classnames(
    'whitespace-nowrap',
    'flex',
    'items-center',
    'font-medium',
    'transition',
    'leading-tight',
    'disabled:cursor-not-allowed',
    'h-11',
    { [defaultMargin]: !customMargin }
  );

export const buttonModeLight = {
  primary: classnames(
    'bg-nzxt-volt-400',
    'hover:bg-nzxt-volt-500',
    'disabled:text-nzxt-light-grey-500',
    'disabled:bg-nzxt-light-grey-300',
    'px-4',
    'text-base',
    'rounded-md',
    'justify-center',
    'min-w-120',
    'md:min-w-140',
    'text-white'
  ),
  secondary: classnames(
    'bg-nzxt-dark-grey-400',
    'hover:bg-nzxt-dark-grey-500',
    'active:bg-nzxt-dark-grey-700',
    'disabled:text-nzxt-light-grey-500',
    'disabled:bg-nzxt-light-grey-300',
    'px-4',
    'text-base',
    'text-white',
    'rounded-md',
    'justify-center',
    'min-w-120',
    'md:min-w-140'
  ),
  danger: classnames(
    'bg-nzxt-red-200',
    'text-white',
    'hover:bg-nzxt-red-100',
    'disabled:text-nzxt-light-grey-500',
    'disabled:bg-nzxt-light-grey-300',
    'px-4',
    'text-base',
    'rounded-md',
    'justify-center',
    'min-w-120',
    'md:min-w-140'
  ),
  textButton: classnames(
    'text-blue-600',
    'hover:underline',
    'disabled:text-nzxt-light-grey-500',
    'underline-offset'
  ),
};

export const buttonModeDark = {
  primary: classnames(
    'border-white',
    'border-2',
    'text-white',
    'disabled:border-transparent',
    'disabled:text-nzxt-light-grey-500',
    'disabled:bg-nzxt-light-grey-300',
    'px-4',
    'rounded-md',
    'justify-center',
    'min-w-120',
    'md:min-w-140'
  ),
  secondary: classnames(
    'bg-nzxt-dark-grey-500',
    'hover:bg-nzxt-dark-grey-600',
    'disabled:text-nzxt-light-grey-500',
    'disabled:bg-nzxt-light-grey-300',
    'px-4',
    'text-base',
    'rounded-md',
    'justify-center',
    'min-w-120',
    'md:min-w-140'
  ),
  danger: classnames(
    'bg-nzxt-red-100',
    'text-white',
    'hover:bg-nzxt-red-200',
    'disabled:text-nzxt-light-grey-500',
    'disabled:bg-nzxt-light-grey-300',
    'px-4',
    'text-base',
    'rounded-md',
    'justify-center',
    'min-w-120',
    'md:min-w-140'
  ),
  textButton: classnames(
    'text-blue-600',
    'hover:underline',
    'disabled:text-nzxt-light-grey-500',
    'underline-offset'
  ),
};
