/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable camelcase */
import { FC, memo, useMemo, useEffect } from 'react';
import { useRouter } from 'next/router';
import Link from 'next/link';
import { getRegion } from '@framework/api/utils/maxify';
import { useTranslation as t } from '@utils/hooks';
import GTM from '@utils/gtm';
import { MAXIFY_CURRENCY_MAP_PER_REGION } from '@constants';
import Image from '@components/Image';
import slugify from '@utils/slugify';
import * as styles from './styles';

type AutocompletedResult = {
  id: {
    raw: string;
  };
  product_url?: {
    raw: string;
  };
  url?: {
    raw: string;
  };
  thumbnail: {
    raw: string;
  };
  product_name?: {
    raw: string;
  };
  title?: {
    raw: string;
  };
  product_short_description?: {
    raw: string;
  };
  content?: {
    raw: string;
  };
  type: {
    raw: string;
  };
  variants?: {
    raw: string[];
  };
};

type Props = {
  autocompletedSuggestions?: {
    documents: {
      suggestion: string;
    }[];
  };
  autocompletedResults?: AutocompletedResult[];
  getItemProps: (any) => object;
  closeMenu: () => void;
};

const Autocomplete: FC<Props> = ({
  autocompletedResults,
  getItemProps,
  closeMenu,
}) => {
  const { locale } = useRouter();
  const region = getRegion(locale);
  const currencyCode = useMemo(
    () => MAXIFY_CURRENCY_MAP_PER_REGION[region],
    [region]
  );

  const results = useMemo(
    () =>
      Array.isArray(autocompletedResults)
        ? autocompletedResults.slice(0, 6)
        : [],
    [autocompletedResults]
  );

  const productResults = useMemo(
    () =>
      Array.isArray(results)
        ? results.filter(r => r.type.raw === 'product')
        : [],
    [results]
  );

  // track product impressions for GA enhanced ecommerce
  useEffect(() => {
    if (Array.isArray(productResults) && productResults.length > 0) {
      const impressions = [];

      productResults.forEach((product, i) => {
        if (Array.isArray(product.variants.raw)) {
          const variants = product.variants.raw.map(v => JSON.parse(v));

          impressions.push({
            name: product.product_name?.raw,
            id: variants[0]?.id ?? product.id?.raw,
            price: variants[0]?.price?.toString() ?? null,
            brand: 'NZXT',
            variant:
              variants[0]?.color?.map(color => color.name).join('/') ?? null,
            list: 'Search Autocomplete',
            position: i,
          });
        }
      });

      GTM.dataLayer({
        dataLayer: {
          ecommerce: {
            currencyCode,
            impressions,
          },
        },
      });
    }
  }, [productResults, currencyCode]);

  // track product click for GA enhanced ecommerce
  const handleLinkClick = (result: AutocompletedResult): void => {
    if (Array.isArray(result.variants.raw)) {
      const variants = result.variants.raw.map(v => JSON.parse(v));

      GTM.dataLayer({
        dataLayer: {
          event: 'productClick',
          ecommerce: {
            click: {
              products: [
                {
                  name: result.product_name?.raw,
                  id: variants[0]?.id ?? result.id?.raw,
                  price: variants[0]?.price?.toString() ?? null,
                  brand: 'NZXT',
                  variant:
                    variants[0]?.color?.map(color => color.name).join('/') ??
                    null,
                },
              ],
            },
          },
        },
      });
    }

    closeMenu();
  };

  const LABEL_SUGGESTED_RESULTS = t('label_suggested_results');

  return (
    <div
      className={styles.autocompleteDropdown}
      role="menu"
      aria-orientation="vertical"
      aria-labelledby="options-menu"
    >
      {results && results.length > 0 && (
        <div id="suggested-results">
          <div className={styles.sectionHeadingContainer}>
            <span className={styles.sectionHeading}>
              {LABEL_SUGGESTED_RESULTS}
            </span>
          </div>
          <ul className={styles.resultList}>
            {results.map(result => {
              const title =
                result.type.raw === 'product'
                  ? result.product_name.raw
                  : result.title.raw;

              return title ? (
                <li
                  {...getItemProps({
                    key: result.id.raw,
                    item: result,
                  })}
                  className={styles.resultListItem}
                >
                  <Link
                    href={
                      result.type.raw === 'product'
                        ? `/${result.product_url.raw}`
                        : `/${result.url.raw.replace(/^\/+/g, '')}`
                    }
                    className={styles.flex}
                    onClick={
                      result.product_url ? () => handleLinkClick(result) : null
                    }
                    onKeyDown={
                      result.product_url ? () => handleLinkClick(result) : null
                    }
                    role="link"
                    tabIndex={0}
                  >
                    {result.thumbnail?.raw && (
                      <Image
                        className={styles.image}
                        data={JSON.parse(result.thumbnail.raw).responsiveImage}
                        width={40}
                        height={40}
                        loading="eager"
                      />
                    )}
                    <div
                      id={`${slugify(
                        result.title?.raw ||
                          result.product_name?.raw ||
                          'empty-result'
                      )}-result`}
                      className={styles.ml}
                    >
                      <p className={styles.resultHeading}>{title}</p>
                      <p className={styles.resultSubheading}>
                        {result.type.raw === 'product' &&
                        result.product_short_description?.raw.length > 22
                          ? `${result.product_short_description.raw.substring(
                              0,
                              22
                            )}...`
                          : result.product_short_description?.raw}
                      </p>
                    </div>
                  </Link>
                </li>
              ) : null;
            })}
          </ul>
        </div>
      )}
    </div>
  );
};

export default memo(Autocomplete);
