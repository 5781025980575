/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { getSession } from 'next-auth/react';
import type { HookFetcher } from '../utils/types';
import type { SwrOptions } from '../utils/use-data';
import useCommerceCustomer from '../commerce/use-customer';
import type { CustomerBody, CustomerResponseBody } from '../api/customers';

const defaultOpts = {
  url: '/api/maxify/customers',
  method: 'GET',
};

export const fetcher: HookFetcher<CustomerResponseBody, CustomerBody> = async (
  options,
  _,
  fetch
) => {
  const session = await getSession();
  const token = session?.accessToken;

  return token ? fetch({ ...defaultOpts, ...options }) : null;
};

export function extendHook(
  customFetcher: typeof fetcher,
  swrOptions?: SwrOptions<CustomerResponseBody | null>
) {
  const useCustomer = () =>
    useCommerceCustomer(defaultOpts, [], customFetcher, {
      revalidateOnFocus: false,
      ...swrOptions,
    });

  useCustomer.extend = extendHook;

  return useCustomer;
}

export default extendHook(fetcher);
