import { FC, memo } from 'react';
import { TArg } from '@tailwindcss-classnames';
import { useTranslation as t } from '@utils/hooks';
import * as styles from './styles';

type Props = {
  stock: number;
  className?: TArg[];
};

const StockMessage: FC<Props> = ({ stock, className = [] }) => {
  const STOCK_MSG = t('message_stock_amount', { stock });

  return stock > 0 ? (
    <span className={styles.wrapper(className)}>{STOCK_MSG}</span>
  ) : null;
};

export default memo(StockMessage);
