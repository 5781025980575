import { FC, memo, useEffect, useState } from 'react';
import { useTranslation as t } from '@utils/hooks';
import trackError from '@utils/amplitude/track/error';
import { useHistory } from '@utils/history';
import type { ErrorType } from '@utils/amplitude/types';

type Props = {
  errorMessage?: string;
  supportingMessage?: string;
  errorType?: ErrorType;
};

const ToastMessage: FC<Props> = ({
  errorMessage,
  supportingMessage,
  errorType,
}) => {
  const { last: lastPage } = useHistory();
  const [hasBeenTrackedInAmplitude, setHasBeenTrackedInAmplitude] =
    useState(false);

  useEffect(() => {
    let timeout = null;

    if (!hasBeenTrackedInAmplitude) {
      timeout = setTimeout(() => {
        trackError({
          errorType,
          errorName: `${errorMessage}${
            supportingMessage ? ` - ${supportingMessage}` : ''
          }`,
          referrer: lastPage,
        });

        setHasBeenTrackedInAmplitude(true);
      }, 1);
    }

    return () => {
      clearTimeout(timeout);
    };
  }, [
    errorMessage,
    errorType,
    hasBeenTrackedInAmplitude,
    lastPage,
    supportingMessage,
  ]);

  const errorMess = errorMessage?.toLowerCase().includes('maxify')
    ? null
    : errorMessage;

  return (
    <>
      {errorMess && <p className="font-bold">{errorMess}</p>}
      <p data-test-id="toast-message">
        {supportingMessage || t('message_error')}
      </p>
    </>
  );
};

export default memo(ToastMessage);
