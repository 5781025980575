import { classnames } from 'tailwindcss-classnames';

// link container
const baseLinkClass = classnames(
  // 'py-2',
  'hover:text-nzxt-volt-200',
  'block',
  'rounded',
  'w-full'
);

const isThree = classnames('col-span-1');
export const linkItem = (isByThree: boolean): string =>
  classnames(
    baseLinkClass,
    { [isThree]: isByThree },
    'lg:text-sm',
    'font-semibold',
    'lg:text-white',
    'px-2',
    'group'
  );

// list item headers
const isThreeHeader = classnames('col-span-2');

export const submenuListHeader = (isByThree: boolean): string =>
  classnames(
    { [isThreeHeader]: isByThree },
    'font-semibold',
    'lg:font-bold',
    'lg:uppercase',
    'flex',
    'group-nested-link',
    'lg:text-nzxt-light-grey-300',
    'lg:opacity-80',
    'justify-between',
    'px-1',
    // 'py-2',
    'items-center',
    'lg:text-xs',
    'pl-4',
    'lg:pl-0',
    'mb-2'
  );

export const submenuListHeaderLink = (isByThree: boolean): string =>
  classnames(
    baseLinkClass,
    { [isThreeHeader]: isByThree },
    'font-semibold',
    'lg:font-bold',
    'lg:uppercase',
    'flex',
    'cursor-pointer',
    'group',
    // 'group-nested-link',
    'lg:text-white',
    'justify-between',
    'px-1',
    // 'py-2',
    'items-center',
    'lg:text-sm',
    'pl-4',
    'lg:pl-0'
  );

export const chevron = classnames(
  'ml-2',
  'h-5',
  'w-5',
  'flex-shrink-0',
  'group-hover:animate-bounceRight',
  'hidden',
  'lg:block'
);

export const flexEnd = classnames('flex', 'items-center');

export const icon = classnames(
  'h-[1em]',
  'w-[1em]',
  'mr-1.5',
  'hidden',
  'lg:inline-block'
);
