/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { useCallback } from 'react';
import debounce from 'lodash.debounce';
import type { HookFetcher } from '../utils/types';
import { CommerceError } from '../utils/errors';
import useCartUpdateItem from '../commerce/cart/use-update-item';
import type { ItemBody, UpdateItemBody } from '../api/cart';
import { fetcher as removeFetcher } from './use-remove-item';
import useCart, { ShopifyCart } from './use-cart';

const defaultOpts = {
  url: '/api/maxify/cart',
  method: 'PUT',
};

export type UpdateItemInput = Partial<{ id: string | string[] } & ItemBody>;

export const fetcher: HookFetcher<ShopifyCart | null, UpdateItemBody> = (
  options,
  { itemId, item },
  fetch
) => {
  if (Number.isInteger(item.quantity)) {
    // allow the update hook to remove an item if the qty is lower than 1
    if (item.quantity! < 1) {
      return removeFetcher(null, { itemId }, fetch);
    }
  } else if (item.quantity) {
    throw new CommerceError({
      message: 'The item quantity has to be a valid integer',
    });
  }

  return fetch({
    ...defaultOpts,
    ...options,
    body: { itemId, item },
  });
};

function extendHook(customFetcher: typeof fetcher, cfg?: { wait?: number }) {
  const useUpdateItem = () => {
    const { mutate } = useCart();
    const fn = useCartUpdateItem<ShopifyCart | null, UpdateItemBody>(
      defaultOpts,
      customFetcher
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
    return useCallback(
      debounce(async (input: UpdateItemInput) => {
        const data = await fn({
          itemId: input.id,
          item: {
            quantity: input.quantity,
            attributes: input.attributes,
          },
        });
        await mutate(data, false);
        return data;
      }, cfg?.wait ?? 250),
      [fn, mutate]
    );
  };

  useUpdateItem.extend = extendHook;

  return useUpdateItem;
}

export default extendHook(fetcher);
