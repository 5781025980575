import { FC, memo } from 'react';
import buildImgixUrl from '@utils/imgix/build-imgix-url';
import type {
  ImgixParams,
  ImgixSrcSetOptions,
  UrlType,
} from '@utils/imgix/types';

type MediaQueryType = 'min' | 'max';

interface ResponsiveSourceProps {
  imageUrl: string;
  imgixParams: ImgixParams;
  screen: string;
  urlType?: UrlType;
  options?: {
    imgixDomain?: string;
    srcSetOptions?: ImgixSrcSetOptions;
  };
  mediaQueryType?: MediaQueryType;
}

const ResponsiveSource: FC<ResponsiveSourceProps> = ({
  imageUrl,
  imgixParams,
  screen,
  urlType = 'srcset',
  options,
  mediaQueryType = 'min',
}) => (
  <source
    media={`(${mediaQueryType}-width: ${screen})`}
    srcSet={buildImgixUrl(imageUrl, imgixParams, urlType, options)}
  />
);

export default memo(ResponsiveSource);
