/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, memo } from 'react';
import SupportButton from '@components/Layout/Header/SupportButton';
import { useRegionFeatures } from '@utils/hooks';
import UserSection from '@components/Layout/Header/MobileMenu/UserSection';
import { useRouter } from 'next/router';
import { getRegion } from '@framework/api/utils/maxify';
import * as styles from './styles';

type Props = {
  idLabel?: string;
};

const MobileUtilityLinks: FC<Props> = ({ idLabel }) => {
  const { locale } = useRouter();
  const region = getRegion(locale);
  const { signIn: showSignIn, support: showSupport } =
    useRegionFeatures(region);

  const utilityLinks = [
    showSupport && <SupportButton iconStyles="text-nzxt-volt-400" />,
    showSignIn && <UserSection idLabel={idLabel} />,
  ].filter(Boolean);

  return (
    <>
      {(showSupport || showSignIn) &&
        utilityLinks.map((link, i) => (
          // eslint-disable-next-line react/no-array-index-key
          <li key={`utilityLink-${i}`} className={styles.utilityLink}>
            {link}
          </li>
        ))}
    </>
  );
};

export default memo(MobileUtilityLinks);
