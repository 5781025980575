type Price = string | number;

const hasDiscountPrice = (
  regPrice: Price,
  discPrice?: Price,
  shopifyDiscPrice?: Price
): boolean => {
  const realDisc = shopifyDiscPrice || discPrice;

  if (
    parseFloat(`${discPrice}`) - parseFloat(`${shopifyDiscPrice}`) <
    parseFloat(`${regPrice}`)
  ) {
    return true;
  }

  if (!realDisc) {
    return false;
  }

  if (parseFloat(`${realDisc}`) < parseFloat(`${regPrice}`)) {
    return true;
  }

  return false;
};

export default hasDiscountPrice;
