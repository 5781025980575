/* eslint-disable max-len */
/* eslint-disable jsx-a11y/anchor-is-valid */
import {
  useState,
  useRef,
  FC,
  useEffect,
  MutableRefObject,
  useMemo,
} from 'react';
import { getRegion } from '@framework/api/utils/maxify';
import {
  disableBodyScroll,
  enableBodyScroll,
  clearAllBodyScrollLocks,
} from '@utils/scroll-lock/bodyScrollLock';
import { useMedia, useRegionFeatures, useTranslation as t } from '@utils/hooks';
import Link from 'next/link';
import { IconNzxtLogo } from '@nzxt/react-icons';
import Router, { useRouter } from 'next/router';
import debounce from 'lodash.debounce';
import GTM from '@utils/gtm';
import ClickOutside from '@utils/click-outside';
import type { DatoNav, NavPane } from '@framework/api/types';
import useUiStore, {
  getDisplayNav,
  getCloseNav,
  getDisplayDesktopNav,
  getCloseDesktopNav,
  getOpenDesktopNav,
} from '@stores/use-ui-store';
import BannerContainer from '@components/Layout/BannerContainer';
import MarketingBanner from '@components/Layout/MarketingBanner';
import HeaderMenu from './HeaderMenu';
import ProfileDropdown from './ProfileDropdown';
import SupportButton from './SupportButton';
import MobileMenuButton from './MobileMenuButton';
import HeaderWrapper from './HeaderWrapper';
import CartButton from './CartButton';
import SearchButton from './SearchButton';
import SearchBar from './SearchBar';
import * as styles from './styles';

type Props = {
  data?: DatoNav;
  regionalPanes?: NavPane[];
  displayPreviewBanner?: boolean;
  hideMarketingBanner?: boolean;
};

const Header: FC<Props> = ({
  data,
  regionalPanes,
  displayPreviewBanner,
  hideMarketingBanner,
}) => {
  const ref = useRef() as MutableRefObject<HTMLDivElement>;
  const skipRef = useRef() as MutableRefObject<HTMLDivElement>;
  const marketingBanner = data?.marketingBanner ?? null;
  const { locale } = useRouter();

  const region = getRegion(locale);
  const regionalMarketingBanner = useMemo(
    () =>
      Array.isArray(marketingBanner)
        ? marketingBanner.filter(c =>
            c.bannerRegion?.some(r => r.region === region)
          )
        : null,
    [marketingBanner, region]
  );

  const hasMarketingBanner =
    Array.isArray(regionalMarketingBanner) &&
    regionalMarketingBanner?.length > 0;

  let banners = null;

  if (hasMarketingBanner) {
    banners = regionalMarketingBanner
      .filter(b => {
        if (b.countdownEnd) {
          if (new Date(b.countdownEnd) < new Date()) {
            return null;
          }
        }
        return b;
      })
      .filter(Boolean);
  }

  // const displayToast = useToastStore(getDisplayToast);

  const displayNav = useUiStore(getDisplayNav);
  const closeNav = useUiStore(getCloseNav);
  const displayDesktopNav = useUiStore(getDisplayDesktopNav);
  const openDesktopNav = useUiStore(getOpenDesktopNav);
  const closeDesktopNav = useUiStore(getCloseDesktopNav);

  const [focused, setFocused] = useState(null);

  const handleUnfocused = (): void => {
    skipRef?.current?.focus();
    setFocused(null);
    closeNav();
    closeDesktopNav();
  };

  const handleFocused = (index: number): void => {
    setFocused(index);
    openDesktopNav();

    if (focused === index) {
      handleUnfocused();
    }
  };

  const debouncedUnfocus = debounce(handleUnfocused, 250);

  useEffect(() => {
    if (ref.current) {
      const shouldReserveScrollBarGap = displayDesktopNav && !displayNav;
      if (displayNav || focused !== null) {
        disableBodyScroll(ref.current, {
          reserveScrollBarGap: shouldReserveScrollBarGap,
        });
      } else {
        enableBodyScroll(ref.current);
      }
    }
    return () => {
      clearAllBodyScrollLocks();
    };
  }, [displayNav, focused, ref, displayDesktopNav]);

  useEffect(() => {
    const handleKeydown = (e: KeyboardEvent): void => {
      if (e.key === 'Escape') {
        handleUnfocused();
      }
    };

    Router.events.on('routeChangeComplete', debouncedUnfocus);
    window.addEventListener('keydown', handleKeydown);

    return () => {
      Router.events.off('routeChangeComplete', debouncedUnfocus);
      window.removeEventListener('keydown', handleKeydown);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedUnfocus]);

  // track logo click via GA
  const handleLogoClick = (): void => {
    GTM.dataLayer({
      dataLayer: {
        event: 'headerLogo',
      },
    });
  };

  const logoLabel = t('home');

  const isMd = useMedia('md');
  const isSm = useMedia('sm');
  const isLg = !isMd && !isSm;

  const hasData = Array.isArray(data?.panes) && data?.panes?.length > 0;
  const { cartButton: showCartButton, support: showSupportIcon } =
    useRegionFeatures(region);

  return hasData ? (
    <HeaderWrapper>
      <div>
        <span tabIndex={-1} ref={skipRef}>
          <span className={styles.srOnly}>
            Skip to <a href="#main">content</a> or <a href="#footer">footer</a>
          </span>
        </span>
      </div>

      <BannerContainer preview={displayPreviewBanner} />
      {hasMarketingBanner &&
      !hideMarketingBanner &&
      Array.isArray(banners) &&
      banners.length > 0 ? (
        <div className={styles.marketingBannerWrapper}>
          <MarketingBanner marketingBannerData={banners} />
        </div>
      ) : null}

      <ClickOutside
        active={(isLg && displayNav) || focused !== null}
        onClick={() => handleUnfocused()}
      >
        <div className={styles.topBarContainer}>
          <div className={styles.topInnerContainer} ref={ref}>
            <div className={styles.shrink}>
              <MobileMenuButton />
              <Link
                href="/"
                passHref
                onClick={() => handleLogoClick()}
                onKeyDown={() => handleLogoClick()}
                role="link"
                tabIndex={0}
                aria-label={`NZXT ${logoLabel}`}
              >
                <IconNzxtLogo className={styles.mobileLogo} />
              </Link>
            </div>

            <div className={styles.bottomWrapper}>
              <div className={styles.bottomBarContainer}>
                <Link
                  href="/"
                  passHref
                  onClick={() => handleLogoClick()}
                  onKeyDown={() => handleLogoClick()}
                  role="link"
                  tabIndex={0}
                  aria-label={`NZXT ${logoLabel}`}
                  data-test-id="home-button"
                >
                  <IconNzxtLogo className={styles.logo} />
                </Link>
                <ul className={styles.linkContainer} role="menu">
                  {data &&
                    regionalPanes &&
                    Array.isArray(regionalPanes) &&
                    regionalPanes.length > 0 &&
                    regionalPanes.map((pane, i) => (
                      <HeaderMenu
                        key={pane.title}
                        {...pane}
                        index={i}
                        handleOpen={() => handleFocused(i)}
                        focused={focused}
                      />
                    ))}
                </ul>
              </div>
            </div>
            <div className={styles.dropdownContainerBase}>
              <span>
                <SearchButton />
              </span>
              {showSupportIcon && (
                <span
                  className={styles.hiddenSmall}
                  data-test-id="support-button"
                >
                  <SupportButton iconStyles={styles.supportButton} />
                </span>
              )}
              <span className={styles.hiddenSmall}>
                <ProfileDropdown
                  marketingBannerData={regionalMarketingBanner}
                />
              </span>
              {showCartButton && (
                <span>
                  <CartButton key={`cart-button-${region}`} />
                </span>
              )}
            </div>
          </div>
          <SearchBar />
        </div>
      </ClickOutside>
    </HeaderWrapper>
  ) : null;
};

export default Header;
