import { classnames } from '@tailwindcss-classnames';

// Cart FPC Extend Offer Custom Styles

export const wrapper = classnames('md:mt-5');
export const innerWrapper = classnames('md:ml-24', 'md:mr-12', 'md:mt-5');
export const border = classnames('md:border-t', 'md:my-5', 'md:ml-24');
export const heading = classnames('pb-4', 'font-bold', 'text-lg');
export const warrantySubheading = classnames(
  'text-nzxt-light-grey-700',
  'mt-2',
  'text-sm'
);
export const learnMoreButton = classnames(
  '!mt-1.5',
  'ml-2',
  'underline-offset',
  '!h-auto',
  'text-sm',
  'text-nzxt-blue-400',
  'underline'
);

export const addButtonContainer = classnames('ml-auto');
export const addButton = classnames(
  'min-w-max',
  'flex',
  'items-center',
  'font-medium',
  'transition',
  'h-8',
  'bg-nzxt-volt-400',
  'hover:bg-nzxt-volt-500',
  'disabled:text-nzxt-light-grey-500',
  'disabled:bg-nzxt-light-grey-300',
  'disabled:cursor-not-allowed',
  'px-4',
  'text-base',
  'rounded-md',
  'justify-center',
  'min-w-120',
  'md:min-w-[90px]',
  'text-white'
);
