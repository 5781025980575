import type { State } from './types';

export const getDisplayToast = (state: State): boolean => state.displayToast;

export const getDismissedToast = (state: State): boolean =>
  state.dismissedToast;

export const getToastSmallText = (state: State): string => state.toastSmallText;

export const getToastCountryText = (state: State): string =>
  state.toastCountryText;

export const getToastText = (state: State): string => state.toastText;

export const getToastShop = (state: State): string => state.toastShop;

export const getToastCountryLink = (state: State): string =>
  state.toastCountryLink;

export const getToastButtonText = (state: State): string =>
  state.toastButtonText;

export const getToastAction = (state: State): (() => void) => state.toastAction;

export const getOpenToast = (state: State): (() => void) => state.openToast;

export const getCloseToast = (state: State): (() => void) => state.closeToast;

export const getSetToastAction = (state: State): ((fn: () => void) => void) =>
  state.setToastAction;

export const getSetToastText = (state: State): ((text: string) => void) =>
  state.setToastText;

export const getSetToastShop = (state: State): ((text: string) => void) =>
  state.setToastShop;

export const getSetToastCountryLink = (
  state: State
): ((text: string) => void) => state.setToastCountryLink;

export const getSetToastSmallText = (state: State): ((text: string) => void) =>
  state.setToastSmallText;

export const getSetToastCountryText = (
  state: State
): ((text: string) => void) => state.setToastCountryText;

export const getSetToastButtonText = (state: State): ((text: string) => void) =>
  state.setToastButtonText;

export const getDismissToast = (state: State): (() => void) =>
  state.dismissToast;

export const getUndismissToast = (state: State): (() => void) =>
  state.undismissToast;
