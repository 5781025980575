/* eslint-disable security/detect-non-literal-regexp */
/* eslint-disable no-restricted-syntax */
import type { ResponsiveImageType } from '@components/Image/Image';

const regex = new RegExp(`${process.env.NEXT_PUBLIC_CMS_ASSETS_URL}/`, 'g');

const proxyImage = (image: ResponsiveImageType): ResponsiveImageType => {
  const decoratedImage = image;

  for (const [key, value] of Object.entries(image)) {
    if (typeof value === 'string') {
      decoratedImage[key] = value.replace(regex, '/assets/cms/');
    }
  }

  return { ...image, ...decoratedImage };
};

export default proxyImage;
