/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC } from 'react';
import { IconShoppingCart } from '@nzxt/react-icons';
import Link from 'next/link';
import GTM from '@utils/gtm';
import useCart from '@framework/cart/use-cart';
import { ROUTE_CART } from '@constants';
import * as styles from './styles';

type Props = {
  darkModeMobile?: boolean;
  isMobile?: boolean;
};

const CartButton: FC<Props> = ({ darkModeMobile, isMobile }) => {
  const { count } = useCart();

  const handleClick = (): void => {
    // track cart button click via GA
    GTM.dataLayer({
      dataLayer: {
        event: 'cartClick',
      },
    });
  };

  return (
    <div className={styles.container}>
      <Link
        href={`/${ROUTE_CART}`}
        passHref
        className={styles.button}
        id={`cart-button${isMobile ? '-mobile' : ''}`}
        aria-label="Go to Cart"
        onClick={() => handleClick()}
        onKeyDown={() => handleClick()}
        role="link"
        tabIndex={0}
      >
        <IconShoppingCart className={styles.cartClass(darkModeMobile)} />
        {count && count > 0 ? (
          <span className={styles.cartCount}>{count}</span>
        ) : null}
      </Link>
    </div>
  );
};

export default CartButton;
