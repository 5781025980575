import { FC, memo } from 'react';
import buildImgixUrl from '@utils/imgix/build-imgix-url';
import isSvgImageUrl from '@utils/image/isSvgImageUrl';
import type {
  ImgixParams,
  ImgixSrcSetOptions,
  UrlType,
} from '@utils/imgix/types';

interface ResponsiveImageProps {
  imageUrl: string;
  imgixParams: ImgixParams;
  urlType?: UrlType;
  options?: {
    imgixDomain?: string;
    srcSetOptions?: ImgixSrcSetOptions;
  };
  srcSet?: string;
  height?: number;
  width?: number;
  alt?: string;
  className?: string;
}

const ResponsiveImage: FC<ResponsiveImageProps> = ({
  imageUrl,
  imgixParams,
  urlType = 'url',
  options,
  srcSet,
  height,
  width,
  alt = '',
  className,
}) => {
  const imgSrc = buildImgixUrl(imageUrl, imgixParams, urlType, options);
  const imgSrcSet = isSvgImageUrl(imageUrl)
    ? undefined
    : srcSet ||
      (urlType === 'srcset'
        ? buildImgixUrl(imageUrl, imgixParams, 'srcset', options)
        : undefined);

  return (
    <img
      height={height}
      width={width}
      alt={alt}
      src={imgSrc}
      srcSet={imgSrcSet}
      className={className}
    />
  );
};

export default memo(ResponsiveImage);
