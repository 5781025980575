import { classnames } from '@tailwindcss-classnames';

export const container = classnames('relative');
export const baseText = classnames('text-white', 'text-sm');

export const button = classnames(
  'flex',
  'items-center',
  'text-white',
  'focus:outline-white',
  'p-1'
);

export const profileSvg = classnames(
  'h-5',
  'md:h-6',
  'w-5',
  'md:w-6',
  'text-white',
  'hover:text-nzxt-dark-grey-100'
);

export const srOnly = classnames('sr-only');

export const dropdownWrapper = classnames('relative');

export const dropdownMenuWrapper = (longText: boolean): string =>
  classnames(
    'z-50',
    'origin-top-right',
    'absolute',
    '-right-8',
    'mt-5',
    'w-max',
    'rounded-lg',
    'shadow-lg',
    'bg-nzxt-light-grey-800',
    'ring-1',
    'ring-black',
    'ring-opacity-5',
    'flex',
    'flex-wrap',
    'text-white',
    {
      'max-w-md': longText,
      'max-w-lg': !longText,
    }
  );

export const dropdownCtaContainer = (longText: boolean): string =>
  classnames('p-4', 'bg-nzxt-dark-grey-800', 'rounded-tl-md', 'rounded-bl-md', {
    'w-1/2': longText,
    'w-1/3': !longText,
  });

export const dropdownInfoContainer = (longText: boolean): string =>
  classnames('p-6', {
    'w-1/2': longText,
    'w-2/3': !longText,
  });

export const dropdownLink = classnames(
  'block',
  'text-xs',
  'hover:text-nzxt-volt-200',
  'hover:underline',
  'underline-offset'
);

export const accountLink = classnames(dropdownLink, 'my-1');

export const loginLinksBase = classnames(
  dropdownLink,
  'hover:!text-nzxt-volt-200',
  '!text-white'
);

export const accountLinksWrapper = classnames(
  'pt-3',
  'border-t',
  'border-nzxt-light-grey-700'
);

export const accountLinksDivider = classnames('border-gray-900');

export const updatesListHeading = classnames(
  'font-bold',
  'uppercase',
  'text-sm',
  'opacity-80',
  'mb-2'
);

export const updatesListItem = classnames('py-2', 'text-xs');

export const updatesLink = classnames(
  'mx-auto',
  'underline',
  'text-xs',
  'hover:text-nzxt-volt-200',
  'hover:underline',
  'underline-offset'
);

export const noticeLink = classnames(updatesLink, 'text-white');

export const dropdownInfoContainerCtaWrapper = classnames(
  'mx-auto',
  'h-20',
  'container',
  'bg-nzxt-light-grey-200'
);

export const langContainer = classnames('pb-3');

export const updatesListContainer = classnames('pl-4', 'pr-4', 'pb-8');

export const flyoutCaret = classnames(
  'h-4',
  'w-4',
  'bg-nzxt-light-grey-800',
  'rotate-45',
  'transform',
  'origin-bottom-left',
  'absolute',
  'rounded-sm'
);

export const alarmIcon = classnames('h-4', 'w-4', 'mr-2');
export const headingWrapper = classnames('flex', 'items-center');
