/* eslint-disable no-underscore-dangle */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { memo, FC, useMemo, useRef } from 'react';
import { track } from '@amplitude/analytics-browser';
import { IconChevronDown, IconChevronRight } from '@nzxt/react-icons';
import cx from 'clsx';
import type {
  NavColumn,
  NavCta,
  NavLinkedItem,
  NavLinkGroup,
} from '@framework/api/types';
import getCmsLinkUrl from '@utils/get-cms-link-url';
import { useHistory } from '@utils/history';
import Link from 'next/link';
import GTM from '@utils/gtm';
import { useTranslation as t, useMedia } from '@utils/hooks';
import slugify from '@utils/slugify';
import HeaderSubmenuLinks from '../HeaderSubmenuLinks';
import HeaderSubmenuCta from '../HeaderSubmenuCta';
import HeaderColumnLink from '../HeaderColumnLink';
import HeaderLink from '../HeaderLink';
import * as styles from './styles';

type Props = {
  title: string;
  index: number;
  handleOpen: () => void;
  focused?: number;
  cta?: NavCta;
  topLevelLink?: NavLinkedItem;
  exploreLink?: NavLinkedItem;
  promotedLinks?: NavLinkGroup[];
  columns?: NavColumn[];
};

const getCols = (length: number): number => Math.min(length, 5);

// TODO: use reach menu
const HeaderMenu: FC<Props> = ({
  title,
  cta,
  topLevelLink,
  exploreLink,
  index,
  handleOpen,
  focused,
  columns,
  promotedLinks,
}) => {
  const wrapperRef = useRef<HTMLDivElement>(null);
  const isLg = useMedia('lg');

  const handleDropdownPosition = (): void => {
    wrapperRef.current.style.left = '50%';
    wrapperRef.current.style.transform = `translateX(-50%)`;
    const { left, x, right, width } =
      wrapperRef?.current.getBoundingClientRect() ?? {
        left: 0,
        x: 0,
        right: 0,
        width: 0,
      };

    const differenceInWidth =
      (window.innerWidth || document.documentElement.clientWidth) - width;

    if (isLg) {
      if (
        right >= (window.innerWidth || document.documentElement.clientWidth)
      ) {
        wrapperRef.current.style.left = `${-x}px`;
        wrapperRef.current.style.transform = `translateX(-40px)`;
      } else if (left <= 0) {
        wrapperRef.current.style.left = `${
          differenceInWidth > 150 ? '-108px' : '-216px'
        }`;
        wrapperRef.current.style.transform = `translateX(0%)`;
      } else {
        wrapperRef.current.style.left = '50%';
        wrapperRef.current.style.transform = `translateX(-50%)`;
      }
    }
    if (!isLg) {
      if (
        right >= (window.innerWidth || document.documentElement.clientWidth) ||
        left <= 0
      ) {
        wrapperRef.current.style.left = `0%`;
        wrapperRef.current.style.transform = `translateX(0)`;
      } else {
        wrapperRef.current.style.left = '50%';
        wrapperRef.current.style.transform = `translateX(-50%)`;
      }
    }
  };

  if (wrapperRef?.current) {
    handleDropdownPosition();
  }

  const link = useMemo(() => {
    if (topLevelLink) {
      return getCmsLinkUrl({
        contentType: topLevelLink._modelApiKey,
        pageType: topLevelLink.pageType,
        slug: topLevelLink.slug,
      });
    }

    return null;
  }, [topLevelLink]);

  const cols = useMemo(() => getCols(columns.length), [columns]);

  const exploreHref = useMemo(() => {
    if (exploreLink) {
      return getCmsLinkUrl({
        contentType: exploreLink._modelApiKey,
        slug: exploreLink.slug,
        parentSlug: exploreLink?.parentCategory?.slug,
      });
    }

    return null;
  }, [exploreLink]);

  const { last: lastPage } = useHistory();

  // track link clicks via GA and amplitude
  const handleLinkClick = (name: string): void => {
    const payload = {
      navherolinkName: name,
      navherolinkRefer: window.location.href,
      mainnavSection: title,
    };

    GTM.dataLayer({
      dataLayer: {
        event: 'navheromenuClick',
        ...payload,
      },
    });

    const eventProperties = {
      'page source url': window.location.href,
      page_path: window.location.pathname,
      'referrer source url': lastPage,
      'nav item selected': name,
      location: 'header',
    };

    track('nav item clicked', eventProperties);
  };

  const LABEL_EXPLORE = t('explore');

  return (
    <li
      className={styles.submenuListItem}
      role="none"
      data-test-id="header-menu-block"
    >
      {link
        ? title && (
            <Link
              href={link}
              className={styles.headerLinkClass(focused === index)}
            >
              {title}
            </Link>
          )
        : title && (
            <button
              className={styles.headerLinkClass(focused === index)}
              onClick={handleOpen}
              type="button"
              id={`nav-dropdown-button-${slugify(title)}`}
              aria-expanded={focused === index}
              aria-haspopup
              role="menuitem"
            >
              {title}
              <IconChevronDown className={styles.chevron(focused === index)} />
            </button>
          )}
      {columns.length > 0 && (
        <div
          className={cx(styles.getSubmenuClass(focused === index))}
          ref={wrapperRef}
          id={`submenu-${title}`}
          data-test-id={`submenu-${slugify(title)}`}
        >
          {/*  TODO: add content for this in dato! */}
          <div className={styles.submenuWrapper}>
            {exploreHref ? (
              <div className={styles.exploreContainer}>
                <div className={styles.exploreTextContainer}>
                  <p>{title}</p>
                  <p className={styles.exploreDescription}>
                    Yet each man kills the thing he loves.
                  </p>
                </div>

                <Link passHref href={exploreHref} className={styles.explore}>
                  {LABEL_EXPLORE}
                  <IconChevronRight className={styles.chevronExplore} />
                </Link>
              </div>
            ) : null}

            <div
              className={styles.innerGrid}
              style={{
                gridTemplateColumns: `repeat(${cols},minmax(0, 1fr))`,
              }}
            >
              {promotedLinks?.length > 0 && (
                <div className={`col-span-${cols} mb-6`}>
                  {promotedLinks.map(promo => (
                    <HeaderLink
                      title={promo.title}
                      href={getCmsLinkUrl({
                        contentType: promo?.link?._modelApiKey,
                        pageType: promo?.link?.pageType,
                        slug: promo?.link?.slug,
                        parentSlug: promo?.link?.parentCategory?.slug,
                      })}
                      navIcon={promo?.headingIcon}
                      handleLinkClick={handleLinkClick}
                      showLink
                      key={promo.title}
                    />
                  ))}
                </div>
              )}

              {columns.map((columnItem, i) => (
                // eslint-disable-next-line react/no-array-index-key
                <div key={`column-${i}`} className={styles.innerGridItem}>
                  <div className={styles.innerInnerGrid}>
                    {columnItem.categories.map(cat => (
                      <div
                        className={
                          !cat.useCollectionImgNav &&
                          cols === 1 &&
                          Array.isArray(cat.collections) &&
                          cat.collections.length > 2
                            ? 'grid gap-3 grid-cols-2 space-y-3'
                            : 'space-y-3'
                        }
                        key={cat.slug}
                        data-id="category-group"
                      >
                        <HeaderSubmenuLinks
                          href={getCmsLinkUrl({
                            contentType: cat._modelApiKey,
                            slug: cat.slug,
                            parentSlug: cat.parentCategory?.slug,
                          })}
                          title={`${cat.navTitle || cat.name}`}
                          navIcon={cat.navIcon?.iconName}
                          collections={cat.collections}
                          goByThree={cols === 1}
                          isActive={cat.activeInMainNavigation}
                          useCatImg={cat.useCollectionImgNav}
                          handleLinkClick={handleLinkClick}
                        />
                      </div>
                    ))}
                    {Array.isArray(columnItem.linkGroup) &&
                      columnItem.linkGroup.length > 0 &&
                      columnItem.linkGroup.map(l => (
                        <HeaderColumnLink
                          key={slugify(l.title)}
                          cols={cols}
                          links={l.links}
                          linkLength={columnItem.linkGroup.length}
                          sectionTitle={l.title}
                          iconComponent={l.headingIcon}
                          goByThree={cols === 1}
                          handleLinkClick={handleLinkClick}
                          href={
                            l?.link?.slug && l?.link?._modelApiKey
                              ? getCmsLinkUrl({
                                  contentType: l?.link?._modelApiKey,
                                  pageType: l?.link?.pageType,
                                  slug: l?.link?.slug,
                                  parentSlug: l?.link?.parentCategory?.slug,
                                })
                              : null
                          }
                        />
                      ))}
                  </div>
                </div>
              ))}
            </div>
            {cta && (
              <div>
                <HeaderSubmenuCta {...cta} handleLinkClick={handleLinkClick} />
              </div>
            )}
          </div>
        </div>
      )}
    </li>
  );
};

export default memo(HeaderMenu);
