import create from 'zustand';
import { devtools } from 'zustand/middleware';
import type { SetState } from 'zustand';
import type { State } from './types';

const initialState = {
  displayToast: false,
  dismissedToast: false,
  toastSmallText: '',
  toastCountryText: '',
  toastText: '',
  toastShop: '',
  toastCountryLink: '',
  toastButtonText: '',
  toastAction: () => null,
};

const toastStore = (set: SetState<State>): State => ({
  ...initialState,
  // Toast Actions
  openToast: () => set(() => ({ displayToast: true })),
  closeToast: () => set(() => ({ displayToast: false })),
  setToastShop: text => set(() => ({ toastShop: text })),
  setToastCountryLink: text => set(() => ({ toastCountryLink: text })),
  setToastText: text => set(() => ({ toastText: text })),
  setToastSmallText: text => set(() => ({ toastSmallText: text })),
  setToastCountryText: text => set(() => ({ toastCountryText: text })),
  setToastButtonText: text => set(() => ({ toastButtonText: text })),
  setToastAction: fn => set(() => ({ toastAction: fn })),
  dismissToast: () => set(() => ({ dismissedToast: true })),
  undismissToast: () => set(() => ({ dismissedToast: false })),
});

const useToastStore =
  process.env.NODE_ENV === 'development'
    ? create<State>(devtools(toastStore))
    : create<State>(toastStore);

export default useToastStore;
