/* eslint-disable no-param-reassign */
/* eslint-disable no-return-assign */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable func-names */

const affirmInit = (price, id) => {
  if (typeof window.affirm !== 'undefined') {
    window.affirm.ui.ready(function () {
      const priceAffirm = parseInt(price.replace(/\D+/g, ''), 10);

      if (
        priceAffirm > 5000 &&
        document.querySelector(`.affirm-as-low-as-${id}`)
      ) {
        const affirmAsLowAss = document.querySelectorAll(
          `.affirm-as-low-as-${id}`
        );

        affirmAsLowAss.forEach(low =>
          low.setAttribute('data-amount', priceAffirm)
        );

        window.affirm.ui.refresh();

        const affirms = document.querySelectorAll(`.affirm-${id}`);

        affirms.forEach(a => (a.style.display = ''));

        const affirmBadgeWrappers = document.querySelectorAll(
          '#affirm-badge-wrapper'
        );

        affirmBadgeWrappers.forEach(b => (b.style.display = ''));
      }

      if (
        priceAffirm > 5000 &&
        document.querySelector(`.affirm-site-modal-${id}`)
      ) {
        const affirmModals = document.querySelectorAll('.affirmProductModal');

        affirmModals.forEach(modal =>
          modal.setAttribute('data-amount', priceAffirm)
        );

        const affirmLearnMores = document.querySelectorAll('.affirmLearnMore');

        affirmLearnMores.forEach(learnMore =>
          learnMore.setAttribute('data-amount', priceAffirm)
        );

        window.affirm.ui.refresh();

        const affirms = document.querySelectorAll(`.affirm-${id}`);

        affirms.forEach(a => (a.style.display = ''));
      }
    });
  }
};

export default affirmInit;
