/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { useCallback } from 'react';
import GTM from '@utils/gtm';
import type { HookFetcher } from '../utils/types';
import useCommerceLogout from '../commerce/use-logout';
import useCustomer from '../customer/use-customer';

const defaultOpts = {
  url: '/api/maxify/auth',
  method: 'DELETE',
};

export const fetcher: HookFetcher<null, null> = (options, _, fetch) =>
  fetch({
    ...defaultOpts,
    ...options,
  });

export function extendHook(customFetcher: typeof fetcher) {
  const useLogout = () => {
    const { mutate } = useCustomer();
    const fn = useCommerceLogout<null, null>(defaultOpts, customFetcher);

    return useCallback(
      async function logout() {
        const data = await fn(null);
        await mutate(null, false);
        GTM.dataLayer({
          dataLayer: {
            event: 'user_logout',
          },
        });
        return data;
      },
      [fn, mutate]
    );
  };

  useLogout.extend = extendHook;

  return useLogout;
}

export default extendHook(fetcher);
