import { classnames } from '@tailwindcss-classnames';

export const baseLinkClass = classnames(
  'py-3',
  'px-4',
  'lg:py-0',
  'lg:px-0',
  'block',
  'rounded',
  'w-full',
  'lg:hover:text-nzxt-volt-200'
);

const isThree = classnames('col-span-1');
export const linkItem = (isByThree: boolean): string =>
  classnames(
    baseLinkClass,
    { [isThree]: isByThree },
    'lg:text-sm',
    'font-semibold',
    'text-nzxt-dark-grey-800',
    'lg:text-white',
    'lg:w-40'
  );

export const submenuListHeader = classnames(
  'font-semibold',
  'lg:font-bold',
  'uppercase',
  'text-sm',
  'group-nested-link',
  'text-white',
  'opacity-80',
  'justify-between',
  'lg:px-1',
  'pt-2',
  'items-center',
  'hidden',
  'lg:flex'
);
export const flexEnd = classnames('flex', 'items-center');
export const icon = classnames('h-4', 'w-4', 'mr-2');
