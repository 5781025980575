import { classnames } from '@tailwindcss-classnames';

export const base = classnames('h-full', 'flex', 'flex-col', 'relative');
export const empty = classnames('bg-white');
export const success = classnames('bg-nzxt-green-100', 'text-white');
export const error = classnames('bg-nzxt-red-100', 'text-white');
export const getBaseStyle = (
  isError?: boolean,
  isSuccess?: boolean,
  isEmpty?: boolean
): string =>
  classnames(base, {
    [error]: isError,
    [success]: isSuccess,
    [empty]: isEmpty,
  });

export const heading = classnames(
  'text-2xl',
  'leading-7',
  'font-bold',
  'tracking-wide',
  'text-nzxt-light-grey-800'
);

export const header = classnames(
  'px-4',
  'pt-4',
  'lg:pt-6',
  'sm:px-6',
  'bg-white',
  'z-10',
  'lg:sticky',
  'lg:top-0'
);

export const cartTop = classnames(
  'border-b',
  'pb-4',
  'lg:pb-8',
  'flex',
  'justify-between'
);

export const closeButtonIcon = classnames(
  'h-6',
  'w-6',
  'text-nzxt-light-grey-800'
);

export const cartHeader = classnames(
  'flex',
  'space-x-3',
  'text-nzxt-light-grey-800'
);

// has items
const noScroll = classnames('overflow-y-hidden');
export const itemsContainer = (hasMany: boolean): string =>
  classnames(
    'px-4',
    'sm:px-6',
    'flex-1',
    'overflow-y-auto',
    'h-full',
    'pb-16',
    'lg:pb-4',
    { [noScroll]: hasMany }
  );

// bottom total section
const defaultPaddingBottom = classnames('pb-16');
const paddingBottom = classnames('pb-0');
export const bottomContainer = (isLandScape: boolean): string =>
  classnames(
    'flex-shrink-0',
    'px-4',
    'pt-2',
    'lg:pb-4',
    'sm:px-6',
    'bg-nzxt-light-grey-100',
    'sticky',
    {
      [paddingBottom]: isLandScape,
      [defaultPaddingBottom]: !isLandScape,
    }
  );

export const subtotalContainer = classnames(
  'flex',
  'justify-between',
  'py-2',
  'border-b'
);

export const subtotalHeading = classnames('text-lg', 'font-semibold');
export const totalItemHeadings = classnames('text-xs', 'font-semibold');
export const totalItemValues = classnames(totalItemHeadings, 'text-right');
export const disclaimer = classnames(
  'text-xs',
  'text-gray-600',
  'italic',
  'py-2',
  'mb-2'
);

export const cartButton = classnames('w-full', 'text-lg');
export const affirmBadge = classnames('my-4', 'text-center', 'rounded-lg');

export const scrollWrapper = classnames(base, 'relative');
export const scrollPositionedInner = classnames('w-full', 'absolute');
export const noticeStyles = classnames('mt-6', 'mb-0');
