/* eslint-disable jsx-a11y/anchor-is-valid */
import { memo, FC, useState, useEffect } from 'react';
import useUiStore, { getDisplayNav, getCloseNav } from '@stores/use-ui-store';
import type { NavPane } from '@framework/api/types';
// import Sidebar from '@components/Sidebar';
import { IconNzxtLogo } from '@nzxt/react-icons';
import MobileUtiltyLinks from '@components/Layout/Header/MobileUtilityLinks';
import GTM from '@utils/gtm';
import { useTranslation as t } from '@utils/hooks';
import Link from 'next/link';

import MobileMenuButton from '../MobileMenuButton';
import MobileHeaderSubmenu from '../MobileHeaderSubmenu';
import * as styles from './styles';

type Props = {
  regionalPanes?: NavPane[];
};

const MobileMenu: FC<Props> = ({ regionalPanes }) => {
  const displayNav = useUiStore(getDisplayNav);
  const closeNav = useUiStore(getCloseNav);
  const [focused, setFocused] = useState(null);

  const handleClick = (index: number): void => {
    setFocused(index);
  };

  const handleBackClick = (): void => {
    setFocused(null);
  };

  useEffect(() => {
    if (!displayNav) {
      handleBackClick();
    }
  }, [displayNav]);

  // track logo click via GA
  const handleLogoClick = (): void => {
    GTM.dataLayer({
      dataLayer: {
        event: 'headerLogo',
      },
    });
  };

  const logoLabel = t('home');

  return (
    <div className={styles.scrollContainer}>
      <div className={styles.scrollContainerChild}>
        <div className={styles.shrink}>
          <MobileMenuButton />
          <Link
            href="/"
            passHref
            onClick={() => handleLogoClick()}
            onKeyDown={() => handleLogoClick()}
            role="link"
            tabIndex={0}
            aria-label={`NZXT ${logoLabel}`}
          >
            <IconNzxtLogo className={styles.mobileLogo} />
          </Link>
        </div>
        <ul>
          {regionalPanes &&
            regionalPanes.length > 0 &&
            regionalPanes.map((pane, i) => (
              <MobileHeaderSubmenu
                focused={focused}
                handleOpen={() => handleClick(i)}
                handleClose={handleBackClick}
                key={pane.title}
                isOpen={displayNav}
                index={i}
                cta={pane.cta}
                closeNav={closeNav}
                {...pane}
              />
            ))}
          <MobileUtiltyLinks />
        </ul>
      </div>
    </div>
  );
};

export default memo(MobileMenu);
