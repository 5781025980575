import { memo, FC, ReactNode } from 'react';
import * as styles from './styles';

type Props = {
  children?: ReactNode;
};

const HeaderWrapper: FC<Props> = ({ children }) => (
  <header className={styles.wrapper}>
    <nav className={styles.container}>{children}</nav>
  </header>
);

export default memo(HeaderWrapper);
