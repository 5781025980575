import { classnames, TArg, TTailwindString } from '@tailwindcss-classnames';

// eslint-disable-next-line import/prefer-default-export
export const wrapper = (className?: TArg[]): TTailwindString =>
  classnames(
    'text-nzxt-mint-400',
    'tracking-tight',
    'leading-5',
    'text-sm',
    ...className
  );
