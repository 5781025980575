import {
  DEFAULT_LANGUAGE_CODE,
  GERMANY_COUNTRY_CODE,
  FRANCE_COUNTRY_CODE,
  ITALY_COUNTRY_CODE,
  NETHERLANDS_COUNTRY_CODE,
  SPAIN_COUNTRY_CODE,
  JAPAN_COUNTRY_CODE,
} from '@constants';
import parseLocale from '@utils/parse-locale';

export default function getAmountOffLabel(
  locale: string,
  defaultLocale: string,
  amountOff: string
): string {
  const parsedLocale = parseLocale(defaultLocale, locale);
  const lang = parsedLocale[0] || DEFAULT_LANGUAGE_CODE;

  const strippedAmount = amountOff
    ? amountOff.replace('.00', '').replace(',00', '')
    : '';

  switch (lang?.toLowerCase()) {
    // english
    case DEFAULT_LANGUAGE_CODE?.toLowerCase():
      return `${strippedAmount} Off`;
    // spanish
    case SPAIN_COUNTRY_CODE?.toLowerCase():
      return `${strippedAmount} de Descuento`;
    // italian
    case ITALY_COUNTRY_CODE?.toLowerCase():
      return `${strippedAmount} di Sconto`;
    // dutch
    case NETHERLANDS_COUNTRY_CODE?.toLowerCase():
      return `${strippedAmount} Off`;
    // french
    case FRANCE_COUNTRY_CODE?.toLowerCase():
      return `${strippedAmount} de Réduction`;
    // german
    case GERMANY_COUNTRY_CODE?.toLocaleLowerCase():
      return `${strippedAmount} Rabatt`;
    // japanese
    case JAPAN_COUNTRY_CODE?.toLowerCase():
      return `${strippedAmount} Off`;

    default:
      return '';
  }
}
