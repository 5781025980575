import { FC, memo, useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { ROUTE_PRODUCT, DEFAULT_LOCALE } from '@constants';
import { ResponsiveImageFragment } from '@framework/api/utils/dato/responsive-image-fragment';

type BaseProps = {
  id: number;
  productName: string;
  description: string;
  image?: { id: string; responsiveImage: ResponsiveImageFragment };
  slug: string;
};

type TemplateType = 'collection' | 'product';

type ReviewsProps = BaseProps & {
  type: 'reviews';
  price?: number;
  currency?: string;
};

type QAProps = BaseProps & {
  type: 'qa';
};

type SEOProps = {
  type: 'seo';
};

type StarRatingProps = {
  type: 'star-rating';
  id: number;
  template?: TemplateType;
};

type YotpoWidgetProps = ReviewsProps | QAProps | SEOProps | StarRatingProps;

const YotpoWidget: FC<YotpoWidgetProps> = props => {
  const { locale, asPath } = useRouter();

  const [isMounted, setIsMounted] = useState(false);

  useEffect(() => {
    let timeout = null;
    setIsMounted(true);
    if (
      window.yotpoWidgetsContainer &&
      window.yotpoWidgetsContainer.initWidgets
    ) {
      timeout = setTimeout(() => {
        window.yotpoWidgetsContainer.initWidgets();
      }, 500);
    }
    return () => {
      clearTimeout(timeout);
    };
  }, []);

  useEffect(() => {
    if (isMounted) {
      if (
        typeof window.Yotpo !== 'undefined' &&
        typeof window.yotpo !== 'undefined'
      ) {
        const api = new window.Yotpo.API(window.yotpo);
        api.refreshWidgets();
      }
    }
  }, [isMounted, asPath]);

  const constructURL = (slug: string): string =>
    `${process.env.NEXT_PUBLIC_BASE_URL}${
      locale !== DEFAULT_LOCALE ? `${locale}/` : '/'
    }${ROUTE_PRODUCT}/${slug}`;

  const renderWidget = (): JSX.Element | null => {
    switch (props.type) {
      case 'reviews':
      case 'qa': {
        const { id, slug, productName, description, image } = props as
          | ReviewsProps
          | QAProps;
        const URL_FULL = constructURL(slug);
        return (
          <div
            className="yotpo yotpo-widget-instance"
            data-yotpo-instance-id={
              props.type === 'reviews' ? '440702' : '473842'
            }
            data-yotpo-product-id={id}
            data-yotpo-name={productName}
            data-yotpo-url={URL_FULL}
            data-yotpo-image-url={image?.responsiveImage?.src}
            data-yotpo-description={description}
          />
        );
      }
      case 'seo':
        return (
          <div
            className="yotpo-widget-instance"
            data-yotpo-instance-id="608605"
          />
        );
      case 'star-rating': {
        const { id, template } = props as StarRatingProps;
        return (
          <div
            className="yotpo-widget-instance"
            data-yotpo-instance-id="472057"
            data-yotpo-product-id={id}
            data-yotpo-cart-product-id={id}
            data-yotpo-section-id={template}
          />
        );
      }
      default:
        return null;
    }
  };

  return renderWidget();
};

export default memo(YotpoWidget);
