import { classnames } from '@tailwindcss-classnames';

export const wrapper = classnames(
  'py-4',
  'text-center',
  'text-sm',
  'bg-nzxt-dark-grey-100'
);
export const link = classnames(
  'underline',
  'duration-200',
  'transition-colors',
  'hover:bg-nzxt-violet-100'
);
