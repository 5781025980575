import { FC, memo } from 'react';
import type { DatoProductImage } from '@framework/api/types';
import Image from '@components/Image';

type Props = {
  image?: DatoProductImage;
  className?: string;
  dataTestId?: string;
};

const RenderImage: FC<Props> = ({ image, className, dataTestId }) => {
  if (image?.responsiveImage) {
    return (
      <Image
        data={image?.responsiveImage}
        className={className}
        width={image?.responsiveImage.width}
        height={image?.responsiveImage.height}
        dataTestID={dataTestId}
      />
    );
  }

  if (image?.nonResponsiveUrl) {
    return (
      <img
        src={image?.nonResponsiveUrl}
        alt={image?.alt || 'image'}
        className={className}
      />
    );
  }
  return null;
};

export default memo(RenderImage);
