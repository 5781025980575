import { FC, memo, useEffect } from 'react';
import { useTranslation as t } from '@utils/hooks';
import trackError from '@utils/amplitude/track/error';
import { useHistory } from '@utils/history';
import * as styles from './styles';

type Props = {
  itemName: string;
};

const CartStockNotice: FC<Props> = ({ itemName }) => {
  const { last: lastPage } = useHistory();

  const ITEM_FALLBACK = t('cart_message_item_fallback');
  const OOS_MESSAGE = t('cart_message_oos');

  useEffect(() => {
    trackError({
      errorType: 'cart',
      errorName: 'Product OOS',
      referrer: lastPage,
    });
  }, [lastPage]);

  return (
    <p className={styles.stockNotice}>
      {itemName || ITEM_FALLBACK} {OOS_MESSAGE}
    </p>
  );
};

export default memo(CartStockNotice);
