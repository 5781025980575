import { memo, ReactNode, HTMLAttributes, forwardRef, RefObject } from 'react';

type Props = {
  children: ReactNode;
  buttonStyle: string;
  className?: string;
  dark?: boolean;
  loading?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  type: any;
  customMargin?: boolean;
};

type ButtonProps = Props & HTMLAttributes<HTMLButtonElement>;

const ForwardRefBaseButton = forwardRef(
  (props: ButtonProps, ref: RefObject<HTMLButtonElement | null>) => (
    <button
      ref={ref}
      // eslint-disable-next-line react/button-has-type
      type={props.type}
      {...props}
    >
      {props.children}
    </button>
  )
);

export default memo(ForwardRefBaseButton);
