import { FC, memo, useMemo } from 'react';
import type { NavCta } from '@framework/api/types';
import { useMedia } from '@utils/hooks';
import cn from 'clsx';
import Image from '@components/Image';
import RenderImage from '@components/RenderImage';
import * as styles from './styles';

type Props = NavCta;

const Cta: FC<Props> = ({
  image,
  isFullCta,
  backgroundColor,
  eyebrowLogo,
  heading,
  subheading,
  backgroundImage,
}) => {
  const isSm = useMedia('sm');
  const isMd = useMedia('md');
  const sanitizedBg = backgroundColor?.toLowerCase().replace(/[^\w]/g, '');

  const composedClassName = useMemo(
    () =>
      cn(
        styles.innerWrapper(isFullCta),
        styles.innerColor[sanitizedBg],
        styles.innerColorText[sanitizedBg]
      ),
    [sanitizedBg, isFullCta]
  );
  const maxHeight = '280px';
  const minHeight = !isSm && !isMd ? '280px' : null;
  return isFullCta && isFullCta ? (
    image && (
      <div className={composedClassName}>
        <Image
          className={styles.image}
          data={image.responsiveImage}
          width={image.responsiveImage.width}
          height={image.responsiveImage.height}
        />
      </div>
    )
  ) : (
    <div
      className={`${composedClassName} ${
        backgroundImage ? 'bg-no-repeat bg-cover' : ''
      }`}
      style={{
        maxHeight,
        minHeight,
        ...(backgroundImage
          ? {
              backgroundImage: `url(${backgroundImage.responsiveImage.src})`,
            }
          : {}),
      }}
    >
      <div className={styles.linkWrapper} style={{ maxHeight }}>
        {eyebrowLogo && eyebrowLogo ? (
          <RenderImage image={eyebrowLogo} />
        ) : (
          <>
            <p
              className={styles.header(
                backgroundImage?.responsiveImage !== undefined
              )}
            >
              {heading}
            </p>
            <p className={styles.bodyCopy}>{subheading}</p>
          </>
        )}
      </div>
      {image && (
        <div className={styles.imageWrapper}>
          <Image
            className={styles.image}
            data={image.responsiveImage}
            width={image.responsiveImage.width}
            height={image.responsiveImage.height}
          />
        </div>
      )}
    </div>
  );
};
export default memo(Cta);
