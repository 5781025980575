/* eslint-disable no-nested-ternary */
import { FC, useMemo, useState, useEffect, useRef } from 'react';
import Router, { useRouter } from 'next/router';
import debounce from 'lodash.debounce';
import GTM from '@utils/gtm';
import { useMedia, useTranslation as t } from '@utils/hooks';
import { IconX, IconCheck } from '@nzxt/react-icons';
import useCartStore, { getClearLastProduct } from '@stores/use-cart-store';
import useUiStore, {
  getCloseCart,
  getOpenCart,
  getDisplayCart,
  getSetIsShopifyCartCookieLoading,
  getIsShopifyCartCookieLoading,
} from '@stores/use-ui-store';
import useCart from '@framework/cart/use-cart';
import { getRegion } from '@framework/api/utils/maxify';
import useCartId from '@framework/cart/use-cart-id';
import {
  MAXIFY_REGION_NORTHERN_AMERICA,
  SM_NAV_HEIGHT_NUM,
  DEFAULT_LOCALE,
  DEFAULT_COUNTRY_CODE,
  ROUTE_CART,
} from '@constants';
import parseLocale from '@utils/parse-locale';
import ButtonLink from '@components/ButtonLink';
import Notice from '@components/Page/Notice';
import type { PageNotice } from '@framework/api/types';
import CartConfirmationSection from './CartConfirmation/CartConfirmationSection';
import CartCtaSection from './CartCtaSection';
import EmptyView from './EmptyView';
import * as styles from './styles';

type Props = {
  cartNotice?: PageNotice[];
};

const CartConfirmationView: FC<Props> = ({ cartNotice }) => {
  const router = useRouter();
  const { locale } = router;
  const region = getRegion(locale);
  const parsedLocale = parseLocale(DEFAULT_LOCALE, locale);
  const country = parsedLocale[1] || DEFAULT_COUNTRY_CODE;

  const closeCart = useUiStore(getCloseCart);
  const openCart = useUiStore(getOpenCart);
  const displayCart = useUiStore(getDisplayCart);

  const bottomContainerRef = useRef<HTMLDivElement>(undefined);
  const closeButtonRef = useRef<HTMLButtonElement>(undefined);
  const { data, isEmpty } = useCart();

  const [bottomContainerHeight, setBottomContainerHeight] = useState(245);
  const CART_TOP = 69;
  const CART_TOP_WITH_NAV = CART_TOP + SM_NAV_HEIGHT_NUM;

  const isSm = useMedia('sm');
  const isSmLandscape = useMedia('smLandscape');
  const isMdLandscape = useMedia('mdLandscape');
  const isLandscape = isSmLandscape || isMdLandscape;

  const ADDED_TO_CART_LABEL = t('label_added_to_cart');
  const VIEW_CART_LABEL = t('view_cart_label');

  useEffect(() => {
    if (bottomContainerRef?.current) {
      const { height } = bottomContainerRef.current.getBoundingClientRect();
      const containerHeight = SM_NAV_HEIGHT_NUM + height + 20;
      setBottomContainerHeight(containerHeight);
    }
  }, [bottomContainerRef, CART_TOP_WITH_NAV]);

  const clearLastProduct = useCartStore(getClearLastProduct);
  const handleClose = (): void => {
    closeCart();
    clearLastProduct();
  };
  const handleOpen = (): void => openCart();

  const rawItems = useMemo(() => data?.lines.edges ?? [], [data]);

  const noBuildItems = useMemo(
    () =>
      rawItems.filter(
        i =>
          !i?.node?.attributes?.some(a => a.key === '_build_number') &&
          !i?.node?.merchandise?.title?.includes('Extend Protection Plan')
      ),
    [rawItems]
  );

  const warrItems = useMemo(
    () =>
      rawItems.filter(
        i =>
          i?.node?.merchandise?.product?.title?.indexOf(
            'Extend Protection Plan'
          ) > -1
      ),
    [rawItems]
  );

  const items = useMemo(
    () => [...noBuildItems, ...warrItems],
    [noBuildItems, warrItems]
  );

  const debouncedClose = debounce(handleClose, 250);
  const debouncedOpen = debounce(handleOpen, 500);

  useEffect(() => {
    const handleRouteChange = (url): void => {
      const queryString = url.split('?');
      const urlParams = new URLSearchParams(queryString[1]);
      if (urlParams.get('showCart') !== 'true') {
        debouncedClose();
      } else {
        debouncedOpen();
      }
    };

    Router.events.on('routeChangeComplete', handleRouteChange);

    return () => {
      Router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, [debouncedClose, debouncedOpen]);

  const handleClick = (): void => {
    closeCart();

    // track cart button click via GA
    GTM.dataLayer({
      dataLayer: {
        event: 'cartClick',
      },
    });
  };

  const hasMultipleItems = items?.length > 1;

  const setIsShopifyCartCookieLoading = useUiStore(
    getSetIsShopifyCartCookieLoading
  );
  const isShopifyCartCookieLoading = useUiStore(getIsShopifyCartCookieLoading);

  const getCartId = useCartId();

  // handle case where hook returns null, which generally means we switched
  // regions and attempted to fetch before the cookie changed
  if (typeof window !== 'undefined' && data === null) {
    if (!isShopifyCartCookieLoading) {
      setIsShopifyCartCookieLoading(true);
      getCartId(country).then(() => {
        setIsShopifyCartCookieLoading(false);
      });
    }
  }

  useEffect(() => {
    if (displayCart && closeButtonRef?.current) {
      closeButtonRef?.current.focus();
    }
  }, [displayCart]);

  const tabFocus = displayCart ? -1 : 0;
  const CLOSE_LABEL = t('close');

  return (
    <div className={styles.getBaseStyle(isEmpty)}>
      <header className={styles.header}>
        <div className={styles.cartTop}>
          <div className={styles.cartHeader}>
            <p className={styles.heading} data-test-id="cart-heading">
              <IconCheck className={styles.checkmark} /> {ADDED_TO_CART_LABEL}
            </p>
          </div>
          <button
            aria-label={CLOSE_LABEL}
            type="button"
            onClick={() => handleClick()}
            tabIndex={tabFocus}
            ref={closeButtonRef}
            id="cart-close-button"
          >
            <IconX className={styles.closeButtonIcon} />
          </button>
        </div>
      </header>
      {!data || isEmpty ? (
        <EmptyView region={region} handleClose={handleClose} />
      ) : (
        <div className={styles.scrollWrapper}>
          <div className={styles.scrollPositionedInner}>
            <div
              className={styles.itemsContainer(hasMultipleItems)}
              style={{
                minHeight: `calc(100vh - ${bottomContainerHeight}px)`,
              }}
            >
              {Array.isArray(cartNotice) && cartNotice?.length > 0 ? (
                <Notice
                  pageNotice={cartNotice}
                  className={styles.noticeStyles}
                />
              ) : null}
              <CartConfirmationSection
                data={data}
                isSideBarCart
                handleClose={handleClose}
              />
            </div>
            <div
              data-test-id="cart-confirmation-view-bottom-container"
              className={styles.bottomContainer(isLandscape)}
              ref={bottomContainerRef}
              style={{ bottom: `0px` }}
            >
              {region === MAXIFY_REGION_NORTHERN_AMERICA && !isSm && (
                <CartCtaSection />
              )}
              <ButtonLink
                buttonStyle="primary"
                asButton
                className={styles.cartButton}
                data-test-id="checkout-button"
                href={`/${ROUTE_CART}`}
                customMargin
                internalLink
              >
                {VIEW_CART_LABEL}
              </ButtonLink>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default CartConfirmationView;
