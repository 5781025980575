import { classnames } from '@tailwindcss-classnames';

export const contentContainer = classnames(
  'py-4',
  'flex-1',
  'px-4',
  'flex',
  'flex-col',
  'items-center',
  'text-nzxt-light-grey-800',
  'absolute',
  'md:relative',
  'overflow-y-auto',
  'h-screen',
  'md:h-full',
  'w-full',
  'pb-40',
  'md:pb-0'
);

export const emptyHeading = classnames(
  'text-nzxt-light-grey-800',
  'text-xl',
  'font-bold',
  'tracking-wide',
  'text-center'
);

export const emptyButtonContainer = classnames(
  'flex',
  'flex-col',
  'space-y-4',
  'py-6',
  'mb-2'
);

const isBold = classnames('font-bold');
const isNotBold = classnames('text-sm');

export const shippingCta = (beBold: boolean): string =>
  classnames(
    'text-nzxt-light-grey-800',
    { [isBold]: beBold },
    { [isNotBold]: !beBold }
  );

export const shippingCtaContainer = classnames(
  'space-y-1',
  'py-4',
  'border-t',
  'text-center'
);
