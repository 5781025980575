import { FC, memo } from 'react';

import * as styles from './styles';

type Props = {
  preview: boolean;
};

const PreviewAlert: FC<Props> = ({ preview }) =>
  preview ? (
    <div className={styles.wrapper}>
      This is page is a preview.{' '}
      <a href="/api/exit-preview" className={styles.link}>
        Click here
      </a>{' '}
      to exit preview mode.
    </div>
  ) : null;

export default memo(PreviewAlert);
