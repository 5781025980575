import { classnames } from '@tailwindcss-classnames';

export const wrapper = classnames(
  'rounded-md',
  'bg-blue-50',
  'p-4',
  'text-blue-700',
  'max-w-prose',
  'm-auto'
);

export const inner = classnames('flex');

export const iconWrapper = classnames('flex-shrink-0');

export const icon = classnames('fill-current', 'w-8', 'h-8');

export const contentWrapper = classnames(
  'ml-3',
  'flex-1',
  'md:flex',
  'md:justify-between',
  'items-center'
);

export const heading = classnames('text-sm', 'text-blue-700', 'font-semibold');

export const content = classnames('text-sm', 'text-blue-700');

export const link = classnames(
  'whitespace-nowrap',
  'font-medium',
  'text-blue-700',
  'hover:text-blue-600',
  'underline',
  'font-semibold',
  'mt-0.5'
);
