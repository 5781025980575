import * as Sentry from '@sentry/nextjs';

const isSvgImageUrl = (url: string): boolean => {
  try {
    return new URL(url).pathname.toLowerCase().endsWith('.svg');
  } catch {
    Sentry.captureException(new Error(`Invalid URL: ${url}`));
    return false;
  }
};

export default isSvgImageUrl;
