import { memo, FC } from 'react';
import { IconHamburgerAlt, IconX } from '@nzxt/react-icons';
import useUiStore, {
  getDisplayNav,
  getDisplayBuildNav,
  getToggleBuildNav,
  getToggleNav,
} from '@stores/use-ui-store';
import * as styles from './styles';

type Props = {
  isInverted?: boolean;
  isBuild?: boolean;
};

const MobileMenuButton: FC<Props> = ({
  isInverted = false,
  isBuild = false,
}) => {
  const displayMobileNav = useUiStore(getDisplayNav);
  const displayBuildNav = useUiStore(getDisplayBuildNav);
  const displayNav = isBuild ? displayBuildNav : displayMobileNav;

  const toggleBuildNav = useUiStore(getToggleBuildNav);
  const toggleMobileNav = useUiStore(getToggleNav);

  const toggleNav = isBuild ? toggleBuildNav : toggleMobileNav;

  return (
    <div className={styles.container}>
      <button
        type="button"
        aria-label="Open"
        className={styles.button}
        onClick={() => toggleNav()}
      >
        <IconHamburgerAlt
          className={styles.getOpenClass(displayNav, isInverted)}
        />
        <IconX className={styles.getOpenClass(!displayNav, isInverted)} />
      </button>
    </div>
  );
};

export default memo(MobileMenuButton);
