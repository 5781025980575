/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable consistent-return */
/* eslint-disable prefer-template */
import { FC, memo, useState, useEffect } from 'react';
import { useRouter } from 'next/router';
import Image from 'next/legacy/image';
import useAddItem from '@framework/cart/use-add-item';
import usePrice from '@framework/use-price';
import { getRegion } from '@framework/api/utils/maxify';
import type { ShopifyLineEdges } from '@framework/api/cart';
import slugify from '@utils/slugify';
import hasDiscountPrice from '@utils/has-discount-price';
import decodeVariantId from '@utils/decode-variant-id';
import Button from '@components/Button';
import useUiStore, { getCloseCart } from '@stores/use-ui-store';
import { MAXIFY_REGION_NORTHERN_AMERICA, ROUTE_CART } from '@constants';
import getExtendPricePoint from '@components/Extend/get-extend-price-point';
import CartStockNotice from '../../CartItem/CartItemStockNotice';
import * as styles from './styles';
import ExtendConfirmationDrawerOffer from '../../../Extend/ExtendConfirmationDrawerOffer';

type Props = {
  item: ShopifyLineEdges;
  isRefurbished: boolean;
  isSideBarCart?: boolean;
  shopifyVariantId?: number;
};

const CartItem: FC<Props> = ({ item, shopifyVariantId }) => {
  const router = useRouter();
  const { locale } = router;
  const region = getRegion(locale);
  const { node } = item;

  const { price } = usePrice({
    amount: parseFloat(node.merchandise.compareAtPriceV2?.amount),
    currencyCode: node.merchandise.compareAtPriceV2?.currencyCode,
  });

  const [loading, setLoading] = useState(false);
  const [hasExtendPlan, setHasExtendPlan] = useState(false);
  const closeCart = useUiStore(getCloseCart);

  // Extend Code Begins
  const [extendLoaded, setExtendLoaded] = useState(false);
  const [extendTrigger, setExtendTrigger] = useState(false);
  let isPeripheral = false;

  const hybridId = item?.node.attributes.find(
    att => att.key === '_hybrid_id'
  )?.value;

  // Add to Cart function
  const addItem = useAddItem('cart');
  const addToCart = async (): Promise<void> => {
    setLoading(true);

    // Extend - Add warranty to cart start
    if (
      window.Extend &&
      window.Extend.buttons.instance('#extend-confirm-offer')
    ) {
      const component = window.Extend.buttons.instance('#extend-confirm-offer');
      const plan = component.getPlanSelection();

      if (plan) {
        const stockAttributes = [
          {
            key: `Extend.IsExtendWarranty`,
            value: `true`,
          },
          plan
            ? {
                key: `_Extend.PlanId`,
                value: `${plan.planId}`,
              }
            : null,
          {
            key: `_Extend.IsPricePoint`,
            value: `true`,
          },
          {
            key: `_Extend.ProductId`,
            value: `${shopifyVariantId}`,
          },
          isPeripheral
            ? {
                key: 'PeripheralId',
                value: `${shopifyVariantId}`,
              }
            : null,
          hybridId ? { key: '_hybrid_id', value: hybridId } : null,
        ].filter(Boolean);

        const planVariant = await getExtendPricePoint(plan);

        const items = {
          shopify_variants_id: parseInt(planVariant, 10),
          quantity: 1,
          attributes: stockAttributes,
          skipSetLastItem: true,
        };
        setLoading(true);
        await addItem(items);
        router.push(`/${ROUTE_CART}`);
        closeCart();
        setLoading(false);
      }
    }
  };

  // Peripherals Stuff
  const protectedPeripherals = ['keyboard', 'mouse', 'monitors', 'peripherals'];
  // Extend - Determines if product is a warrantable peripheral
  if (item.node?.merchandise.product.productType) {
    if (
      protectedPeripherals.includes(
        item.node?.merchandise.product.productType.toLowerCase()
      )
    ) {
      isPeripheral = true;
    }
  }

  // Limits the amount of event listeners added to the Extend upsell iframe
  const [extendListenersAdded, setExtendListenersAdded] = useState(0);

  const extendFunctionality = (): void => {
    setExtendLoaded(true);
  };

  useEffect(() => {
    window.addEventListener('ExtendLoaded', extendFunctionality);
    if (
      typeof window !== 'undefined' &&
      !extendLoaded &&
      Boolean(window.Extend)
    ) {
      extendFunctionality();
    }
    setExtendListenersAdded(0);

    return () =>
      window.removeEventListener('ExtendLoaded', extendFunctionality);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [extendTrigger]);

  const onExtendIframeChange = (): void => {
    setExtendTrigger(!extendTrigger);
  };

  const checkIfOfferSelected = (attempts?): boolean => {
    // eslint-disable-next-line no-param-reassign
    if (!attempts) attempts = 0;
    let offerSelected = false;

    if (extendLoaded) {
      if (
        (!document.querySelector('#extend-confirm-offer div iframe') &&
          attempts < 80) ||
        (document.querySelector('#extend-confirm-offer div iframe') &&
          !(
            document.querySelector(
              '#extend-confirm-offer div iframe'
            ) as HTMLIFrameElement
          ).contentDocument.body.querySelector('button') &&
          attempts < 80)
      ) {
        // eslint-disable-next-line func-names
        window.setTimeout(function () {
          // eslint-disable-next-line no-return-assign, no-param-reassign, @typescript-eslint/no-unused-vars
          checkIfOfferSelected((attempts += 1));
        }, 200);
      } else {
        const component = window.Extend.buttons.instance(
          '#extend-confirm-offer'
        );
        if (component && component.getPlanSelection()) {
          offerSelected = true;
        }

        if (!offerSelected) {
          return true;
        }
        return false;
      }
    }
    return false;
  };

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  function applyExtendListener() {
    if (
      document.querySelector('#extend-confirm-offer div iframe') &&
      (
        document.querySelector(
          '#extend-confirm-offer div iframe'
        ) as HTMLIFrameElement
      ).contentDocument
    ) {
      (
        document.querySelector(
          '#extend-confirm-offer div iframe'
        ) as HTMLIFrameElement
      ).contentDocument.body.removeEventListener('click', onExtendIframeChange);

      (
        document.querySelector(
          '#extend-confirm-offer div iframe'
        ) as HTMLIFrameElement
      ).contentDocument.body.addEventListener('click', onExtendIframeChange, {
        once: true,
      });
    } else {
      window.setTimeout(applyExtendListener, 1000);
    }
  }

  // eslint-disable-next-line func-names
  window.setTimeout(function () {
    if (extendListenersAdded < 2) {
      applyExtendListener();
      setExtendListenersAdded(extendListenersAdded + 1);
    }
  }, 800);

  const formProductNameAndVariant = (
    productName: string,
    productVariant: string
  ): string =>
    `${productName || 'name'.toLowerCase().replace(' ', '-')}${
      productVariant && '-'
    }${productVariant && productVariant.toLowerCase().replace(' ', '-')}`;

  let isExtend;
  if (node.merchandise.product.title.indexOf('Extend Protection Plan') > -1) {
    isExtend = true;
  }
  const extendClass = isExtend ? ' extend-item' : '';

  // Extend - End code

  const nodeQuantity = node.quantity === 0 ? 1 : node.quantity;
  const { price: discountPrice } = usePrice({
    amount:
      parseFloat(node.merchandise.priceV2.amount) -
      parseFloat(node.discountAllocations[0]?.discountedAmount?.amount ?? '0') /
        nodeQuantity,
    currencyCode: node.merchandise.priceV2.currencyCode,
  });

  const realPrice =
    (parseFloat(node.merchandise.compareAtPriceV2?.amount) === 0 ||
      !node.merchandise.compareAtPriceV2) &&
    parseFloat(node.merchandise.priceV2.amount) > 0
      ? discountPrice
      : price;

  const showDiscountPrice = hasDiscountPrice(
    node.merchandise.compareAtPriceV2?.amount,
    node.merchandise.priceV2.amount,
    node.discountAllocations[0]?.discountedAmount?.amount
  );

  let plan = { planId: null };
  if (typeof window !== 'undefined') {
    const component = window?.Extend?.buttons?.instance(
      '#extend-confirm-offer'
    );
    plan = component?.getPlanSelection();
  }

  return (
    <li
      className={styles.getBaseStyle(false)}
      data-test-id={`${slugify(
        `${node.merchandise.product.title} ${node.merchandise.title}`
      )}-variant-id-${decodeVariantId(node.merchandise.id)}`}
    >
      {node.quantity < 1 && (
        <div className={styles.stockNoticeWrapper}>
          <CartStockNotice
            itemName={`${node.merchandise.product.title} - ${node.merchandise.title}`}
          />
        </div>
      )}
      <div className={styles.baseContainer + extendClass}>
        <div className={styles.imageWrapper}>
          {node.merchandise.image && (
            <Image
              className={styles.productImage}
              src={node.merchandise.image.url}
              id={slugify(
                `cart-${formProductNameAndVariant(
                  node.merchandise.product.title,
                  node.merchandise.title
                )}-image`
              )}
              width={150}
              height={150}
              alt={`Cart Item ${formProductNameAndVariant(
                node.merchandise.product.title,
                node.merchandise.title
              )}`}
            />
          )}
        </div>
        <div className={styles.container}>
          <span className={styles.getItemNameStyle(false)}>
            {`${node.merchandise.product.title} - ${node.merchandise.title}`}
          </span>
        </div>
        <div className={styles.itemPriceRemove}>
          <div className={styles.priceContainer}>
            <span
              className={styles.getItemPriceStyle(showDiscountPrice)}
              data-test-id={slugify(
                `${formProductNameAndVariant(
                  node.merchandise.product.title,
                  node.merchandise.title
                )}-cart-price`
              )}
            >
              {realPrice}
            </span>
            <span
              className={showDiscountPrice ? styles.itemPrice : styles.hidden}
              id={slugify(
                `${formProductNameAndVariant(
                  node.merchandise.product.title,
                  node.merchandise.title
                )}-cart-discount-price`
              )}
            >
              {discountPrice}
            </span>
          </div>
        </div>
      </div>
      {region === MAXIFY_REGION_NORTHERN_AMERICA && (
        <>
          <ExtendConfirmationDrawerOffer
            productInfo={{ shopifyVariantId, price }}
            isPeripheral={isPeripheral}
            setHasExtendPlan={setHasExtendPlan}
          />
          {hasExtendPlan && (
            <Button
              buttonStyle="primary"
              onClick={() => addToCart()}
              disabled={!plan?.planId || loading}
              className="w-full"
              data-test-id="extend-warranty-purchase-button"
            >
              Protect Purchase
            </Button>
          )}
        </>
      )}
    </li>
  );
};

export default memo(CartItem);
