import { FC } from 'react';
import { IconMagnifyingGlass } from '@nzxt/react-icons';
import GTM from '@utils/gtm';
import useUiStore, { getToggleSearch } from '@stores/use-ui-store';
import * as styles from './styles';

const SearchButton: FC = () => {
  const toggleSearch = useUiStore(getToggleSearch);

  const handleClick = (): void => {
    toggleSearch();

    // track search button click via GA
    GTM.dataLayer({
      dataLayer: {
        event: 'searchClick',
      },
    });
  };

  return (
    <div className={styles.container}>
      <button
        type="button"
        className={styles.button}
        onClick={() => handleClick()}
        id="search-button"
        aria-label="Open Search"
      >
        <IconMagnifyingGlass className={styles.searchClass} />
      </button>
    </div>
  );
};

export default SearchButton;
