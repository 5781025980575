/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { memo, FC } from 'react';
import { IconChevronRight, IconChevronLeft } from '@nzxt/react-icons';
import Link from 'next/link';
import * as styles from './styles';

type Props = {
  href?: string;
  title: string;
  isBack?: boolean;
  isExternal?: boolean;
  shortDescription?: string;
  handleClick?: () => void;
  handleLinkClick?: (name: string) => void;
};

const MobileHeaderLink: FC<Props> = ({
  href,
  title,
  handleClick,
  isBack,
  isExternal,
  shortDescription,
  handleLinkClick,
}) => (
  <>
    {href ? (
      <>
        {isExternal ? (
          <a
            href={href}
            className={styles.linkClass}
            target="_blank"
            rel="noopener noreferrer"
            onClick={handleLinkClick ? () => handleLinkClick(title) : null}
            onKeyDown={handleLinkClick ? () => handleLinkClick(title) : null}
            tabIndex={0}
          >
            {title}
          </a>
        ) : (
          <Link
            href={href}
            className={styles.linkClass}
            onClick={handleLinkClick ? () => handleLinkClick(title) : null}
            onKeyDown={handleLinkClick ? () => handleLinkClick(title) : null}
            tabIndex={0}
            role="link"
          >
            {title}
            <span className={styles.description}>{shortDescription}</span>
          </Link>
        )}
      </>
    ) : (
      <button
        type="button"
        className={styles.fullWidthButton}
        onClick={handleClick}
      >
        {isBack && <IconChevronLeft />}
        <p className={styles.buttonLink(isBack)}>{title}</p>
        {!isBack && <IconChevronRight />}
      </button>
    )}
  </>
);

export default memo(MobileHeaderLink);
