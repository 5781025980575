/* eslint-disable jsx-a11y/anchor-is-valid */

import { FC, memo, useState } from 'react';

import type { MarketingBannerProps } from '@framework/api/types';
import MarketingBannerCarousel from './MarketingBannerCarousel';
import MarketingBannerInner from './MarketingBannerInner';

import * as styles from './styles';

type Props = {
  marketingBannerData: MarketingBannerProps[];
};

const MarketingBanner: FC<Props> = ({ marketingBannerData }) => {
  const isMoreThanOne =
    Array.isArray(marketingBannerData) && marketingBannerData?.length > 1;

  const bannerBgColor = marketingBannerData?.map(
    ({ bannerColor }) => bannerColor.hex
  );
  const [activeSlide, setActiveSlide] = useState(0);

  return Array.isArray(marketingBannerData) &&
    marketingBannerData?.length > 0 ? (
    <div
      className={styles.bannerWrapper}
      style={{
        backgroundColor: bannerBgColor[activeSlide] || '#8A00FC',
        maxWidth: '100vw',
      }}
    >
      <div className={styles.outerWrapper}>
        {isMoreThanOne ? (
          <MarketingBannerCarousel
            marketingBannerData={marketingBannerData}
            setActiveSlide={setActiveSlide}
          />
        ) : (
          <>
            {marketingBannerData.map(banner => (
              <MarketingBannerInner
                key={banner.bannerCopy}
                bannerCopy={banner.bannerCopy}
                buttonLinkLabel={banner.buttonLinkLabel}
                internalLink={banner.internalLink}
                isInverted={banner?.isInverted}
                countdownEnd={banner?.countdownEnd}
              />
            ))}
          </>
        )}
      </div>
    </div>
  ) : null;
};

export default memo(MarketingBanner);
