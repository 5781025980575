import { parseCookies } from 'nookies';
import { SYSTEM_AMPLITUDE_COOKIE_NAME } from '@constants';

type GetAmplitudeCookie = () => string | null;

const getAmplitudeCookie: GetAmplitudeCookie = () => {
  const cookies = parseCookies();

  return JSON.parse(cookies[SYSTEM_AMPLITUDE_COOKIE_NAME] || null);
};

export default getAmplitudeCookie;
