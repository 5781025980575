import { classnames } from '@tailwindcss-classnames';

export const base = classnames('h-full', 'flex', 'flex-col', 'relative');

export const linkWrapper = classnames(
  'pt-2',
  'pb-3',
  'space-y-1',
  'flex',
  'flex-col',
  'items-center'
);
export const borderContainer = classnames(
  'pt-4',
  'pb-3',
  'border-t',
  'border-gray-700'
);

export const flexContainer = classnames('flex', 'items-center', 'px-5');

export const shrinkContainer = classnames('flex-shrink-0');

export const userContainer = classnames('space-y-1');

export const userName = classnames('text-base', 'font-medium', 'leading-none');

export const linkContainer = classnames('mt-3', 'px-2', 'space-y-1');

export const link = classnames(
  'block',
  'px-3',
  'py-2',
  'rounded-md',
  'text-base',
  'font-medium',
  'text-nzxt-light-grey-800',
  'hover:text-white',
  'hover:bg-gray-700',
  'focus:outline-none',
  'focus:text-white',
  'focus:bg-gray-700'
);

// login
export const whiteText = classnames('text-white', 'text-base');
export const darkText = classnames('text-nzxt-light-grey-800', 'text-sm');

const defaultLogoutColor = classnames('text-nzxt-volt-400');
const darkLogoutColor = classnames('text-nzxt-volt-200');
export const button = (darkMode: boolean): string =>
  classnames('flex', 'items-center', 'rounded-full', 'focus:outline-none', {
    [whiteText]: darkMode,
    [darkText]: !darkMode,
  });

export const profileSvg = (darkMode: boolean): string =>
  classnames('w-[1em]', 'h-[1em]', 'lg:h-5', 'lg:w-5', 'mr-2', {
    [darkLogoutColor]: darkMode,
    [defaultLogoutColor]: !darkMode,
  });

export const searchBox = classnames(
  'relative',
  'flex',
  'px-4',
  'items-center',
  'border-b',
  'py-2',
  'mb-4',
  'pl-1',
  'text-sm',
  'shadow-none',
  'w-full'
);

export const profileLinkListItem = classnames('w-full');

const darkModeLink = classnames('text-base', 'text-white');

export const profileLink = (darkMode?: boolean): string =>
  classnames('w-full', 'justify-start', 'flex', 'text-sm', 'block', 'py-2', {
    [darkModeLink]: darkMode,
  });

export const profileLinkLogout = (darkMode?: boolean): string =>
  classnames(
    'w-full',
    'justify-start',
    'flex',
    'text-sm',
    'block',
    'py-2',
    'hover:text-nzxt-volt-400',
    {
      'text-base': darkMode,
      [darkLogoutColor]: darkMode,
      [defaultLogoutColor]: !darkMode,
    }
  );

export const profileLinkWrapper = classnames(
  'py-4',
  'border-t',
  'px-4',
  'flex'
);

export const utilityLink = classnames('py-4', 'border-t', 'px-4', 'flex');

export const scrollContainer = classnames(
  'h-full',
  'relative',
  'overflow-hidden'
);
export const scrollContainerChild = classnames(
  'absolute',
  'top-0',
  'overflow-y-auto',
  'w-full'
);
export const shrink = classnames(
  'flex-shrink-0',
  'ml-0',
  'flex',
  'items-center',
  'px-4',
  'py-1'
);
export const mobileLogo = classnames(
  'w-16',
  'h-4',
  'ml-1',
  'block',
  'lg:hidden'
);
