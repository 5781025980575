import * as Sentry from '@sentry/nextjs';
import ImgixClient from '@imgix/js-core';
import isSvgImageUrl from '@utils/image/isSvgImageUrl';
import { ImgixParams, BuildUrlOptions, UrlType } from '@utils/imgix/types';

const getHostnameFromUrl = (url: string): string => {
  const parsedUrl = new URL(url);
  return parsedUrl.hostname;
};

const getPathnameFromUrl = (url: string): string => {
  const parsedUrl = new URL(url);
  return parsedUrl.pathname;
};

const buildImgixUrl = (
  imageUrl: string,
  imgixParams: ImgixParams,
  urlType: UrlType = 'srcset',
  options: BuildUrlOptions = {}
): string => {
  if (!imageUrl || !imgixParams) {
    Sentry.captureException(new Error('Missing required parameters'));
  }

  const trimmedUrl = getPathnameFromUrl(imageUrl);
  if (!trimmedUrl) {
    Sentry.captureException(new Error('Invalid URL'));
  }

  if (isSvgImageUrl(imageUrl)) {
    return imageUrl;
  }

  const {
    imgixDomain = getHostnameFromUrl(process.env.NEXT_PUBLIC_CMS_ASSETS_URL),
    srcSetOptions,
  } = options;
  const client = new ImgixClient({ domain: imgixDomain });

  if (urlType === 'srcset') {
    return client.buildSrcSet(trimmedUrl, imgixParams, srcSetOptions);
  }

  return client.buildURL(trimmedUrl, imgixParams);
};

export default buildImgixUrl;
