import { classnames } from '@tailwindcss-classnames';

export const contentContainer = classnames(
  'py-4',
  'flex-1',
  'px-4',
  'flex',
  'flex-col',
  'items-center',
  'text-nzxt-light-grey-800'
);

export const iconWrapper = classnames(
  'border',
  'border-white',
  'rounded-full',
  'flex',
  'items-center',
  'justify-center',
  'w-16',
  'h-16'
);

export const secondaryText = classnames(
  'pt-6',
  'text-xl',
  'font-light',
  'text-center'
);
