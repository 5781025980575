import { classnames } from '@tailwindcss-classnames';

export const itemsUl = classnames(
  'space-y-4',
  'sm:py-0',
  'sm:space-y-0',
  'divide-y',
  'divide-gray-300',
  'pb-20',
  'lg:pb-10'
);

export default itemsUl;
