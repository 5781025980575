/* eslint-disable jsx-a11y/anchor-is-valid */
import { memo, FC, useState, useEffect } from 'react';
import { useRouter } from 'next/router';
import { Experiment } from '@amplitude/experiment-js-client';
import Link from 'next/link';
import { ROUTE_COLLECTION, ROUTE_PRODUCT } from '@constants';
import slugify from '@utils/slugify';
import { useMedia } from '@utils/hooks';
import type { NavCollection } from '@framework/api/types';
import { ResponsiveImage } from '@components/Imgix';
import HeaderLink from '../HeaderLink';
import * as styles from './styles';

type Props = {
  title: string;
  href?: string;
  navIcon?: string;
  collections?: NavCollection[];
  goByThree?: boolean;
  isActive?: boolean;
  handleLinkClick: (name: string) => void;
  useCatImg?: boolean;
};

const amplitudeId = process.env.NEXT_PUBLIC_AMPLITUDE_ID;

const HeaderSubmenuLinks: FC<Props> = ({
  title,
  href,
  navIcon,
  collections,
  goByThree,
  handleLinkClick,
  isActive,
  useCatImg,
}) => {
  const router = useRouter();
  const { asPath } = router;

  const isSm = useMedia('sm');
  const isMd = useMedia('md');
  const isLg = !isSm && !isMd;

  const [experimentVariant, setExperimentVariant] = useState(null);

  useEffect(() => {
    const initExperiment = async (): Promise<void> => {
      const experiment =
        Experiment.initializeWithAmplitudeAnalytics(amplitudeId);

      await experiment.start();

      const variant = experiment.variant('player-pc-links');

      if (variant && variant.value) {
        setExperimentVariant(variant.value);
      }
    };

    if (amplitudeId) {
      initExperiment();
    }
  }, [asPath]);

  const abProdLink = experimentVariant === 'treatment';
  const PROD_LINK_SLUGS = ['player-one', 'player-two', 'player-three'];

  return (
    <>
      <HeaderLink
        title={title}
        href={href}
        navIcon={navIcon}
        goByThree={goByThree}
        handleLinkClick={handleLinkClick}
        showLink={isActive}
      />

      {collections && collections.length > 0 && (
        <ul className="space-y-3">
          {collections?.map((collection, i) => (
            <li key={collection.slug}>
              <Link
                href={
                  abProdLink && PROD_LINK_SLUGS.includes(collection.slug)
                    ? `/${ROUTE_PRODUCT}/${collection.slug}`
                    : `/${ROUTE_COLLECTION}/${collection.slug}`
                }
                // eslint-disable-next-line react/no-array-index-key
                key={`${collection.name}-${i}`}
                className={styles.linkItem(goByThree)}
                id={`nav-link-${slugify(collection.name)}-${
                  isLg ? 'lg' : 'sm'
                }`}
                data-test-id={`nav-link-${slugify(collection.name)}`}
                onClick={() => handleLinkClick(collection.name)}
                onKeyDown={() => handleLinkClick(collection.name)}
                role="link"
                tabIndex={0}
              >
                {useCatImg && collection?.seo?.image?.responsiveImage?.src && (
                  <span className={styles.collectionImage}>
                    <ResponsiveImage
                      imageUrl={collection.seo.image.responsiveImage.src}
                      imgixParams={{ w: 53, h: 53, auto: 'format' }}
                      height={53}
                      width={53}
                      urlType="srcset"
                      alt={`Learn more about ${collection.name}`}
                    />
                  </span>
                )}
                <span className={styles.copyContainer}>
                  {collection.name}
                  {collection.shortDescription && (
                    <span className={styles.description}>
                      {collection.shortDescription}
                    </span>
                  )}
                </span>
              </Link>
            </li>
          ))}
        </ul>
      )}
    </>
  );
};

export default memo(HeaderSubmenuLinks);
