import { classnames } from '@tailwindcss-classnames';

const textWhite = classnames('text-white');

export const loading = (isInverted: boolean): string =>
  classnames(
    'w-10/12',
    'h-auto',
    'flex',
    'items-center',
    'text-center',
    'justify-center',
    'p-3',
    {
      [textWhite]: isInverted,
    }
  );
