import { classnames } from '@tailwindcss-classnames';

export const supportSvg = classnames(
  'h-[1em]',
  'lg:h-6',
  'w-[1em]',
  'lg:w-6',
  'mr-2',
  'lg:mr-0'
);

export const whiteText = classnames('text-white', 'text-base');
export const darkText = classnames('text-sm');

export const linkStyle = (darkMode: boolean): string =>
  classnames('flex', 'items-center', 'focus:outline-white', {
    [whiteText]: darkMode,
    [darkText]: !darkMode,
  });

export const label = classnames('lg:hidden', 'lg:ml-0');
