import { classnames } from '@tailwindcss-classnames';

const container = classnames('w-full', 'absolute');
const hiddenLarge = classnames('lg:hidden');
const hasMarketingBanner = classnames(
  '-top-smallNav',
  'md:-top-mdNav',
  'lg:-top-lgNav'
);

const hasMarketingBannerInner = classnames('top-[47px]');
const hasNoMarketingBannerInner = classnames('top-0');

const hasMarketingBannerAndSub = classnames(
  'top-[-98px]',
  'md:top-[-102px]',
  'lg:-top-lgNav'
);

const hasSub = classnames('top-[-45px]', 'lg:-top-lgNav');

export const getContainerClass = (
  displayMarketingBanner: boolean,
  withSubNav: boolean,
  isMobileOnly = false
): string =>
  classnames(container, {
    [hiddenLarge]: isMobileOnly,
    [hasMarketingBanner]: displayMarketingBanner && !withSubNav,
    [hasMarketingBannerAndSub]: displayMarketingBanner && withSubNav,
    [hasSub]: withSubNav && !displayMarketingBanner,
  });

const baseColor = classnames('bg-white');
const invertedColor = classnames('bg-nzxt-dark-grey-800');

const baseClasses = classnames(
  'z-10',
  'fixed',
  'translate-y-0',
  'lg:border-0',
  'overflow-y-auto'
);

const activeClass = classnames('visible');
const inactive = classnames('invisible');

const fullHeight = classnames('z-50');
const leftClass = classnames('left-0');
const rightClass = classnames('right-0');
const innerWidth = classnames('w-full', 'border-0', 'top-0');
const fullWidth = classnames('w-full', 'md:w-1/2', 'lg:w-1/3', '2xl:w-1/4');

const defaultStyles = classnames(
  'w-3/4',
  'md:w-1/2',
  'lg:w-1/3',
  '2xl:w-1/4',
  'lg:mt-2'
);

export const relative = classnames('relative');

export const getMobileOpenClass = (
  isOpen: boolean,
  displayMarketingBanner: boolean,
  isRight = false,
  isInnerItem = false,
  isFullHeight = false,
  isFullWidth = false,
  isInverted = false
): string =>
  classnames(baseClasses, {
    [fullHeight]: isFullHeight && !isInnerItem,
    [defaultStyles]: !isInnerItem && !isFullHeight && !isFullWidth,
    [fullWidth]: isFullWidth,
    [innerWidth]: isInnerItem,
    [activeClass]: isOpen,
    [inactive]: !isOpen,
    [leftClass]: !isRight,
    [rightClass]: isRight,
    [baseColor]: !isInverted,
    [invertedColor]: isInverted,
    [hasMarketingBannerInner]: !isInnerItem && displayMarketingBanner,
    [hasNoMarketingBannerInner]: !isInnerItem && !displayMarketingBanner,
  });
