/* eslint-disable no-nested-ternary */
import { FC, useEffect, useMemo, useRef, memo } from 'react';
import { useRouter } from 'next/router';
import { MAXIFY_REGION_NORTHERN_AMERICA } from '@constants';
import { getRegion } from '@framework/api/utils/maxify';
import useCartStore, { getLastProduct } from '@stores/use-cart-store';
import type { ShopifyLineEdges, ShopifyCart } from '@framework/api/cart';
import ExtendHelper from '@components/Extend/ExtendHelper';
// import encodeVariantId from '@utils/encode-variant-id';
import CartConfirmationItem from './CartConfirmationItem';
import EmptyView from '../EmptyView';

const isRefurbished = (item: ShopifyLineEdges): boolean =>
  item.node.merchandise.product.title
    .toLowerCase()
    .includes(
      'refurbish' || 'renoviert' || 'remis' || 'ristruttur' || 'gerenoveerd'
    );

type Props = {
  data: ShopifyCart;
  isSideBarCart?: boolean;
  setItemContainerHeight?: (height: number) => void;
  handleClose: () => void;
};

const CartItemsSection: FC<Props> = ({
  data,
  isSideBarCart = false,
  setItemContainerHeight,
  handleClose,
}) => {
  const router = useRouter();
  const { locale } = router;
  const region = getRegion(locale);
  const itemContainer = useRef<HTMLDivElement>();

  const rawItems = useMemo(() => data?.lines.edges ?? [], [data]);

  const noBuildItems = useMemo(
    () =>
      rawItems.filter(
        i =>
          !i?.node?.attributes?.some(a => a.key === '_build_number') &&
          !i?.node?.merchandise?.title?.includes('Extend Protection Plan')
      ),
    [rawItems]
  );

  const warrItems = useMemo(
    () =>
      rawItems.filter(
        i =>
          i?.node?.merchandise?.product?.title?.indexOf(
            'Extend Protection Plan'
          ) > -1
      ),
    [rawItems]
  );

  const items = useMemo(
    () => [...noBuildItems, ...warrItems],
    [noBuildItems, warrItems]
  );

  useEffect(() => {
    if (itemContainer?.current && setItemContainerHeight) {
      setItemContainerHeight(
        itemContainer.current?.getBoundingClientRect().height
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itemContainer, data]);

  const lastItemId = useCartStore(getLastProduct);
  // const lastItem = encodeVariantId(`${lastItemId?.lastProduct}`);
  const lastItem = `${lastItemId?.lastProduct}`;

  const confirmationProduct = items.find(
    itm => itm?.node?.merchandise?.id.split('/ProductVariant/')[1] === lastItem
  );

  return confirmationProduct?.node?.id ? (
    <div ref={itemContainer}>
      <ul>
        {region === MAXIFY_REGION_NORTHERN_AMERICA && (
          <ExtendHelper items={items} />
        )}

        <CartConfirmationItem
          key={confirmationProduct.node.id}
          item={confirmationProduct}
          isRefurbished={isRefurbished(confirmationProduct)}
          isSideBarCart={isSideBarCart}
          shopifyVariantId={lastItemId?.lastProduct}
        />
      </ul>
    </div>
  ) : (
    <EmptyView region={region} handleClose={handleClose} />
  );
};

export default memo(CartItemsSection);
