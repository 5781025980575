/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, memo, useEffect } from 'react';
import Link from 'next/link';
import { IconX } from '@nzxt/react-icons';
import { useTranslation as t } from '@utils/hooks';
import trackError from '@utils/amplitude/track/error';
import { useHistory } from '@utils/history';
import type { ShopifyLineEdges } from '@framework/api/cart';
import useUiStore, { getRemoveCartStockNotice } from '@stores/use-ui-store';
import * as styles from './styles';

type Props = {
  noticeItem: {
    buildName: string;
    buildLink: string;
    soldOutItems?: ShopifyLineEdges[];
  };
};

const CartBuildStockNotice: FC<Props> = ({ noticeItem }) => {
  const { last: lastPage } = useHistory();

  const removeCartStockNotice = useUiStore(getRemoveCartStockNotice);

  const BUILD_ITEM_OOS_MSG_BEFORE = t('cart_message_build_item_oos_before');
  const BUILD_ITEM_OOS_MSG_AFTER = t('cart_message_build_item_oos_after');
  const BUILD_ITEM_OOS_MSG_V2 = t('cart_message_build_oos_v2');
  const CLOSE = t('close');

  const { buildName, buildLink, soldOutItems } = noticeItem;

  const handleRemove = (): void => {
    removeCartStockNotice(noticeItem);
  };

  useEffect(() => {
    trackError({
      errorType: 'cart',
      errorName: 'Product OOS',
      referrer: lastPage,
    });
  }, [lastPage]);

  const soldOutItemNames =
    soldOutItems?.map(itm => itm?.node?.merchandise?.product?.title) || [];

  return (
    <div className={styles.stockNoticeWrapper}>
      <p className={styles.stockNotice}>
        {soldOutItemNames?.length > 0 ? (
          <Link
            href={buildLink}
            onClick={handleRemove}
            onKeyDown={handleRemove}
            role="link"
            tabIndex={0}
          >
            <div>
              <dl className={styles.detailList}>
                <dt>{BUILD_ITEM_OOS_MSG_V2}</dt>
                {soldOutItemNames.map(productTitle => (
                  <dd>
                    <span aria-hidden="true" className={styles.dash}>
                      -
                    </span>
                    {productTitle}
                  </dd>
                ))}
              </dl>
            </div>
          </Link>
        ) : (
          <Link
            href={buildLink}
            onClick={handleRemove}
            onKeyDown={handleRemove}
            role="link"
            tabIndex={0}
          >
            {BUILD_ITEM_OOS_MSG_BEFORE} {buildName} {BUILD_ITEM_OOS_MSG_AFTER}
          </Link>
        )}
      </p>
      <IconX
        width={24}
        height={24}
        className={styles.icon}
        onClick={handleRemove}
        aria-label={CLOSE}
      />
    </div>
  );
};

export default memo(CartBuildStockNotice);
