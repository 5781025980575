import { FC, useState, memo } from 'react';
import { useRouter } from 'next/router';
import Image from 'next/legacy/image';
import { IconTrash } from '@nzxt/react-icons';
import GTM from '@utils/gtm';
import usePrice from '@framework/use-price';
import { getRegion } from '@framework/api/utils/maxify';
import useRemoveItem from '@framework/cart/use-remove-item';
import type { ShopifyLineEdges } from '@framework/api/cart';
import slugify from '@utils/slugify';
import hasDiscountPrice from '@utils/has-discount-price';
import decodeVariantId from '@utils/decode-variant-id';
import {
  MAXIFY_REGION_NORTHERN_AMERICA,
  ROUTE_CART,
  PREBUILD_PARENT_CATS,
} from '@constants';
import YotpoWidget from '@components/YotpoReviews/YotpoWidget';
import CartStockNotice from './CartItemStockNotice';
import * as styles from './styles';
import ExtendCartOffer from '../../Extend/ExtendCartOffer';

export type ItemProps = {
  item: ShopifyLineEdges;
};

const Item: FC<ItemProps> = ({ item }) => {
  const router = useRouter();
  const { locale, pathname } = router;
  const region = getRegion(locale);
  let isPeripheral = false;
  const isCartPage = pathname.includes(ROUTE_CART);

  const { node } = item;

  const extractProductNumber = (str: string): number | null => {
    const match = str.match(/\/Product\/(\d+)/);
    if (match) {
      const productNumber = parseInt(match[1], 10);
      return Number.isNaN(productNumber) ? null : productNumber;
    }
    return null;
  };

  const itemParentId = extractProductNumber(node.merchandise.product.id);

  const { price } = usePrice({
    amount: parseFloat(node.merchandise.compareAtPriceV2?.amount),
    currencyCode: node.merchandise.compareAtPriceV2?.currencyCode,
  });

  const nodeQuantity = node.quantity === 0 ? 1 : node.quantity;
  const { price: discountPrice } = usePrice({
    amount:
      parseFloat(node.merchandise.priceV2.amount) -
      parseFloat(node.discountAllocations[0]?.discountedAmount?.amount ?? '0') /
        nodeQuantity,
    currencyCode: node.merchandise.priceV2.currencyCode,
  });

  const realPrice =
    (parseFloat(node.merchandise.compareAtPriceV2?.amount) === 0 ||
      !node.merchandise.compareAtPriceV2) &&
    parseFloat(node.merchandise.priceV2.amount) > 0
      ? discountPrice
      : price;

  const showDiscountPrice = hasDiscountPrice(
    node.merchandise.compareAtPriceV2?.amount,
    node.merchandise.priceV2.amount,
    node.discountAllocations[0]?.discountedAmount?.amount
  );

  const protectedPeripherals = ['keyboard', 'mouse', 'monitors', 'peripherals'];

  // Extend - Determines if product is a warrantable peripheral
  if (node?.merchandise.product.productType) {
    if (
      protectedPeripherals.includes(
        node?.merchandise.product.productType.toLowerCase()
      )
    ) {
      isPeripheral = true;
    }
  }
  // Extend - End code

  const removeItem = useRemoveItem();

  const [removing, setRemoving] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleRemove = async (): Promise<void> => {
    setRemoving(true);
    setLoading(true);

    try {
      await removeItem({ id: node.id });
      setLoading(false);

      GTM.dataLayer({
        dataLayer: {
          event: 'removeFromCart',
          ecommerce: {
            remove: {
              products: [
                {
                  name: node.merchandise.product.title,
                  id: parseInt(node.merchandise.id, 10),
                  price: node.merchandise.priceV2.amount.toString(),
                  variant: node.merchandise.title,
                  quantity: node.quantity,
                },
              ],
            },
          },
        },
      });

      if (node.merchandise.product.title.includes('Extend Protection Plan')) {
        // track remove from cart for extended warranty
        GTM.dataLayer({
          dataLayer: {
            event: 'warrantyDelete',
            warrantyLength: node.merchandise.title,
            warrantyPrice: node.merchandise.priceV2.amount,
          },
        });
      }
    } catch (error) {
      setRemoving(false);
      setLoading(false);
    }
  };

  const formProductNameAndVariant = (
    productName: string,
    productVariant: string
  ): string =>
    `${productName || 'name'.toLowerCase().replace(' ', '-')}${
      productVariant && '-'
    }${productVariant && productVariant.toLowerCase().replace(' ', '-')}`;

  let isExtend;

  if (node.merchandise.product.title.indexOf('Extend Protection Plan') > -1) {
    isExtend = true;
  }

  const extendClass = isExtend ? ' extend-item' : '';

  const regex = /(\w+)(:.*?)(?=\s+\w+:|$)/g;
  const formattedDescriptionArr = Array.from(
    node?.merchandise?.product?.description?.matchAll(regex),
    m => `${m[1]}${m[2]}`
  );

  return (
    <div
      className={styles.getBaseStyleHybrid(removing)}
      data-test-id={`${slugify(
        `${node.merchandise.product.title} ${node.merchandise.title}`
      )}-variant-id-${decodeVariantId(node.merchandise.id)}`}
    >
      {node.quantity < 1 && (
        <div className={styles.stockNoticeWrapper}>
          <CartStockNotice
            itemName={`${node.merchandise.product.title} - ${node.merchandise.title}`}
          />
        </div>
      )}
      <div className={styles.containerCol}>
        <div className={styles.baseContainer + extendClass}>
          <div className={styles.hybridImageWrapper}>
            {node.merchandise.image && (
              <Image
                className={styles.productImage}
                src={node.merchandise.image.url}
                id={slugify(
                  `cart-${formProductNameAndVariant(
                    node.merchandise.product.title,
                    node.merchandise.title
                  )}-image`
                )}
                width={150}
                height={150}
                alt={`Cart Item ${formProductNameAndVariant(
                  node.merchandise.product.title,
                  node.merchandise.title
                )}`}
              />
            )}
          </div>
          <div className={styles.container}>
            <span
              className={styles.getHybridItemNameStyle(false)}
              data-test-id="cart-item-title"
            >
              {`${node.merchandise.product.title} - ${node.merchandise.title}${
                node.quantity > 1 ? ` (${node.quantity})` : ''
              }`}
            </span>
            <YotpoWidget
              type="star-rating"
              id={itemParentId}
              template="collection"
            />
          </div>
          <div className={styles.itemPriceRemove}>
            <button
              type="button"
              className={styles.removeButton}
              onClick={handleRemove}
              data-test-id={slugify(
                `${formProductNameAndVariant(
                  node.merchandise.product.title,
                  node.merchandise.title
                )}-remove-cart`
              )}
              disabled={loading}
              aria-label="Remove From Cart"
            >
              <IconTrash className={styles.removeButtonIcon} />
            </button>
            <div className={styles.priceContainer}>
              <span
                className={styles.getItemPriceStyle(showDiscountPrice)}
                data-test-id={slugify(
                  `${formProductNameAndVariant(
                    node.merchandise.product.title,
                    node.merchandise.title
                  )}-cart-price`
                )}
              >
                {realPrice}
              </span>
              <span
                className={showDiscountPrice ? styles.itemPrice : styles.hidden}
                id={slugify(
                  `${formProductNameAndVariant(
                    node.merchandise.product.title,
                    node.merchandise.title
                  )}-cart-discount-price`
                )}
              >
                {discountPrice}
              </span>
            </div>
          </div>
        </div>
        {PREBUILD_PARENT_CATS.includes(
          node?.merchandise?.product?.productType
        ) && formattedDescriptionArr?.length > 0 ? (
          <div className={styles.descrptionWrapper}>
            <ul>
              {formattedDescriptionArr.map(d => (
                <li key={`${node.id}-${d}`}>{d}</li>
              ))}
            </ul>
          </div>
        ) : null}
      </div>
      {region === MAXIFY_REGION_NORTHERN_AMERICA && isCartPage ? (
        <ExtendCartOffer
          item={item}
          price={price}
          isPeripheral={isPeripheral}
          isFullPageCart
        />
      ) : (
        <ExtendCartOffer
          item={item}
          price={price}
          isPeripheral={isPeripheral}
        />
      )}
    </div>
  );
};

export default memo(Item);
