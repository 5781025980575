import { classnames } from '@tailwindcss-classnames';

const isRemoving = classnames('opacity-75', 'pointer-events-none');
const isNotRemoving = classnames('opacity-100', 'pointer-events-auto');
export const getBaseStyle = (removing?: boolean): string =>
  classnames('py-6', {
    [isRemoving]: removing,
    [isNotRemoving]: removing,
  });

// stock notice container

export const stockNoticeWrapper = classnames(
  'flex',
  'justify-center',
  'pb-2',
  'px-2'
);

// product container
export const baseContainer = classnames(
  'flex',
  'flex-row',
  'space-x-4',
  'lg:space-x-8'
);

// product image
export const imageWrapper = classnames(
  'w-16',
  'h-16',
  'relative',
  'overflow-hidden'
);
export const productImage = classnames(
  'absolute',
  'w-1',
  'h-1',
  'left-24',
  'top-24'
);

// product content
export const container = classnames('flex-1', 'flex', 'flex-col', 'text-base');

// product name

export const itemName = classnames(
  'font-bold',
  'mb-5',
  'text-sm',
  'md:text-base',
  'lg:text-lg',
  'text-nzxt-light-grey-800'
);

const pointer = classnames('cursor-pointer');

export const getItemNameStyle = (isLink?: boolean): string =>
  classnames(itemName, 'text-left', {
    [pointer]: isLink,
  });

// product price
export const hidden = classnames('hidden'); // applies when something isnt discounted
export const itemPriceRemove = classnames(
  'flex',
  'flex-col',
  'justify-between',
  'space-y-2',
  'text-base'
);
export const priceContainer = classnames('flex', 'flex-col');

const regularPrice = classnames('line-through', 'opacity-50');
export const itemPrice = classnames('text-sm', 'text-nzxt-light-grey-600');
export const getItemPriceStyle = (showDiscountPrice: boolean): string =>
  classnames(itemPrice, {
    [regularPrice]: showDiscountPrice,
  });
