import create from 'zustand';
import { devtools } from 'zustand/middleware';
import type { SetState } from 'zustand';
import type { Geo } from '@utils/geoip-cookie';
import type { State } from './types';

const initialState = {
  countryCode: null,
  continentCode: null,
  setGeo: () => null,
};

const geoStore = (set: SetState<State>): State => ({
  ...initialState,
  // GeoIP Actions
  setGeo: (geo: Geo) =>
    set(() => ({
      countryCode: geo.countryCode,
      continentCode: geo.continentCode,
    })),
});

const useGeoStore =
  process.env.NODE_ENV === 'development'
    ? create<State>(devtools(geoStore))
    : create<State>(geoStore);

export default useGeoStore;
