/* eslint-disable no-param-reassign */
/* eslint-disable no-return-assign */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable func-names */
/* eslint-disable max-len */
/* eslint-disable prefer-template */
/* eslint-disable object-shorthand */
/* eslint-disable dot-notation */
/* eslint-disable consistent-return */
import { FC, useEffect, useRef, MutableRefObject, useState } from 'react';

// Brings in the 'Variant' object type to be used as a prop
type Props = {
  productInfo: { shopifyVariantId: number; price: string };
  isPeripheral?: boolean;
  setHasExtendPlan?: (x: boolean) => void;
};

// Component that handles all Extend offer functionality on the PDP
const ExtendConfirmationDrawerOffer: FC<Props> = ({
  productInfo,
  isPeripheral,
  setHasExtendPlan,
}) => {
  // -- Hooks --
  const prodVariant = useRef(productInfo.shopifyVariantId);
  const [extendLoaded, setExtendLoaded] = useState(false);
  const extendRef = useRef() as MutableRefObject<HTMLDivElement>;
  let bandRefId;
  const total =
    productInfo?.price?.replace(/[^\d.,]+/g, '') ?? productInfo?.price;

  // -- Util Functions --

  // Styles the offer iframe to remove the Extend logo and reword the caption text
  function styleOffer(callback) {
    if (document.querySelector('#extend-confirm-offer div iframe')) {
      setHasExtendPlan(true);

      const iFrameDocument = (
        document.querySelector(
          '#extend-confirm-offer div iframe'
        ) as HTMLIFrameElement
      ).contentDocument;
      if (iFrameDocument.body.querySelector('.info-button')) {
        (
          iFrameDocument.body.querySelector('.info-button') as HTMLDivElement
        ).style.color = '#0644CC';
      }
      if (iFrameDocument.body.querySelector('.caption-text')) {
        (
          iFrameDocument.body.querySelector('.caption-text') as HTMLDivElement
        ).innerText = 'Add an extended warranty';
        (
          iFrameDocument.body.querySelector('.caption-text') as HTMLDivElement
        ).style.marginRight = '4px';
      }
      if (iFrameDocument.body.querySelector('.logo')) {
        (iFrameDocument.body.querySelector('.logo') as HTMLDivElement).remove();
      }

      callback();
    }
  }

  // Sets the offer to display block and resizes to ensure iframe is fully rendered
  function displayUpdatedStyles() {
    (
      document.querySelector('#extend-confirm-offer') as HTMLDivElement
    ).style.display = 'block';
    window.dispatchEvent(new Event('resize'));
  }

  // getPriceBand - Rounds price up to the nearest mutiple of 25 or  multiple 100 if number is above 100
  function getPriceBand() {
    if (!total) {
      return;
    }
    // Rounds up in increments of 100 if price is over 999, otherwise it will round in increments of 25
    const bandPrice =
      Number(total) > 999
        ? Math.ceil(Number(total) / 100) * 100
        : Math.ceil(Number(total) / 25) * 25;
    return bandPrice;
  }

  // getPriceBandRefId - Returns the priceBand variantId
  function getPriceBandRefId(planId) {
    // Grabs planId and returns string between the dashes ( e.g. '1B0-VEAPS-3y' => 'BVEAPSy')
    const planName = isPeripheral
      ? planId.replace(/[-By]/g, '').replace(/[0-9]/g, '')
      : planId.replace(/-/g, '').replace(/[0-9]/g, '');

    // Encodes planName from letters to numbers and adds '111' to beginning of string (e.g. BVEAPSy => 22511619 => 22251161925)
    const planKey = isPeripheral
      ? '111' +
        planName
          .split('')
          .map(str => str.toLowerCase().charCodeAt(0) - 96)
          .join('')
      : planName
          .split('')
          .map(str => str.toLowerCase().charCodeAt(0) - 96)
          .join('');

    const priceBandPrice = getPriceBand();

    // Concatenates planKey and priceBandPrice (e.g. 22511619 + 75 => 2251161975)
    if (planKey && priceBandPrice) {
      return planKey + priceBandPrice;
    }
  }

  // Initial page load offer render
  function initialOfferRender(callback) {
    if (isPeripheral) {
      bandRefId = getPriceBandRefId('B0-NZXT-1y');

      window.Extend.buttons.render(extendRef?.current, {
        referenceId: bandRefId,
      });

      window.setTimeout(function () {
        callback(displayUpdatedStyles);
      }, 800);
    } else {
      window.Extend.buttons.render(extendRef?.current, {
        referenceId: prodVariant.current,
      });

      window.setTimeout(function () {
        callback(displayUpdatedStyles);
      }, 800);
    }
  }

  const extendFunctionality = () => {
    setExtendLoaded(true);

    if (
      window.Extend &&
      extendRef?.current &&
      !extendRef?.current.querySelector('div')
    ) {
      initialOfferRender(styleOffer);
    }
  };

  useEffect(() => {
    window.addEventListener('ExtendLoaded', extendFunctionality);

    // script appears to be looking for document ready/document load
    // so we need to force the render on clientside navigation
    if (
      typeof window !== 'undefined' &&
      !extendLoaded &&
      Boolean(window.Extend)
    ) {
      extendFunctionality();
    }

    return () =>
      window.removeEventListener('ExtendLoaded', extendFunctionality);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <div
        ref={extendRef}
        id="extend-confirm-offer"
        style={{ width: '100%', display: 'none' }}
        data-test-id="confirmation-drawer-extend-warranty-block"
      />
    </div>
  );
};

export default ExtendConfirmationDrawerOffer;
