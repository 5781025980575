import { FC, memo } from 'react';
import { useTranslation as t } from '@utils/hooks';
import Button from '@components/Button';
import ButtonLink from '@components/ButtonLink';
import { ROUTE_BUILD } from '@constants';
import * as styles from './styles';

type Props = {
  region: string;
  handleClose: () => void;
  isSmall?: boolean;
};

const Empty: FC<Props> = ({ handleClose, isSmall = false }) => {
  // const LABEL_FREE_SHIPPING = t('cart_free_shipping_cta');
  // const LABEL_FREE_SHIPPING_EXCLUSION = t('cart_free_shipping_exclusion');
  // const LABEL_FREE_SHIPPING_PROMO = t('cart_free_shipping_promo_cta');
  const top = isSmall ? { top: '85px' } : {};
  return (
    <div className={styles.contentContainer} style={{ ...top }}>
      <p className={styles.emptyHeading}>{t('cart_heading_empty')}</p>
      <p>{t('cart_subheading_empty')}</p>
      <div className={styles.emptyButtonContainer}>
        <Button
          buttonStyle="secondary"
          href="/"
          onClick={handleClose}
          type="button"
          customMargin
        >
          {t('keep_browsing')}
        </Button>
        <ButtonLink
          asButton
          buttonStyle="secondary"
          href={`/${ROUTE_BUILD}`}
          internalLink
          customMargin
          dark
        >
          {t('create_build')}
        </ButtonLink>
      </div>
      {/* commenting this out temporarily per Lindsey's request */}
      {/* {region === MAXIFY_REGION_NORTHERN_AMERICA && (
        <div className={styles.shippingCtaContainer}>
          {process.env.NEXT_PUBLIC_FREE_SHIP === 'true' ? (
            <p className={styles.shippingCta(true)}>
              {LABEL_FREE_SHIPPING_PROMO}
            </p>
          ) : (
            <>
              <p className={styles.shippingCta(true)}>{LABEL_FREE_SHIPPING}</p>
              <p className={styles.shippingCta(false)}>
                {LABEL_FREE_SHIPPING_EXCLUSION}
              </p>
            </>
          )}
        </div>
      )} */}
    </div>
  );
};

export default memo(Empty);
