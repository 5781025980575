import {
  CONTENT_TYPE_CATEGORY,
  CONTENT_TYPE_COLLECTION,
  CONTENT_TYPE_PRODUCT,
  CONTENT_TYPE_PAGE,
  CONTENT_TYPE_NEWS,
  CONTENT_TYPE_NEWS_CATEGORY,
  CONTENT_TYPE_LEGAL,
  CONTENT_TYPE_LEGAL_HUB,
  CONTENT_TYPE_NOTICE,
  CONTENT_TYPE_SUBSCRIPTION,
  PAGE_TYPE_ABOUT,
  PAGE_TYPE_SOFTWARE,
  PAGE_TYPE_RETAILERS,
  PAGE_TYPE_BUILD,
  PAGE_TYPE_SUPPORT,
  PAGE_TYPE_USER,
  PAGE_TYPE_NEWS,
  PAGE_TYPE_CAREERS,
  PAGE_TYPE_ROOT,
  PAGE_TYPE_HOME,
  PAGE_TYPE_REVIEWS,
  PAGE_TYPE_REFURBISHED,
  PAGE_TYPE_COLLECTION,
  PAGE_TYPE_PARTNER,
  PAGE_TYPE_SALE,
  PAGE_TYPE_LOCKED_BUILD,
  CONTENT_TYPE_ROUTE_MAP,
} from '@constants';

type Args = {
  contentType:
    | typeof CONTENT_TYPE_CATEGORY
    | typeof CONTENT_TYPE_COLLECTION
    | typeof CONTENT_TYPE_NEWS
    | typeof CONTENT_TYPE_PRODUCT
    | typeof CONTENT_TYPE_PAGE
    | typeof CONTENT_TYPE_LEGAL
    | typeof CONTENT_TYPE_LEGAL_HUB
    | typeof CONTENT_TYPE_NEWS_CATEGORY
    | typeof CONTENT_TYPE_NOTICE
    | typeof CONTENT_TYPE_SUBSCRIPTION
    | 'external_link'
    | string;
  pageType?:
    | typeof CONTENT_TYPE_LEGAL
    | typeof PAGE_TYPE_ABOUT
    | typeof PAGE_TYPE_SOFTWARE
    | typeof PAGE_TYPE_RETAILERS
    | typeof PAGE_TYPE_SUPPORT
    | typeof PAGE_TYPE_USER
    | typeof PAGE_TYPE_BUILD
    | typeof PAGE_TYPE_NEWS
    | typeof PAGE_TYPE_CAREERS
    | typeof PAGE_TYPE_ROOT
    | typeof PAGE_TYPE_HOME
    | typeof PAGE_TYPE_REVIEWS
    | typeof PAGE_TYPE_REFURBISHED
    | typeof PAGE_TYPE_COLLECTION
    | typeof PAGE_TYPE_PARTNER
    | typeof PAGE_TYPE_SALE
    | typeof PAGE_TYPE_LOCKED_BUILD
    | string;
  slug: string;
  parentSlug?: string;
};

const getCmsLinkUrl = ({
  contentType,
  pageType,
  slug,
  parentSlug,
}: Args): string => {
  const type = pageType || contentType;
  const routePrefix = `/${CONTENT_TYPE_ROUTE_MAP[type]}/` || '/';

  let combinedSlug = parentSlug ? `${parentSlug}/${slug}` : slug;

  if (pageType === PAGE_TYPE_HOME) {
    return '/';
  }

  if (
    pageType === PAGE_TYPE_RETAILERS ||
    pageType === PAGE_TYPE_SUPPORT ||
    contentType === CONTENT_TYPE_LEGAL_HUB
  ) {
    combinedSlug = '';
  }

  if (pageType === PAGE_TYPE_USER && slug === 'user') {
    combinedSlug = '';
  }

  if (pageType === PAGE_TYPE_CAREERS && slug === 'careers') {
    combinedSlug = '';
  }

  if (pageType === PAGE_TYPE_ABOUT && slug === 'company') {
    combinedSlug = '';
  }

  if (pageType === PAGE_TYPE_ABOUT && slug === 'order-tracking') {
    return `/${combinedSlug}`;
  }

  if (pageType === PAGE_TYPE_NEWS) {
    combinedSlug = '';
    return `${routePrefix}${combinedSlug}page/1`;
  }

  if (pageType === PAGE_TYPE_REVIEWS) {
    combinedSlug = '';
    return `${routePrefix}${combinedSlug}page/1`;
  }

  if (contentType === CONTENT_TYPE_NEWS_CATEGORY) {
    return `${routePrefix}${combinedSlug}/page/1`;
  }

  if (pageType === PAGE_TYPE_ABOUT && slug === 'partner-program-landing') {
    return `/partner-program`;
  }

  if (pageType === PAGE_TYPE_ABOUT && slug === 'partner-program-form') {
    return `/partner-program/form`;
  }
  if (pageType === PAGE_TYPE_BUILD && slug === 'build') {
    combinedSlug = '';
  }

  return `${routePrefix}${combinedSlug}`;
};

export default getCmsLinkUrl;
