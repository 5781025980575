import { FC } from 'react';
import { IconX } from '@nzxt/react-icons';
import { useTranslation as t } from '@utils/hooks';
import * as styles from './styles';

const ErrorView: FC = () => (
  <div className={styles.contentContainer}>
    <span className={styles.iconWrapper}>
      <IconX width={24} height={24} />
    </span>
    <h2 className={styles.secondaryText}>{t('cart_heading_error')}</h2>
  </div>
);

export default ErrorView;
