/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, memo, useEffect, useRef } from 'react';
import Script from 'next/script';
import cn from 'clsx';
import {
  AFFIRM_SCRIPT_URL,
  AFFIRM_API_KEY,
  AFFIRM_SMALL_SIZE,
} from '@constants';
import { useTranslation as t } from '@utils/hooks';
import GTM from '@utils/gtm';
import affirmInit from './affirm-init';
import * as styles from './styles';
import extraStyles from './Affirm.module.css';

type Props = {
  id: string;
  price: string;
  learnMore?: boolean;
  whiteLogo?: boolean;
  whiteLink?: boolean;
  size?: string;
};

const Affirm: FC<Props> = ({
  id,
  price,
  learnMore,
  whiteLogo = false,
  whiteLink = false,
  size = AFFIRM_SMALL_SIZE,
}) => {
  useEffect(() => {
    affirmInit(price, id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [price]);

  const asLowAsRef = useRef<HTMLSpanElement>(null);

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const handleEvent = (e: any): void => {
      if (e?.target?.className === 'affirm-modal-trigger') {
        GTM.dataLayer({
          dataLayer: {
            event: 'affirmModal',
          },
        });
      }
    };
    if (typeof window !== 'undefined' && asLowAsRef?.current) {
      window.addEventListener('click', e => handleEvent(e));
    }

    return () => window.removeEventListener('click', e => handleEvent(e));
  }, [asLowAsRef]);

  const LEARN_MORE = t('learn_more');
  const MONTHLY_PAYMENTS = t('monthly_payments');

  return (
    <>
      {typeof window !== 'undefined' && typeof window.affirm === 'undefined' && (
        <Script id="add-affirm" strategy="afterInteractive">
          {`
            (function(l, g, m, e, a, f, b) {if (!l.affirm) {var d, c = l[m] || {},h = document.createElement(f),n = document.getElementsByTagName(f)[0],k = function(a, b, c) {return function() {a[b]._.push([c, arguments])}};c[e] = k(c, e, "set");d = c[e];c[a] = {};c[a]._ = [];d._ = [];c[a][b] = k(c, a, b);a = 0;for (b = "set add save post open empty reset on off trigger ready setProduct".split(" "); a < b.length; a++) d[b[a]] = k(c, e, b[a]);a = 0;for (b = ["get", "token", "url", "items"]; a < b.length; a++) d[b[a]] = function() {};h.async = !0;h.src = g[f];n.parentNode.insertBefore(h, n);delete g[f];d(g);l[m] = c}})(window, {public_api_key: "${AFFIRM_API_KEY}",script: "${AFFIRM_SCRIPT_URL}"}, "affirm", "checkout", "ui", "script", "ready");
          `}
        </Script>
      )}
      <div className={styles.getSize(size)}>
        <div
          className="AffirmDesktopWrap min-h-[17px]"
          data-test-id="affirm-block"
        >
          <div className="AffirmDesktop">
            <div className={`affirm affirm-${id}`} style={{ display: 'none' }}>
              {learnMore ? (
                <p>
                  {MONTHLY_PAYMENTS}
                  <a
                    className={cn(
                      'affirm-site-modal',
                      `affirm-site-modal-${id}`,
                      styles.learnMoreLink(whiteLink)
                    )}
                    data-page-type="homepage"
                  >
                    {LEARN_MORE}
                  </a>
                </p>
              ) : (
                <span
                  ref={asLowAsRef}
                  className={cn('affirm-as-low-as', `affirm-as-low-as-${id}`, {
                    [extraStyles.affirm]: !whiteLink,
                    [extraStyles.lightAffirm]: whiteLink,
                  })}
                  data-amount=""
                  data-page-type="payment"
                  data-affirm-color={whiteLogo ? 'white' : 'black'}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default memo(Affirm);
