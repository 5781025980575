/* eslint-disable no-underscore-dangle */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { memo, FC, useMemo } from 'react';
import Link from 'next/link';
import getCmsLinkUrl from '@utils/get-cms-link-url';
import type { NavCta } from '@framework/api/types';
import Cta from './Cta';

import * as styles from './styles';

type Props = NavCta & {
  externalLink?: { url: string };
  handleLinkClick: (name: string) => void;
};

const HeaderSubmenuCta: FC<Props> = ({
  heading,
  subheading,
  link,
  externalLink,
  eyebrowLogo,
  isFullCta,
  image,
  backgroundColor,
  backgroundImage,
  handleLinkClick,
}) => {
  const href = useMemo(
    () =>
      link
        ? getCmsLinkUrl({
            contentType: link._modelApiKey,
            pageType: link.pageType,
            slug: link.slug,
            parentSlug: link?.parentCategory?.slug,
          })
        : null,
    [link]
  );

  const CtaComponent = (
    <Cta
      heading={heading}
      subheading={subheading}
      image={image}
      isFullCta={isFullCta}
      eyebrowLogo={eyebrowLogo}
      backgroundColor={backgroundColor}
      backgroundImage={backgroundImage}
    />
  );

  return image?.responsiveImage?.src ? (
    <div className={styles.outerWrapper}>
      {/* eslint-disable-next-line no-nested-ternary */}
      {href || externalLink ? (
        href ? (
          <Link href={href} passHref onClick={() => handleLinkClick(heading)}>
            {CtaComponent}
          </Link>
        ) : (
          <a href={externalLink.url} onClick={() => handleLinkClick(heading)}>
            {CtaComponent}
          </a>
        )
      ) : (
        CtaComponent
      )}
    </div>
  ) : null;
};

export default memo(HeaderSubmenuCta);
