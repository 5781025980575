/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useMemo, memo } from 'react';
import { useRouter } from 'next/router';
import type { PageNotice } from '@framework/api/types';
import { getRegion } from '@framework/api/utils/maxify';
import Link from 'next/link';
import getCmsLinkUrl from '@utils/get-cms-link-url';
import { IconAlert } from '@nzxt/react-icons';
import * as styles from './styles';

type Props = {
  pageNotice: PageNotice[];
  className?: string;
};

const Notice: FC<Props> = ({ pageNotice, className }) => {
  const { locale } = useRouter();
  const region = getRegion(locale);

  const regionalpageNotice = useMemo(
    () =>
      Array.isArray(pageNotice)
        ? pageNotice.filter(c =>
            c.availableRegions?.some(r => r.region === region)
          )
        : null,
    [pageNotice, region]
  );

  const internalButtonLink = regionalpageNotice[0]?.internalLink
    ? getCmsLinkUrl({
        // eslint-disable-next-line no-underscore-dangle
        contentType: regionalpageNotice[0].internalLink._modelApiKey,
        pageType: regionalpageNotice[0].internalLink.pageType,
        slug: regionalpageNotice[0].internalLink.slug,
        parentSlug: regionalpageNotice[0].internalLink?.parentCategory?.slug,
      })
    : null;

  return Array.isArray(regionalpageNotice) && regionalpageNotice?.length > 0 ? (
    <>
      {regionalpageNotice.map(notice => (
        <div
          key={notice.id}
          className={`${styles.wrapper} ${className}`}
          data-test-id="page-notice-item"
        >
          <div className={styles.inner}>
            <div className={styles.iconWrapper}>
              <IconAlert className={styles.icon} />
            </div>
            <div className={styles.contentWrapper}>
              <div>
                {notice.title && (
                  <h3 className={styles.heading}>{notice.title}</h3>
                )}
                {notice.description && (
                  <p className={styles.content}>
                    {notice.description}{' '}
                    {/* eslint-disable-next-line no-nested-ternary */}
                    {internalButtonLink || notice.externalButtonLink ? (
                      internalButtonLink ? (
                        <Link
                          href={internalButtonLink}
                          passHref
                          className={styles.link}
                        >
                          {notice.linkText}.
                        </Link>
                      ) : (
                        <a
                          href={notice.externalButtonLink.url}
                          className={styles.link}
                        >
                          {notice.linkText}
                        </a>
                      )
                    ) : null}
                  </p>
                )}
              </div>
            </div>
          </div>
        </div>
      ))}
    </>
  ) : null;
};
export default memo(Notice);
