import { classnames } from '@tailwindcss-classnames';

export const stockNoticeWrapper = classnames(
  'flex',
  'justify-start',
  'pt-4',
  'pb-3',
  'px-5',
  'relative',
  'bg-red-600',
  'rounded',
  'mt-2'
);

export const stockNotice = classnames(
  'font-semibold',
  'text-center',
  'text-xs',
  'inline-block',
  'text-white'
);

export const icon = classnames(
  'text-white',
  'w-6',
  'h-6',
  'absolute',
  'top-0',
  'right-0',
  'cursor-pointer'
);

export const detailList = classnames('space-y-1', 'text-left');
export const dash = classnames('mr-1');
