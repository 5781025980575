import { FC, memo, useState, useEffect } from 'react';
import { useCountdown } from '@utils/hooks';
import * as styles from './styles';

type Props = {
  countdownEnd: string;
};

const MarketingBannerCountdown: FC<Props> = ({ countdownEnd }) => {
  const countdownDate = useCountdown(countdownEnd);

  const [showTimer, setShowTimer] = useState(false);

  useEffect(() => {
    if (
      countdownDate &&
      countdownDate.days === '00' &&
      !countdownDate.isExpired
    ) {
      setShowTimer(true);
    }
  }, [countdownDate]);

  return showTimer ? (
    <div className={styles.timerContainer}>
      <div className={styles.countdownCell}>
        <p className={styles.countdownNumber}>{countdownDate.days}</p>
        <p className={styles.countdownText} style={{ fontSize: '0.375rem' }}>
          Days
        </p>
      </div>
      <span className={styles.bartoloColon}>:</span>
      <div className={styles.countdownCell}>
        <p className={styles.countdownNumber}>{countdownDate.hours}</p>
        <p className={styles.countdownText} style={{ fontSize: '0.375rem' }}>
          Hrs
        </p>
      </div>
      <span className={styles.bartoloColon}>:</span>
      <div className={styles.countdownCell}>
        <p className={styles.countdownNumber}>{countdownDate.minutes}</p>
        <p className={styles.countdownText} style={{ fontSize: '0.375rem' }}>
          Min
        </p>
      </div>
      <span className={styles.bartoloColon}>:</span>
      <div className={styles.countdownCell}>
        <p className={styles.countdownNumber}>{countdownDate.seconds}</p>
        <p className={styles.countdownText} style={{ fontSize: '0.375rem' }}>
          Sec
        </p>
      </div>
    </div>
  ) : null;
};

export default memo(MarketingBannerCountdown);
