import { FC, useState, memo, useEffect } from 'react';
import { useRouter } from 'next/router';
import Link from 'next/link';
import Image from 'next/legacy/image';
import { v4 as uuidv4 } from 'uuid';
import { Experiment } from '@amplitude/experiment-js-client';
import { IconTrash } from '@nzxt/react-icons';
import GTM from '@utils/gtm';
import usePrice from '@framework/use-price';
import { getRegion } from '@framework/api/utils/maxify';
import useUpdateItem from '@framework/cart/use-update-item';
import useRemoveItem from '@framework/cart/use-remove-item';
import useAddItem from '@framework/cart/use-add-item';
import type { ShopifyLineEdges } from '@framework/api/cart';
import slugify from '@utils/slugify';
import hasDiscountPrice from '@utils/has-discount-price';
import decodeVariantId from '@utils/decode-variant-id';
import {
  MAXIFY_REGION_NORTHERN_AMERICA,
  ROUTE_CART,
  ROUTE_PRODUCT,
} from '@constants';
import YotpoWidget from '@components/YotpoReviews/YotpoWidget';
import AvailabilityMessage from '@components/Build/Shell/AvailabilityMessage';
import StockMessage from '@components/PDP/StockMessage';
import CartItemCounter from './CartItemCounter';
import CartStockNotice from './CartItemStockNotice';
import * as styles from './styles';
import ExtendCartOffer from '../../Extend/ExtendCartOffer';
import CartItemEsd from './CartItemEsd';

export type Props = {
  item: ShopifyLineEdges;
  isRefurbished: boolean;
  isOp: boolean;
  isSideBarCart?: boolean;
  isFanUpgrade?: boolean;
};

const amplitudeId = process.env.NEXT_PUBLIC_AMPLITUDE_ID;

const CartItem: FC<Props> = ({ item, isRefurbished, isOp, isFanUpgrade }) => {
  const router = useRouter();
  const { locale, pathname, asPath } = router;
  const region = getRegion(locale);
  let isPeripheral = false;
  const isCartPage = pathname.includes(ROUTE_CART);

  const { node } = item;

  const extractProductNumber = (str: string): number | null => {
    const match = str.match(/\/Product\/(\d+)/);
    if (match) {
      const productNumber = parseInt(match[1], 10);
      return Number.isNaN(productNumber) ? null : productNumber;
    }
    return null;
  };

  const itemParentId = extractProductNumber(node.merchandise.product.id);

  const rawPrice = parseFloat(node.merchandise.compareAtPriceV2?.amount);
  const { price } = usePrice({
    amount: rawPrice,
    currencyCode: node.merchandise.compareAtPriceV2?.currencyCode,
  });

  const nodeQuantity = node.quantity === 0 ? 1 : node.quantity;
  const rawDiscountPrice =
    parseFloat(node.merchandise.priceV2.amount) -
    parseFloat(node.discountAllocations[0]?.discountedAmount?.amount ?? '0') /
      nodeQuantity;
  const { price: discountPrice } = usePrice({
    amount: rawDiscountPrice,
    currencyCode: node.merchandise.priceV2.currencyCode,
  });

  const rawRealPrice =
    (parseFloat(node.merchandise.compareAtPriceV2?.amount) === 0 ||
      !node.merchandise.compareAtPriceV2) &&
    parseFloat(node.merchandise.priceV2.amount) > 0
      ? rawDiscountPrice
      : rawPrice;
  const realPrice =
    (parseFloat(node.merchandise.compareAtPriceV2?.amount) === 0 ||
      !node.merchandise.compareAtPriceV2) &&
    parseFloat(node.merchandise.priceV2.amount) > 0
      ? discountPrice
      : price;

  const showDiscountPrice = hasDiscountPrice(
    node.merchandise.compareAtPriceV2?.amount,
    node.merchandise.priceV2.amount,
    node.discountAllocations[0]?.discountedAmount?.amount
  );

  const discountPercentage = showDiscountPrice
    ? Math.floor(((rawRealPrice - rawDiscountPrice) / rawRealPrice) * 100)
    : 0;

  const protectedPeripherals = ['keyboard', 'mouse', 'monitors', 'peripherals'];

  // Extend - Determines if product is a warrantable peripheral
  if (item.node?.merchandise.product.productType) {
    if (
      protectedPeripherals.includes(
        item.node?.merchandise.product.productType.toLowerCase()
      )
    ) {
      isPeripheral = true;
    }
  }
  // Extend - End code

  const slug = item?.node?.attributes?.find(a => a.key === '_slug')?.value;
  const [experimentVariant, setExperimentVariant] = useState(null);

  useEffect(() => {
    const initExperiment = async (): Promise<void> => {
      const experiment =
        Experiment.initializeWithAmplitudeAnalytics(amplitudeId);

      await experiment.start();

      const variant = experiment.variant('cart-pdp-routing');

      if (variant && variant.value) {
        setExperimentVariant(variant.value);
      }
    };

    if (amplitudeId) {
      initExperiment();
    }
  }, [asPath]);

  const abShowLink = experimentVariant === 'treatment';

  const updateItem = useUpdateItem();
  const removeItem = useRemoveItem();
  const addItem = useAddItem('cart');

  const [removing, setRemoving] = useState(false);
  const [loading, setLoading] = useState(false);

  const updateQuantity = async (val: number): Promise<void> => {
    setLoading(true);
    const attributes = node?.attributes;

    try {
      if (attributes.some(a => a.key === '_hybrid_id') && val > 1) {
        const newUuid = `${uuidv4()}-hb`;
        attributes.find(a => a.key === '_hybrid_id').value = newUuid;

        await addItem({
          shopify_variants_id: parseInt(
            node?.merchandise?.id?.replace('gid://shopify/ProductVariant/', ''),
            10
          ),
          attributes,
        });
      } else {
        await updateItem({
          id: node.id,
          quantity: val,
          attributes,
        });
      }
      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  };

  const handleRemove = async (): Promise<void> => {
    setRemoving(true);

    try {
      await removeItem({ id: node.id });

      GTM.dataLayer({
        dataLayer: {
          event: 'removeFromCart',
          ecommerce: {
            remove: {
              products: [
                {
                  name: item.node.merchandise.product.title,
                  id: parseInt(item.node.merchandise.id, 10),
                  price: node.merchandise.priceV2.amount.toString(),
                  variant: item.node.merchandise.title,
                  quantity: item.node.quantity,
                },
              ],
            },
          },
        },
      });

      if (node.merchandise.product.title.includes('Extend Protection Plan')) {
        // track remove from cart for extended warranty
        GTM.dataLayer({
          dataLayer: {
            event: 'warrantyDelete',
            warrantyLength: node.merchandise.title,
            warrantyPrice: node.merchandise.priceV2.amount,
          },
        });
      }
    } catch (error) {
      setRemoving(false);
    }
  };

  const formProductNameAndVariant = (
    productName: string,
    productVariant: string
  ): string =>
    `${productName || 'name'.toLowerCase().replace(' ', '-')}${
      productVariant && '-'
    }${productVariant && productVariant.toLowerCase().replace(' ', '-')}`;

  let isExtend;
  if (node.merchandise.product.title.indexOf('Extend Protection Plan') > -1) {
    isExtend = true;
  }
  const extendClass = isExtend ? ' extend-item' : '';

  const regex = /(\w+)(:.*?)(?=\s+\w+:|$)/g;
  const formattedDescriptionArr = Array.from(
    node?.merchandise?.product?.description?.matchAll(regex),
    m => `${m[1]}${m[2]}`
  );

  const isLowStock =
    node.merchandise.quantityAvailable > 0 &&
    node.merchandise.quantityAvailable <= 10;

  return (
    <li
      className={styles.getBaseStyle(removing)}
      data-test-id={`${slugify(
        `${node.merchandise.product.title} ${node.merchandise.title}`
      )}-variant-id-${decodeVariantId(node.merchandise.id)}`}
    >
      {node.quantity < 1 && (
        <div className={styles.stockNoticeWrapper}>
          <CartStockNotice
            itemName={`${node.merchandise.product.title} - ${node.merchandise.title}`}
          />
        </div>
      )}
      <div className={styles.containerCol}>
        <div className={styles.baseContainer + extendClass}>
          <div className={styles.imageWrapper}>
            {node.merchandise.image && (
              <Image
                className={styles.productImage}
                src={node.merchandise.image.url}
                id={slugify(
                  `cart-${formProductNameAndVariant(
                    node.merchandise.product.title,
                    node.merchandise.title
                  )}-image`
                )}
                width={150}
                height={150}
                alt={`Cart Item ${formProductNameAndVariant(
                  node.merchandise.product.title,
                  node.merchandise.title
                )}`}
              />
            )}
          </div>
          <div className={styles.container}>
            {slug &&
            abShowLink &&
            node.merchandise.product.productType !==
              'Extend Service Contract' ? (
              <Link href={`/${ROUTE_PRODUCT}/${slug}`}>
                <span
                  className={styles.getItemNameStyle(false)}
                  data-test-id="cart-item-title"
                >
                  {`${node.merchandise.product.title} - ${node.merchandise.title}`}
                </span>
              </Link>
            ) : (
              <span
                className={styles.getItemNameStyle(false)}
                data-test-id="cart-item-title"
              >
                {`${node.merchandise.product.title} - ${node.merchandise.title}`}
              </span>
            )}
            <YotpoWidget
              type="star-rating"
              id={itemParentId}
              template="collection"
            />
            {node.merchandise.product.productType === 'Prebuilt-Hybrid' &&
            formattedDescriptionArr?.length > 0 ? (
              <div className={styles.descrptionWrapper}>
                <ul>
                  {formattedDescriptionArr.map(d => (
                    <li key={`${node.id}-${d}`}>{d}</li>
                  ))}
                </ul>
              </div>
            ) : null}
            <div className={styles.esdQtyWrapper}>
              {node.slaDate &&
              process.env.NEXT_PUBLIC_ENABLE_CART_ESD === 'true' ? (
                <CartItemEsd esd={node.slaDate} />
              ) : null}
            </div>
          </div>
          <div className={styles.itemPriceRemove}>
            <div className={styles.priceContainer}>
              {showDiscountPrice ? (
                <AvailabilityMessage
                  showPercentOff
                  innerMessage
                  percentOff={discountPercentage}
                />
              ) : null}
              <div className="flex flex-col">
                <span
                  className={styles.getItemPriceStyle(showDiscountPrice)}
                  data-test-id={slugify(
                    `${formProductNameAndVariant(
                      node.merchandise.product.title,
                      node.merchandise.title
                    )}-cart-price`
                  )}
                >
                  {realPrice}
                </span>
                <span
                  className={
                    showDiscountPrice ? styles.itemPrice : styles.hidden
                  }
                  id={slugify(
                    `${formProductNameAndVariant(
                      node.merchandise.product.title,
                      node.merchandise.title
                    )}-cart-discount-price`
                  )}
                >
                  {discountPrice}
                </span>
              </div>
              {isLowStock ? (
                <StockMessage
                  stock={node.merchandise.quantityAvailable}
                  className={['text-nzxt-red-400']}
                />
              ) : null}
              <div className={styles.itemQtyControls}>
                {node.quantity < 1 || isRefurbished || isOp ? null : (
                  <CartItemCounter
                    key={node.id}
                    id={formProductNameAndVariant(
                      node.merchandise.product.title,
                      node.merchandise.title
                    )}
                    defaultCount={node.quantity}
                    onUpdate={updateQuantity}
                    max={isExtend ? 100 : node.merchandise.quantityAvailable}
                    disabled={isFanUpgrade}
                  />
                )}
              </div>
              <button
                type="button"
                className={styles.removeButton}
                onClick={handleRemove}
                data-test-id={slugify(
                  `${formProductNameAndVariant(
                    node.merchandise.product.title,
                    node.merchandise.title
                  )}-remove-cart`
                )}
                disabled={loading}
                aria-label="Remove From Cart"
              >
                <IconTrash className={styles.removeButtonIcon} />
              </button>
            </div>
          </div>
        </div>
        {node.merchandise.product.productType === 'Prebuilt-Hybrid' &&
        formattedDescriptionArr?.length > 0 ? (
          <div className={styles.descrptionWrapper}>
            <ul data-test-id="prebuilt-item-description-list">
              {formattedDescriptionArr.map(d => (
                <li key={`${node.id}-${d}`}>{d}</li>
              ))}
            </ul>
          </div>
        ) : null}
      </div>
      {region === MAXIFY_REGION_NORTHERN_AMERICA && isCartPage ? (
        <ExtendCartOffer
          item={item}
          price={price}
          isPeripheral={isPeripheral}
          isFullPageCart
        />
      ) : (
        <ExtendCartOffer
          item={item}
          price={price}
          isPeripheral={isPeripheral}
        />
      )}
    </li>
  );
};

export default memo(CartItem);
