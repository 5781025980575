/* eslint-disable no-nested-ternary */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { FC, useEffect, useMemo, useRef, memo } from 'react';
import { useRouter } from 'next/router';
import useUiStore, { getCartStockNotices } from '@stores/use-ui-store';
import { getRegion } from '@framework/api/utils/maxify';
import useRemoveItem from '@framework/cart/use-remove-item';
import type { ShopifyLineEdges, ShopifyCart } from '@framework/api/cart';
import {
  MAXIFY_REGION_NORTHERN_AMERICA,
  HYBRID_CART_CATS,
  PREBUILD_PARENT_CATS,
} from '@constants';
import ExtendHelper from '@components/Extend/ExtendHelper';
import CartItem, {
  CartBuild as CartBuildComponent,
  CartHybrid as CartHybridComponent,
} from '../CartItem';
import CartBuildStockNotice from '../CartBuildStockNotice';

import * as styles from './styles';

const isRefurbished = (item: ShopifyLineEdges): boolean =>
  item.node.merchandise.product.title
    .toLowerCase()
    .includes(
      'refurbish' || 'renoviert' || 'remis' || 'ristruttur' || 'gerenoveerd'
    );

const isOp = (item: ShopifyLineEdges): boolean =>
  item?.node?.merchandise?.sku?.toUpperCase()?.startsWith('ORDERPROTECTION');

const isFanUpgrade = (item: ShopifyLineEdges): boolean => {
  if (
    item?.node?.attributes?.some(a => a.key === '_hybrid_id') &&
    item?.node?.merchandise?.product?.productType?.toLowerCase() === 'air'
  ) {
    return true;
  }

  return false;
};

type Props = {
  data: ShopifyCart;
  isSideBarCart?: boolean;
  setItemContainerHeight?: (height: number) => void;
};

const CartItemsSection: FC<Props> = ({
  data,
  isSideBarCart = false,
  setItemContainerHeight,
}) => {
  const router = useRouter();
  const { locale } = router;
  const region = getRegion(locale);
  const itemContainer = useRef<HTMLDivElement>();

  const rawItems = useMemo(() => data?.lines.edges ?? [], [data]);

  const noBuildItems = useMemo(
    () =>
      rawItems.filter(
        i =>
          !i?.node?.attributes?.some(a => a.key === '_build_number') &&
          !i?.node?.merchandise?.product.title?.includes(
            'Extend Protection Plan'
          )
      ),
    [rawItems]
  );

  const noHybridItems = useMemo(
    () =>
      noBuildItems.filter(
        i =>
          !i?.node?.attributes?.some(a => a.key === '_hybrid_id') ||
          !HYBRID_CART_CATS.includes(
            i?.node?.merchandise?.product?.productType?.toLowerCase()
          )
      ),
    [noBuildItems]
  );

  const warrItems = useMemo(
    () =>
      rawItems.filter(
        i =>
          i?.node?.merchandise?.product?.title?.indexOf(
            'Extend Protection Plan'
          ) > -1
      ),
    [rawItems]
  );

  const opItems = useMemo(() => rawItems.filter(i => isOp(i)), [rawItems]);

  const buildItems = useMemo(
    () =>
      rawItems.filter(i =>
        i?.node?.attributes?.some(a => a.key === '_build_number')
      ),
    [rawItems]
  );

  const buildNumbers = useMemo(
    () => [
      ...new Set(
        buildItems.map(
          i =>
            i?.node?.attributes?.find(a => a.key === '_build_number').value ??
            null
        )
      ),
    ],
    [buildItems]
  );

  const builds = useMemo(() => {
    const pcNumbers = buildNumbers.filter(n => !n.startsWith('KB-'));

    return pcNumbers.map(num =>
      buildItems.filter(
        b =>
          b?.node?.attributes?.find(
            a => a.key === '_build_number' && a.value === num
          ) &&
          !b?.node?.merchandise?.product?.title?.includes(
            'Extend Protection Plan'
          )
      )
    );
  }, [buildItems, buildNumbers]);

  const hybridItems = useMemo(
    () =>
      rawItems.filter(i =>
        i?.node?.attributes?.some(a => a.key === '_hybrid_id')
      ),
    [rawItems]
  );

  const hybridNumbers = useMemo(
    () => [
      ...new Set(
        hybridItems.map(
          i =>
            i?.node?.attributes?.find(a => a.key === '_hybrid_id').value ?? null
        )
      ),
    ],
    [hybridItems]
  );

  const hybrids = useMemo(() => {
    const nums = hybridNumbers.filter(Boolean);

    return nums.map(num =>
      hybridItems.filter(
        b =>
          b?.node?.attributes?.find(
            a => a.key === '_hybrid_id' && a.value === num
          ) &&
          HYBRID_CART_CATS.includes(
            b?.node?.merchandise?.product?.productType?.toLowerCase()
          )
      )
    );
  }, [hybridItems, hybridNumbers]);

  const combinedHybrids = useMemo(() => {
    if (!Array.isArray(warrItems) || warrItems.length < 1) {
      return hybrids;
    }

    let tempWarr = warrItems;

    const newHybrids = hybrids.map(h => {
      const id = h
        ?.find(p =>
          PREBUILD_PARENT_CATS.includes(
            p?.node?.merchandise?.product?.productType?.toLowerCase()
          )
        )
        ?.node?.merchandise?.id?.replace('gid://shopify/ProductVariant/', '');

      const matchingWarr = tempWarr.find(
        w =>
          w?.node?.attributes?.find(a => a.key === '_Extend.ProductId')
            .value === id &&
          !w?.node?.attributes?.some(a => a.key === '_hybrid_id')
      );

      tempWarr = tempWarr.filter(w => w?.node?.id !== matchingWarr?.node?.id);

      return matchingWarr ? [...h, matchingWarr] : h;
    });

    return newHybrids;
  }, [hybrids, warrItems]);

  const nonHybridWarrItems = useMemo(() => {
    const hybridsWithWarr = combinedHybrids
      .filter(h =>
        h.some(p =>
          p?.node?.merchandise?.product?.title?.includes(
            'Extend Protection Plan'
          )
        )
      )
      .flat();

    return warrItems.filter(w => !hybridsWithWarr.includes(w));
  }, [combinedHybrids, warrItems]);

  const items = useMemo(
    () => [...noHybridItems, ...nonHybridWarrItems],
    [noHybridItems, nonHybridWarrItems]
  );

  const cartNotices = useUiStore(getCartStockNotices);

  useEffect(() => {
    if (itemContainer?.current && setItemContainerHeight) {
      setItemContainerHeight(
        itemContainer.current?.getBoundingClientRect().height
      );
    }
  }, [itemContainer, data]);

  // remove builds with OOS items
  const oosBuilds = useMemo(
    () =>
      builds
        .map(build => {
          if (
            build.some(
              i =>
                i.node.quantity === 0 ||
                (i.node.merchandise.quantityAvailable === 0 &&
                  !i.node.merchandise.product.productType.includes(
                    'Extend Service Contract'
                  ))
            )
          ) {
            return (
              build[0]?.node?.attributes?.find(a => a.key === '_build_number')
                .value ?? null
            );
          }

          return null;
        })
        .filter(Boolean),
    [builds]
  );

  const removeItem = useRemoveItem();

  useEffect(() => {
    const removeItems = async (): Promise<void> => {
      const ids = [];
      oosBuilds.forEach(buildId => {
        const build = builds.find(
          b =>
            b[0]?.node?.attributes?.find(a => a.key === '_build_number')
              .value === buildId
        );

        ids.push(build.map(i => i?.node?.id));
      });

      if (ids.length > 0) {
        await removeItem({ id: ids.filter(Boolean).flat() });
      }
    };

    if (Array.isArray(oosBuilds) && oosBuilds.length > 0) {
      removeItems();
    }
  }, [builds, oosBuilds, removeItem]);

  // if only OP items are in cart, remove them
  useEffect(() => {
    const removeItems = async (): Promise<void> => {
      const ids = [];
      opItems.forEach(p => {
        ids.push(p?.node?.id);
      });

      if (ids.length > 0) {
        await removeItem({ id: ids.filter(Boolean).flat() });
      }
    };

    if (
      Array.isArray(rawItems) &&
      Array.isArray(opItems) &&
      rawItems.length === opItems.length
    ) {
      removeItems();
    }
  }, [opItems, rawItems, removeItem]);

  return (
    <div ref={itemContainer}>
      {cartNotices.map(cartNotice => (
        <CartBuildStockNotice
          key={cartNotice.buildLink}
          noticeItem={cartNotice}
        />
      ))}
      <ul className={styles.itemsUl}>
        {builds?.map((build, i) => (
          <CartBuildComponent
            key={
              build[0]?.node.attributes.find(a => a.key === '_build_number')
                .value || `cart-build-${i}`
            }
            build={build}
          />
        ))}
        {combinedHybrids?.map((hybrid, i) => {
          const parentItem = hybrid?.find(p =>
            PREBUILD_PARENT_CATS.includes(
              p?.node?.merchandise?.product?.productType?.toLowerCase()
            )
          );

          return parentItem ? (
            <CartHybridComponent
              key={
                hybrid[0]?.node.attributes.find(a => a.key === '_hybrid_id')
                  .value || `cart-hybrid-${i}`
              }
              parentItem={parentItem}
              hybrid={hybrid}
            />
          ) : (
            hybrid?.map(item => (
              <CartItem
                key={item.node.id}
                item={item}
                isRefurbished={isRefurbished(item)}
                isOp={isOp(item)}
                isFanUpgrade={isFanUpgrade(item)}
                isSideBarCart={isSideBarCart}
              />
            ))
          );
        })}
        {region === MAXIFY_REGION_NORTHERN_AMERICA && (
          <ExtendHelper items={items} />
        )}
        {items?.map(item => (
          <CartItem
            key={item.node.id}
            item={item}
            isRefurbished={isRefurbished(item)}
            isOp={isOp(item)}
            isFanUpgrade={isFanUpgrade(item)}
            isSideBarCart={isSideBarCart}
          />
        ))}
      </ul>
    </div>
  );
};

export default memo(CartItemsSection);
