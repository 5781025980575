import { classnames } from '@tailwindcss-classnames';

export const outer = classnames(
  'xl:mt-0',
  'sm:order-2',
  'flex',
  'md:justify-end',
  'md:items-end',
  'justify-center',
  'items-center',
  'flex-col',
  'md:mb-0'
);

export const fieldWrapper = classnames('flex-grow');

export const title = classnames(
  'leading-5',
  'font-semibold',
  'text-gray-400',
  'tracking-wider',
  'uppercase',
  'text-xs'
);

export const form = classnames('md:max-w-xs', 'flex', 'items-center', 'w-full');

export const fieldset = classnames(
  'w-full',
  'm-0',
  'flex',
  'sm:place-content-between'
);

export const label = classnames(
  'leading-5',
  'font-semibold',
  'tracking-wider',
  'uppercase',
  'text-xxs',
  'block',
  'mb-1'
);

export const relative = classnames('relative');

export const select = classnames(
  'appearance-none',
  'bg-none',
  'block',
  'w-full',
  'bg-nzxt-dark-grey-800',
  'border-none',
  'py-1',
  'mr-12',
  'leading-6',
  'text-white',
  'focus:outline-none',
  'focus:shadow-outline',
  'focus:border-blue-300',
  'text-base',
  'md:text-xs',
  'sm:leading-5',
  'px-1',
  'disabled:cursor-not-allowed',
  'cursor-pointer'
);

export const svg = classnames('h-4', 'w-4', 'text-gray-400');

export const iconWrapper = classnames(
  'pointer-events-none',
  'absolute',
  'inset-y-0',
  'right-0',
  'flex',
  'items-center',
  'p-1'
);

export const twoColumn = classnames('flex-col', 'space-y-2');
export const oneColumn = classnames('flex-row', 'space-x-8');
export const textLight = classnames('text-white');
export const textLightGrey = classnames('text-gray-400');
export const textDark = classnames('text-nzxt-dark-grey-500');
export const labelTextPadding = classnames('px-1');

export const getFieldset = (isHorizontal: boolean): string =>
  classnames(fieldset, {
    [twoColumn]: !isHorizontal,
    [oneColumn]: isHorizontal,
  });

export const getLabel = (isInverted: boolean): string =>
  classnames(label, {
    [textLightGrey]: isInverted,
    [textDark]: !isInverted,
    [labelTextPadding]: isInverted,
  });

export const getSelect = (isInverted: boolean): string =>
  classnames(select, {
    [textLight]: isInverted,
    [textDark]: !isInverted,
  });

export const chevronDown = classnames(
  'stroke-current',
  'text-white',
  'text-base',
  'absolute',
  'right-0'
);

export const getChevronDown = (isInverted: boolean): string =>
  classnames(chevronDown, {
    [textLight]: isInverted,
    [textDark]: !isInverted,
  });

export const dropdownRegion = classnames(textLight, 'pl-2', 'font-bold');
export const menuButton = classnames(
  textLight,
  'pl-1',
  'text-xs',
  'flex',
  'items-center'
);

const w44 = classnames('w-44');
const w32 = classnames('w-32');

export const languageMenuButton = (largeText: boolean): string =>
  classnames(menuButton, {
    [w44]: largeText,
    [w32]: !largeText,
  });
export const languageMenuButtonFooter = classnames(menuButton, w32);

export const locationMenuButton = (largeText: boolean): string =>
  classnames(menuButton, {
    [w44]: largeText,
    [w32]: !largeText,
  });

export const locationMenuButtonFooter = (largeText: boolean): string =>
  classnames(menuButton, {
    [w44]: largeText,
    [w32]: !largeText,
  });

export const dropdownItem = classnames('text-white');
export const activeMenuItem = classnames('-ml-4', 'mr-1');
export const menu = classnames('rounded', 'border-0');

export const activeItem = (isActive: boolean): string =>
  classnames({
    'text-nzxt-violet-200': isActive,
    'text-white': !isActive,
  });
