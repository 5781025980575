/* eslint-disable no-nested-ternary */
import { FC, useMemo, useState, useEffect, useRef } from 'react';
import Router, { useRouter } from 'next/router';
import debounce from 'lodash.debounce';
import GTM from '@utils/gtm';
import { useMedia, useTranslation as t } from '@utils/hooks';
import { IconX } from '@nzxt/react-icons';
import useUiStore, {
  getCloseCart,
  getOpenCart,
  getDisplayCart,
  getCartStockNotices,
  getSetIsShopifyCartCookieLoading,
  getIsShopifyCartCookieLoading,
} from '@stores/use-ui-store';
import useCart from '@framework/cart/use-cart';
import usePrice from '@framework/use-price';
import useRemoveItem from '@framework/cart/use-remove-item';
import { getRegion } from '@framework/api/utils/maxify';
import useCartId from '@framework/cart/use-cart-id';
import {
  MAXIFY_REGION_NORTHERN_AMERICA,
  SM_NAV_HEIGHT_NUM,
  DEFAULT_LOCALE,
  DEFAULT_COUNTRY_CODE,
  ROUTE_CART,
} from '@constants';
import parseLocale from '@utils/parse-locale';
import Notice from '@components/Page/Notice';
import ButtonLink from '@components/ButtonLink';
import Affirm from '@components/Affirm/Affirm';
import { PageNotice } from '@framework/api/types';
import CartItemsSection from '../CartItemsSection';

import EmptyView from './EmptyView';
import ErrorView from './ErrorView';
import SuccessView from './SuccessView';
import * as styles from './styles';

type Props = {
  cartNotice?: PageNotice[];
};

const CartSidebarView: FC<Props> = ({ cartNotice }) => {
  const router = useRouter();
  const { locale } = router;
  const region = getRegion(locale);
  const parsedLocale = parseLocale(DEFAULT_LOCALE, locale);
  const country = parsedLocale[1] || DEFAULT_COUNTRY_CODE;

  const closeCart = useUiStore(getCloseCart);
  const openCart = useUiStore(getOpenCart);
  const displayCart = useUiStore(getDisplayCart);

  const bottomContainerRef = useRef<HTMLDivElement>(undefined);
  const closeButtonRef = useRef<HTMLButtonElement>(undefined);
  const { data, isEmpty, count } = useCart();

  const [bottomContainerHeight, setBottomContainerHeight] = useState(245);
  const CART_TOP = 61;
  const CART_TOP_WITH_NAV = CART_TOP + SM_NAV_HEIGHT_NUM;

  const isSm = useMedia('sm');
  const isSmLandscape = useMedia('smLandscape');
  const isMdLandscape = useMedia('mdLandscape');
  const isLandscape = isSmLandscape || isMdLandscape;

  const ADDED_TO_CART_LABEL = t('label_added_to_cart');
  const SUBTOTAL_STRING = t('cart_label_subtotal');
  const VIEW_CART_LABEL = t('view_cart_label');

  useEffect(() => {
    if (bottomContainerRef?.current) {
      const { height } = bottomContainerRef.current.getBoundingClientRect();
      const containerHeight = SM_NAV_HEIGHT_NUM + height + 20;
      setBottomContainerHeight(containerHeight);
    }
  }, [bottomContainerRef, CART_TOP_WITH_NAV]);

  const { price: subTotal } = usePrice(
    data && {
      amount: parseFloat(data.estimatedCost.subtotalAmount.amount),
      currencyCode: data.estimatedCost.subtotalAmount.currencyCode,
    }
  );

  const handleClose = (): void => closeCart();
  const handleOpen = (): void => openCart();

  const rawItems = useMemo(() => data?.lines.edges ?? [], [data]);

  const noBuildItems = useMemo(
    () =>
      rawItems.filter(
        i =>
          !i?.node?.attributes?.some(a => a.key === '_build_number') &&
          !i?.node?.merchandise?.title?.includes('Extend Protection Plan')
      ),
    [rawItems]
  );

  const warrItems = useMemo(
    () =>
      rawItems.filter(
        i =>
          i?.node?.merchandise?.product?.title?.indexOf(
            'Extend Protection Plan'
          ) > -1
      ),
    [rawItems]
  );

  const items = useMemo(
    () => [...noBuildItems, ...warrItems],
    [noBuildItems, warrItems]
  );

  const buildItems = useMemo(
    () =>
      rawItems.filter(i =>
        i?.node?.attributes?.some(a => a.key === '_build_number')
      ),
    [rawItems]
  );

  const buildNumbers = useMemo(
    () => [
      ...new Set(
        buildItems.map(
          i =>
            i?.node?.attributes?.find(a => a.key === '_build_number').value ??
            null
        )
      ),
    ],
    [buildItems]
  );

  const builds = useMemo(() => {
    const pcNumbers = buildNumbers.filter(n => !n.startsWith('KB-'));

    return pcNumbers.map(num =>
      buildItems.filter(
        b =>
          b?.node?.attributes?.find(
            a => a.key === '_build_number' && a.value === num
          ) && !b?.node?.merchandise?.title?.includes('Extend Protection Plan')
      )
    );
  }, [buildItems, buildNumbers]);

  const error = false;
  const success = false;

  const debouncedClose = debounce(handleClose, 250);
  const debouncedOpen = debounce(handleOpen, 500);

  useEffect(() => {
    const handleRouteChange = (url): void => {
      const queryString = url.split('?');
      const urlParams = new URLSearchParams(queryString[1]);
      if (urlParams.get('showCart') !== 'true') {
        debouncedClose();
      } else {
        debouncedOpen();
      }
    };

    Router.events.on('routeChangeComplete', handleRouteChange);

    return () => {
      Router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, [debouncedClose, debouncedOpen]);

  const handleClick = (): void => {
    closeCart();

    // track cart button click via GA
    GTM.dataLayer({
      dataLayer: {
        event: 'cartClick',
      },
    });
  };

  const isOver50Dollars = useMemo(
    () => subTotal && parseInt(subTotal.replace(/\D+/g, ''), 10) > 5000,
    [subTotal]
  );

  const hasMultipleItems = items?.length > 1;

  const cartNotices = useUiStore(getCartStockNotices);

  const setIsShopifyCartCookieLoading = useUiStore(
    getSetIsShopifyCartCookieLoading
  );
  const isShopifyCartCookieLoading = useUiStore(getIsShopifyCartCookieLoading);

  const getCartId = useCartId();

  // handle case where hook returns null, which generally means we switched
  // regions and attempted to fetch before the cookie changed
  if (typeof window !== 'undefined' && data === null) {
    if (!isShopifyCartCookieLoading) {
      setIsShopifyCartCookieLoading(true);
      getCartId(country).then(() => {
        setIsShopifyCartCookieLoading(false);
      });
    }
  }

  useEffect(() => {
    if (displayCart && closeButtonRef?.current) {
      closeButtonRef?.current.focus();
    }
  }, [displayCart]);

  const tabFocus = displayCart ? -1 : 0;

  // remove builds with OOS items
  const oosBuilds = useMemo(
    () =>
      builds
        .map(build => {
          if (
            build.some(
              i =>
                i.node.quantity === 0 ||
                (i.node.merchandise.quantityAvailable === 0 &&
                  !i.node.merchandise.product.productType.includes(
                    'Extend Service Contract'
                  ))
            )
          ) {
            return (
              build[0]?.node?.attributes?.find(a => a.key === '_build_number')
                .value ?? null
            );
          }

          return null;
        })
        .filter(Boolean),
    [builds]
  );

  const removeItem = useRemoveItem();

  useEffect(() => {
    const removeItems = async (): Promise<void> => {
      const ids = [];
      oosBuilds.forEach(buildId => {
        const build = builds.find(
          b =>
            b[0]?.node?.attributes?.find(a => a.key === '_build_number')
              .value === buildId
        );

        ids.push(build.map(i => i?.node?.id));
      });

      if (ids.length > 0) {
        await removeItem({ id: ids.filter(Boolean).flat() });
      }
    };

    if (Array.isArray(oosBuilds) && oosBuilds.length > 0) {
      removeItems();
    }
  }, [builds, oosBuilds, removeItem]);

  return (
    <div className={styles.getBaseStyle(error, success, isEmpty)}>
      <header className={styles.header}>
        <div className={styles.cartTop}>
          <div className={styles.cartHeader}>
            <p className={styles.heading} data-test-id="cart-heading">
              {ADDED_TO_CART_LABEL}
            </p>
            <p data-test-id="cart-total-items">({count || 0})</p>
          </div>
          <button
            aria-label={t('close')}
            type="button"
            onClick={() => handleClick()}
            tabIndex={tabFocus}
            ref={closeButtonRef}
            id="cart-close-button"
          >
            <IconX className={styles.closeButtonIcon} />
          </button>
        </div>
      </header>
      {(isEmpty || !data) && cartNotices.length === 0 ? (
        <EmptyView region={region} handleClose={handleClose} isSmall={isSm} />
      ) : error ? (
        <ErrorView />
      ) : success ? (
        <SuccessView />
      ) : (
        <div className={styles.scrollWrapper}>
          <div className={styles.scrollPositionedInner}>
            <div
              className={styles.itemsContainer(hasMultipleItems)}
              style={{
                minHeight: `calc(100vh - ${bottomContainerHeight}px)`,
              }}
            >
              {Array.isArray(cartNotice) && cartNotice?.length > 0 ? (
                <Notice
                  pageNotice={cartNotice}
                  className={styles.noticeStyles}
                />
              ) : null}
              <CartItemsSection data={data} isSideBarCart />
            </div>
            <div
              className={styles.bottomContainer(isLandscape)}
              ref={bottomContainerRef}
              style={{ bottom: `0px` }}
            >
              <p className={styles.subtotalContainer}>
                <span className={styles.subtotalHeading}>
                  {SUBTOTAL_STRING}
                </span>
                <span data-test-id="cart-sub-total">{subTotal}</span>
              </p>

              {region === MAXIFY_REGION_NORTHERN_AMERICA && isOver50Dollars && (
                <div className={styles.affirmBadge}>
                  <Affirm id="affirm-cart" price={subTotal} />
                </div>
              )}
              <ButtonLink
                buttonStyle="primary"
                asButton
                className={styles.cartButton}
                data-test-id="checkout-button"
                disabled={isEmpty}
                href={`/${ROUTE_CART}`}
                customMargin
                internalLink
              >
                {VIEW_CART_LABEL}
              </ButtonLink>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default CartSidebarView;
