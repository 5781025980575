/* eslint-disable react/no-unstable-nested-components */
import { FC } from 'react';
import dynamic from 'next/dynamic';
import useUiStore, {
  getDisplayModal,
  getCloseModal,
  getModalView,
} from '@stores/use-ui-store';
import { useTranslation as t } from '@utils/hooks';
import Modal from '@components/Modal';
import {
  MODAL_VIEW_SIGNUP,
  MODAL_VIEW_LOGIN,
  MODAL_VIEW_FORGOT,
  MODAL_VIEW_RESET,
  MODAL_VIEW_BLD_OPTIONS,
  MODAL_VIEW_BLD_MORE_INFO,
  MODAL_ARIA_LABEL_MAP,
  MODAL_SHARE_BLD_PC,
  MODAL_NOTIFICATION_SIGNUP_VIEW,
  MODAL_VIEW_BLD_INFO_HELP,
  MODAL_VIEW_EMAIL_SIGN_UP,
  MODAL_VIEW_PRODUCT_POLICY,
  MODAL_LOAD_BLD_PC,
  MODAL_VIEW_PRODUCT_DETAILS,
  MODAL_NOTICE_PAGE,
} from '@constants';
import * as styles from './styles';

const ariaLabelHandler = (modalView: string): string =>
  modalView ? MODAL_ARIA_LABEL_MAP[modalView] : null;

type Props = {
  background?: 'transparent' | 'light' | 'dark';
};

// TODO: Explore extending shared modal's capabilities (such as custom close modal behavior)

const Modals: FC<Props> = ({ background }) => {
  const displayModal = useUiStore(getDisplayModal);
  const modalView = useUiStore(getModalView);
  const closeModal = useUiStore(getCloseModal);

  const isInverted = background === 'light';

  const Loading = (): JSX.Element => (
    <div className={styles.loading(isInverted)}>{`${t('loading')}...`}</div>
  );

  const LoginView = dynamic(() => import('@components/Auth/LoginView'), {
    loading: () => <Loading />,
  });

  const SignUpView = dynamic(() => import('@components/Auth/SignUpView'), {
    loading: () => <Loading />,
  });

  const ForgotView = dynamic(() => import('@components/Auth/ForgotView'), {
    loading: () => <Loading />,
  });

  const ResetView = dynamic(() => import('@components/Auth/ResetView'), {
    loading: () => <Loading />,
  });

  const BldOptionsView = dynamic(
    () => import('@components/Build/BldOptionsView'),
    {
      loading: () => <Loading />,
    }
  );

  const BldMoreInfoView = dynamic(
    () => import('@components/Build/BldMoreInfoView'),
    {
      loading: () => <Loading />,
    }
  );

  const ExploreView = dynamic(() => import('@components/Build/ExploreView'), {
    loading: () => <Loading />,
  });

  const LoadBuildView = dynamic(
    () => import('@components/Build/LoadBuildView'),
    {
      loading: () => <Loading />,
    }
  );

  const ShareBuildView = dynamic(
    () => import('@components/Build/ShareBuildView'),
    {
      loading: () => <Loading />,
    }
  );

  const NotificationView = dynamic(
    () => import('@components/PDP/NotificationView'),
    {
      loading: () => <Loading />,
    }
  );

  const ProductPolicyView = dynamic(
    () => import('@components/PDP/ProductPolicyView'),
    {
      loading: () => <Loading />,
    }
  );

  const EmailSignUpView = dynamic(() => import('@components/EmailSignUpView'), {
    loading: () => <Loading />,
  });

  const ProductDetailsView = dynamic(
    () => import('@components/PDP/ProductDetailsView'),
    {
      loading: () => <Loading />,
    }
  );

  const NoticePageView = dynamic(
    () => import('@components/Notices/NoticeModalView'),
    {
      loading: () => <Loading />,
    }
  );

  // const isWideModal = modalView === MODAL_VIEW_PRODUCT_DETAILS;
  const isWideModal = [
    MODAL_VIEW_PRODUCT_DETAILS,
    MODAL_VIEW_BLD_MORE_INFO,
  ].includes(modalView);

  return (
    <Modal
      open={displayModal}
      onClose={closeModal}
      ariaLabel={() => ariaLabelHandler(modalView)}
      isWide={isWideModal}
    >
      {modalView === MODAL_VIEW_LOGIN && <LoginView pageType="modal" />}
      {modalView === MODAL_VIEW_SIGNUP && <SignUpView pageType="modal" />}
      {modalView === MODAL_VIEW_FORGOT && <ForgotView pageType="modal" />}
      {modalView === MODAL_VIEW_RESET && <ResetView pageType="modal" />}
      {modalView === MODAL_VIEW_BLD_OPTIONS && <BldOptionsView />}
      {modalView === MODAL_VIEW_BLD_MORE_INFO && <BldMoreInfoView />}

      {modalView === MODAL_LOAD_BLD_PC && <LoadBuildView buildType="PC" />}
      {modalView === MODAL_SHARE_BLD_PC && <ShareBuildView buildType="PC" />}
      {modalView === MODAL_NOTIFICATION_SIGNUP_VIEW && <NotificationView />}
      {modalView === MODAL_VIEW_BLD_INFO_HELP && <ExploreView />}
      {modalView === MODAL_VIEW_EMAIL_SIGN_UP && <EmailSignUpView />}
      {modalView === MODAL_VIEW_PRODUCT_POLICY && <ProductPolicyView />}
      {modalView === MODAL_VIEW_PRODUCT_DETAILS && <ProductDetailsView />}
      {modalView === MODAL_NOTICE_PAGE && <NoticePageView />}
    </Modal>
  );
};

export default Modals;
