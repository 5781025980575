import {
  FC,
  useEffect,
  memo,
  useRef,
  MutableRefObject,
  ReactNode,
  useState,
} from 'react';
import cn from 'clsx';
import {
  disableBodyScroll,
  enableBodyScroll,
  clearAllBodyScrollLocks,
} from '@utils/scroll-lock/bodyScrollLock';
import useCartStore, { getClearLastProduct } from '@stores/use-cart-store';
import useUiStore, {
  getDisplayMarketingBanner,
  getDisplayNav,
  getDisplayCart,
} from '@stores/use-ui-store';

import * as styles from './styles';
import extraStyles from './extraStyles.module.css';

// TODO - handle this any
interface Props {
  children: ReactNode;
  open: boolean;
  onClose: () => void;
  isMobileOnly?: boolean;
  isRight?: boolean;
  isInnerItem?: boolean;
  isScrollable?: boolean;
  isFullHeight?: boolean;
  isFullWidth?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  customRef?: any;
  isInverted?: boolean;
  darkModeMobileNav?: boolean;
  withSubNav?: boolean;
  noMarketingBanner?: boolean;
}

const Sidebar: FC<Props> = ({
  children,
  onClose,
  open = false,
  isMobileOnly = false,
  isRight = false,
  isInnerItem = false,
  isScrollable = false,
  isFullHeight = false,
  isFullWidth = false,
  isInverted = false,
  withSubNav = false,
  noMarketingBanner = false,
  customRef,
}) => {
  const ref = useRef() as MutableRefObject<HTMLDivElement>;
  const displayMarketingBanner = useUiStore(getDisplayMarketingBanner);
  const displayNav = useUiStore(getDisplayNav);
  const displayCart = useUiStore(getDisplayCart);

  useEffect(() => {
    if (ref.current) {
      if (open) {
        disableBodyScroll(ref.current, {
          reserveScrollBarGap: false,
        });
      } else {
        enableBodyScroll(ref.current);
      }
    }

    if (customRef?.current) {
      if (open) {
        disableBodyScroll(customRef.current, {
          reserveScrollBarGap: false,
        });
      } else {
        enableBodyScroll(customRef.current);
      }
    }
    return () => {
      clearAllBodyScrollLocks();
    };
  }, [open]);

  useEffect(() => {
    if (ref.current) {
      if (displayCart) {
        disableBodyScroll(ref.current, {
          reserveScrollBarGap: false,
        });
      } else {
        enableBodyScroll(ref.current);
      }
    }

    if (customRef?.current) {
      if (displayCart) {
        disableBodyScroll(customRef.current, {
          reserveScrollBarGap: true,
        });
      } else {
        enableBodyScroll(customRef.current);
      }
    }
    return () => {
      clearAllBodyScrollLocks();
    };
  }, [displayCart]);

  useEffect(() => {
    if (ref.current) {
      if (displayNav) {
        disableBodyScroll(ref.current, {
          reserveScrollBarGap: false,
        });
      } else {
        enableBodyScroll(ref.current);
      }
    }

    if (customRef?.current) {
      if (displayNav) {
        disableBodyScroll(customRef.current, {
          reserveScrollBarGap: false,
        });
      } else {
        enableBodyScroll(customRef.current);
      }
    }
    return () => {
      clearAllBodyScrollLocks();
    };
  }, [displayNav]);

  useEffect(() => {
    const handleKeydown = (e: KeyboardEvent): void => {
      if (e.key === 'Escape') {
        onClose();
      }
    };

    window.addEventListener('keydown', handleKeydown);

    return () => {
      window.removeEventListener('keydown', handleKeydown);
      clearAllBodyScrollLocks();
    };
  }, [onClose]);

  const [padding, setPadding] = useState(0);
  useEffect(() => {
    if (typeof window !== 'undefined') {
      const { innerHeight } = window;
      const parenHeight =
        customRef && customRef.current ? customRef.current.clientHeight : 0;
      const difference = parenHeight - innerHeight;
      if (difference) setPadding(difference + 30);
    }
  }, [customRef]);
  const clearLastProduct = useCartStore(getClearLastProduct);

  useEffect(() => {
    if (!displayCart) {
      clearLastProduct();
    }
  }, [displayCart]);

  return !isScrollable ? (
    <div
      className={styles.getContainerClass(
        noMarketingBanner ? false : displayMarketingBanner,
        withSubNav,
        isMobileOnly
      )}
      aria-hidden={!open}
    >
      <div
        className={cn(
          styles.getMobileOpenClass(
            open,
            noMarketingBanner ? false : displayMarketingBanner,
            isRight,
            isInnerItem,
            isFullHeight,
            isFullWidth,
            isInverted
          ),
          extraStyles.viewportHeight
        )}
        ref={ref}
      >
        {children}
      </div>
    </div>
  ) : (
    <div
      className={styles.getContainerClass(
        noMarketingBanner ? false : displayMarketingBanner,
        withSubNav,
        isMobileOnly
      )}
      aria-hidden={!open}
    >
      <div
        className={cn(
          styles.getMobileOpenClass(
            open,
            noMarketingBanner ? false : displayMarketingBanner,
            isRight,
            isInnerItem,
            isFullHeight,
            isFullWidth,
            isInverted
          ),
          extraStyles.parent
        )}
        ref={customRef}
      >
        <div className={styles.relative}>
          <div
            className={extraStyles.childWrapper}
            style={{ paddingBottom: padding }}
          >
            {children}
          </div>
        </div>
      </div>
    </div>
  );
};

export default memo(Sidebar);
