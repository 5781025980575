import { classnames } from '@tailwindcss-classnames';

export const fullWidth = classnames('w-full');

const focused = classnames('pointer-events-auto');
const unfocused = classnames('pointer-events-none');

export const verticalSpacing = classnames('border-b', 'mb-2');

export const ctaWrapper = classnames(
  'lg:mx-2',
  'lg:bg-nzxt-light-grey-800',
  'lg:rounded-lg',
  'lg:overflow-hidden',
  'lg:mt-auto'
);
export const flexCol = classnames('flex', 'flex-col');

export const cta = classnames(
  flexCol,
  'lg:flex-1',
  'lg:justify-center',
  'lg:justify-end',
  'lg:mt-auto',
  'lg:mb-1',
  'w-full'
);

export const ul = classnames('flex-1', 'overflow-y-auto', 'h-full');
export const base = classnames(flexCol, 'h-full');
export const full = classnames('h-full');

export const getOpenClass = (isFocused: boolean): string =>
  classnames(base, {
    [focused]: isFocused,
    [unfocused]: !isFocused,
  });

export const shrink = classnames(
  'flex-shrink-0',
  'ml-0',
  'flex',
  'items-center',
  'px-4',
  'py-1'
);
export const mobileLogo = classnames(
  'w-16',
  'h-4',
  'ml-1',
  'block',
  'lg:hidden'
);
