/* eslint-disable react/no-unstable-nested-components */
import { FC, memo } from 'react';
import { useRouter } from 'next/router';
import { SearchBox, WithSearch } from '@elastic/react-search-ui';
import { IconMagnifyingGlass } from '@nzxt/react-icons';
import CreateSearchProvider from '@components/CreateSearchProvider';
import GTM from '@utils/gtm';
import slugify from '@utils/slugify';
import { ROUTE_SEARCH } from '@constants';
import useUiStore, { getDisplaySearch } from '@stores/use-ui-store';
import Autocomplete from './Autocomplete';
import * as styles from './styles';

type Props = {
  id?: string;
  disableAutocomplete?: boolean;
};

const SearchBar: FC<Props> = ({ id, disableAutocomplete }) => {
  const { push } = useRouter();
  const displaySearch = useUiStore(getDisplaySearch);

  // track search events via GA
  const handleSearch = (term: string): void => {
    const payload = { searchQuery: slugify(term) };
    GTM.dataLayer({
      dataLayer: {
        event: 'navSearch',
        ...payload,
      },
    });
  };

  return displaySearch ? (
    <CreateSearchProvider resultsPerPage={6}>
      <WithSearch
        mapContextToProps={({
          wasSearched,
          isLoading,
          results,
          autocompletedResults,
        }) => ({
          wasSearched,
          isLoading,
          results,
          autocompletedResults,
        })}
      >
        {({
          wasSearched,
          isLoading,
          results,
          autocompletedResults: autocompletedResultsFromHOC,
        }) => (
          <div className={styles.searchBoxContainerWrapper}>
            <div className={styles.searchBoxContainer}>
              <SearchBox
                {...(wasSearched &&
                  !isLoading &&
                  results.length > 0 &&
                  autocompletedResultsFromHOC.length === 0 && {
                    autocompletedResults: results,
                  })}
                isOpen
                autocompleteResults={
                  disableAutocomplete
                    ? null
                    : {
                        sectionTitle: 'Suggested Results',
                        titleField: 'product_name',
                        urlField: 'product_url',
                      }
                }
                onSubmit={searchTerm => {
                  push(`/${ROUTE_SEARCH}?q=${searchTerm}`);
                  handleSearch(searchTerm);
                }}
                // eslint-disable-next-line no-empty-pattern
                onSelectAutocomplete={(
                  selection,
                  // eslint-disable-next-line no-empty-pattern
                  {},
                  defaultOnSelectAutocomplete
                ) => {
                  if (selection.suggestion) {
                    push(`/${ROUTE_SEARCH}?q=${selection.suggestion}`);
                    handleSearch(selection.suggestion);
                  } else {
                    defaultOnSelectAutocomplete(selection);
                  }
                }}
                inputView={({ getAutocomplete, getInputProps }) => (
                  <div className={styles.inputContainerWrapper}>
                    <div className={styles.inputContainer}>
                      <div className={styles.inputIcon}>
                        <IconMagnifyingGlass className={styles.inputIconSvg} />
                      </div>
                      <input
                        {...getInputProps({
                          id: id || 'search',
                          name: 'search',
                          type: 'search',
                          placeholder: 'Search NZXT',
                          autoFocus: true,
                        })}
                        className={styles.input}
                      />
                      {getAutocomplete()}
                    </div>
                  </div>
                )}
                autocompleteView={({
                  autocompletedResults,
                  autocompletedSuggestions,
                  getItemProps,
                  closeMenu,
                }) => (
                  <Autocomplete
                    autocompletedResults={autocompletedResults}
                    autocompletedSuggestions={autocompletedSuggestions}
                    getItemProps={getItemProps}
                    closeMenu={closeMenu}
                  />
                )}
              />
            </div>
          </div>
        )}
      </WithSearch>
    </CreateSearchProvider>
  ) : null;
};

export default memo(SearchBar);
