import { classnames } from '@tailwindcss-classnames';

export const container = classnames('w-56', 'md:w-72', 'relative', 'lg:z-20');

export const inputContainerWrapper = classnames(
  'flex',
  'justify-between',
  'flex-1',
  // 'px-4',
  'mx-auto',
  'max-w-screen-2xl',
  'px-4',
  'sm:px-6',
  'lg:px-12'
);

export const inputContainer = classnames('w-full', 'relative');

export const inputIcon = classnames(
  'absolute',
  'inset-y-0',
  'left-0',
  'flex',
  'items-center',
  'pointer-events-none'
);

export const inputIconSvg = classnames('text-nzxt-light-grey-100');

export const input = classnames(
  'rounded-none',
  'border-t-0',
  'border-r-0',
  'border-l-0',
  'border-b-0',
  'focus:outline-none',
  'focus:ring-transparent',
  'focus:ring-2',
  'outline-none',
  'focus:outline-none',
  'focus:shadow-none',
  'focus:ring-nzxt-volt-400',
  'focus:ring-1',
  'appearance-none',
  'block',
  'w-full',
  'pl-8',
  'pr-3',
  'py-1',
  'lg:py-4',
  'shadow-none',
  'lg:shadow-sm',
  'placeholder-nzxt-light-grey-100',
  'placeholder-opacity-75',
  'lg:text-sm',
  'text-base',
  'text-nzxt-light-grey-100',
  'md:h-6',
  'bg-nzxt-dark-grey-500'
);

// autocomplete
export const autocompleteDropdown = classnames(
  'origin-top-right',
  'absolute',
  'right-0',
  'mt-2',
  'w-full',
  'rounded-md',
  'shadow-lg',
  'bg-white',
  'ring-1',
  'ring-black',
  'ring-opacity-5',
  'focus:outline-none',
  'z-20'
);

export const sectionHeadingContainer = classnames('border-b', 'px-4', 'py-3');

export const sectionHeading = classnames(
  'text-sm',
  'font-bold',
  'text-nzxt-dark-grey-500',
  'block'
);

export const resultList = classnames('divide-y', 'divide-gray-200');

export const resultListItem = classnames(
  'flex',
  'hover:bg-gray-100',
  'last:rounded-b-lg'
);

export const resultHeading = classnames(
  'text-sm',
  'font-semibold',
  'text-gray-800'
);

export const resultSubheading = classnames(
  'text-xs',
  'text-nzxt-light-grey-600'
);

export const flex = classnames('flex', 'py-4', 'px-4', 'w-full');

export const ml = classnames('ml-3');

export const image = classnames('h-10', 'w-10');

export const searchBoxContainer = classnames(
  'absolute',
  'w-full',
  'mx-auto',
  'py-2',
  'bg-nzxt-dark-grey-500'
);

export const searchBoxContainerWrapper = classnames(
  'w-full',
  'relative'
  // 'hidden',
  // 'lg:block'
);
