import { classnames } from '@tailwindcss-classnames';

export const container = classnames('relative');

export const button = classnames(
  'flex',
  'items-center',
  'focus:outline-white',
  'relative',
  'p-1'
);

export const cartCount = classnames(
  'border-nzxt-volt-400',
  'bg-nzxt-volt-400',
  'text-white',
  'lg:border-white',
  'lg:bg-white',
  'lg:text-nzxt-dark-grey-800',
  'border',
  'h-4',
  'w-4',
  'absolute',
  'rounded-full',
  '-right-1',
  '-top-0.5',
  'flex',
  'items-center',
  'justify-center',
  'font-bold',
  'text-xs'
);

export const colorViolet = classnames('text-nzxt-volt-400');
export const colorWhite = classnames('text-white');

export const cartClass = (isDarkModeMobile: boolean): string =>
  classnames(
    'h-5',
    'md:h-6',
    'w-5',
    'md:w-6',
    'fill-current',
    'lg:text-white',
    'lg:hover:text-nzxt-dark-grey-100',
    {
      [colorViolet]: !isDarkModeMobile,
      [colorWhite]: isDarkModeMobile,
    }
  );
