import { FC, memo } from 'react';

import * as styles from './styles';

type Props = {
  heading: string;
  subheading: string;
  logo?: string;
  backgroundColor: string;
  onClick?: () => void;
  maxHeight: string;
};

const CartCta: FC<Props> = ({
  heading,
  subheading,
  logo,
  backgroundColor,
  onClick,
  maxHeight,
}) =>
  heading ? (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {onClick ? (
        <button
          type="button"
          onClick={onClick}
          className={styles.contentContainer}
          style={{ backgroundColor: `${backgroundColor}` }}
        >
          {logo && (
            <img
              className={styles.image}
              style={{ maxHeight }}
              src={logo}
              alt={heading}
            />
          )}
          {heading && (
            <p className={styles.heading} data-test-id="cart-cta-heading">
              {heading}
            </p>
          )}
          {subheading && (
            <p className={styles.subheading} data-test-id="cart-cta-subheading">
              {subheading}
            </p>
          )}
        </button>
      ) : (
        <div
          className={styles.contentContainer}
          style={{ backgroundColor: `${backgroundColor}` }}
        >
          {logo && (
            <img
              className={styles.image}
              style={{ maxHeight }}
              src={logo}
              alt={heading}
            />
          )}
          {heading && <p className={styles.heading}>{heading}</p>}
          {subheading && (
            <p className={styles.subheading} data-test-id="cart-cta-subheading">
              {subheading}
            </p>
          )}
        </div>
      )}
    </>
  ) : null;

export default memo(CartCta);
