import { classnames } from '@tailwindcss-classnames';

const hasMarketingBanner = classnames(
  'top-[98px]',
  'md:top-[102px]',
  'lg:top-lgNav'
);

const noMarketingBanner = classnames(
  'top-smallNav',
  'md:top-mdNav',
  'lg:top-lgNav'
);

const wrapperSharedStyles = classnames(
  'transform',
  'right-1/2',
  'lg:-right-[12px]',
  'translate-x-1/2',
  'lg:translate-x-0',
  'w-11/12',
  'absolute',
  'mx-auto',
  'my-2',
  'lg:m-0',
  'px-3',
  'pt-3',
  'pb-4',
  'rounded-md',
  'bg-nzxt-dark-grey-600'
);

export const getWrapperStyle = (flyout: boolean): string =>
  classnames('lg:w-[400px]', wrapperSharedStyles, {
    [noMarketingBanner]: !flyout,
    [hasMarketingBanner]: flyout,
  });

export const getWrapperStyleLongText = (flyout: boolean): string =>
  classnames('lg:w-[470px]', wrapperSharedStyles, {
    [noMarketingBanner]: !flyout,
    [hasMarketingBanner]: flyout,
  });

export const noticeContainer = classnames(
  'lg:divide-x',
  'lg:flex',
  'lg:space-x-4',
  'lg:max-w-screen-sm',
  'lg:flex-col',
  'lg:items-start',
  'lg:px-0',
  'lg:py-0',
  'lg:divide-x-0',
  'relative',
  'lg:pr-12'
);

export const flyoutCaret = classnames(
  'h-4',
  'w-4',
  'bg-nzxt-dark-grey-600',
  'rotate-45',
  'transform',
  'origin-bottom-left',
  'absolute',
  'rounded-sm'
);

export const currentRegionContainer = classnames(
  'text-white',
  'text-xs',
  'items-center',
  'justify-center',
  'lg:flex',
  'lg:justify-start',
  'w-full'
);

export const noticeIconContainer = classnames(
  'px-2',
  'hidden',
  'lg:inline-flex',
  'rounded-lg',
  'mr-2'
);

export const currentRegionTextContainer = classnames(
  'inline-block',
  'mb-1',
  'lg:mb-0'
);

export const currentRegionText = classnames('lg:inline-block');

export const regionNoticeTextDefault = classnames(
  'text-xs',
  'text-white',
  'lg:flex',
  'items-center',
  'justify-center',
  'lg:pl-8',
  'pt-1',
  'pb-2',
  'md:pt-0',
  'md:pt-0'
);

export const regionNoticeTextFlyout = classnames(
  regionNoticeTextDefault,
  'pr-4'
);

export const regionNoticeText = {
  default: regionNoticeTextDefault,
  flyout: regionNoticeTextFlyout,
};

export const regionButtonContainer = classnames('lg:flex', 'justify-center');

export const icon = classnames('text-white', 'w-6', 'h-6');

export const smallText = classnames(
  'text-xs',
  'uppercase',
  'font-bold',
  'text-gray-200'
);

export const link = classnames(
  'inline-flex',
  'whitespace-nowrap',
  'items-center',
  'justify-center',
  'px-4',
  'py-2',
  'border',
  'border-transparent',
  'rounded-md',
  'shadow-sm',
  'text-sm',
  'font-medium',
  'text-white',
  'bg-nzxt-dark-grey-400',
  'hover:bg-nzxt-volt-300'
);

const baseButton = classnames(
  'rounded-md',
  'focus:outline-none',
  'focus:ring-2',
  'focus:ring-nzxt-volt-400'
);

export const button = classnames(
  baseButton,
  'lg:ml-2',
  'lg:flex',
  'lg:items-center',
  'p-2',
  'lg:relative',
  'absolute',
  'top-0',
  'right-0',
  'lg:right-10'
);

export const flyoutButton = classnames(baseButton, 'absolute');

export const screenReaderText = classnames('sr-only');

export const arrowStyles = classnames('ml-2');

export const countryText = classnames(
  'text-xs',
  'text-white',
  'lg:pl-8',
  'pr-4',
  'pt-1'
);
export const switchCountry = classnames('underline');
